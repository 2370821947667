<template>
  <div id="main-container" :key="componentKey">


    <!-- Banner Section For Web Start -->
    <section class="webSection  custom-mentorshipprogram-banner-section">
      <div class="custom-banner-background-overlay"></div>
      <div class="custom-section">
        <div class="custom-row-wrap">
          <div class="custom-row-element ">
            <div class="custom-row-element-wrap ">
              <div class="row custom-row-section ">
                <div class="col-lg-7 ">
                  <div class="custom-banner-p">
                    <h3>The Mentorship Programs</h3>
                  </div>
                  <div class="custom-banner-heading custom-heading-without-background custom-black-heading">
                    <span>The most complete remote mentorship programs for young learners</span>
                  </div>
                  <div class="pt-5"></div>
                  <div class="row no-gutters align-items-center">
                    <div class="col-lg-4">
                      <div class="text-center mb-5 pl-2 pr-2">

                        <div class="custom-row  justify-content-center mt-2">


                          <!--            <i class="far fa-user"></i>-->
                          <span style="color: #ea216c;opacity: .2">
                            <b-icon font-scale="8" icon="circle-fill"></b-icon>
                          </span>

                          <span class=" custom-text-margin">
                            <div class=" mt-4 ">

                              <h6 class=" font-weight-bold  ">1:1 mentorship sessions</h6>
                            </div>
                            <div class="  mb-2">

                              <h1 class=" custom-text-color font-weight-bold">Every week</h1>
                            </div>
                          </span>

                        </div>

                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="text-center mb-5 pl-2 pr-2">

                        <div class="custom-row  justify-content-center mt-2">


                          <!--            <i class="far fa-user"></i>-->
                          <span style="color: #ea216c;opacity: .2">
                            <b-icon font-scale="8" icon="circle-fill"></b-icon>
                          </span>

                          <span class="custom-text-margin">
                            <div class=" mt-4 ">

                              <h6 class=" font-weight-bold  ">Program duration</h6>
                            </div>
                            <div class="  mb-2">

                              <div class="custom-row custom-text-color  w-100">
                                <h1 class="m-0 mr-1 font-weight-bold">4</h1>
                                <h3 class="m-0 font-weight-bold"> months</h3>
                              </div>
                              <p class="text-left custom-text-color font-weight-bold">with 6-8 h/week</p>

                            </div>
                          </span>


                        </div>


                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="text-center  mb-5 pl-2 pr-2">

                        <div class="custom-row  justify-content-center mt-2">


                          <!--            <i class="far fa-user"></i>-->
                          <span style="color: #ea216c;opacity: .2">
                <b-icon font-scale="8" icon="circle-fill"></b-icon>
              </span>

                          <span class=" custom-text-margin">
                <div class=" mt-4  mb-2">

              <h6 class=" font-weight-bold ">Program format</h6>
            </div>
                <div class=" mt-4  mb-2">

                  <h1 class="custom-text-color font-weight-bold">Online</h1>
                </div>
              </span>

                        </div>


                      </div>
                    </div>

                  </div>
                  <div class="row w3-animate-bottom">
                    <div class="col-lg-4">

                      <div class="custom-form-submit-btn">

                        <router-link :to="{name: 'SignUp'}" class="btn custom-btn-search custom-employee-btn my-2 ">
                            <span class="custom-btn-more-text font-weight-bold">
                              View More
                              <i class="fas fa-arrow-alt-circle-right"></i>
                            </span>
                        </router-link>
                      </div>
                    </div>
                    <div class="col-lg-8"></div>
                  </div>
                </div>
                <div class="col-lg-5"></div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>



    <div class="custom-row-element-wrap mt-5 mb-5">
      <div class="text-center mb-5 ml-auto mr-auto w-75">

        <div class="custom-row justify-content-center mt-2">
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
          <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
<!--            <i class="far fa-user"></i>-->
            <b-icon icon="person"></b-icon>
          </span>
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
        </div>
        <div class="custom-widget  mb-2">

          <h2 class=" custom-text-color ">With the Mentorship Programs you will!</h2>
        </div>

      </div>

      <div class="pt-4"></div>
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div>
            <img src="../assets/images/community-member-x720.png" width="100%">
          </div>

        </div>
        <div class="col-lg-6">
          <div class="post-container">


            <div class="custom-row">
              <span class="mr-3 custom-text-color ">
                <b-icon class="" icon="check2" font-scale="2"></b-icon>
              </span>
              <p class="custom-service-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div class="custom-row">
              <span class="mr-3 custom-text-color ">
                <b-icon class="" icon="check2" font-scale="2"></b-icon>
              </span>
              <p class="custom-service-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div class="custom-row">
              <span class="mr-3 custom-text-color ">
                <b-icon class="" icon="check2" font-scale="2"></b-icon>
              </span>
              <p class="custom-service-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div class="custom-row">
              <span class="mr-3 custom-text-color ">
                <b-icon class="" icon="check2" font-scale="2"></b-icon>
              </span>
              <p class="custom-service-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div class="custom-row">
              <span class="mr-3 custom-text-color ">
                <b-icon class="" icon="check2" font-scale="2"></b-icon>
              </span>
              <p class="custom-service-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div class="custom-row">
              <span class="mr-3 custom-text-color ">
                <b-icon class="" icon="check2" font-scale="2"></b-icon>
              </span>
              <p class="custom-service-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div>
              <div class="clear-bottom mt-2">
                <router-link to="/" class="btn link-theme text-decoration-none" href="#">
                  How do i join the program?
                  <i aria-hidden="true" class="fa fa-arrow-alt-circle-right"> </i></router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="pt-5"></div>
      <div class="text-center mb-5 ml-auto mr-auto w-75">

        <div class="custom-row justify-content-center mt-2">
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
          <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
<!--            <i class="far fa-user"></i>-->
            <b-icon icon="person"></b-icon>
          </span>
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
        </div>
        <div class="custom-widget  mb-2">

          <h2 class=" custom-text-color ">Million Women Mentorship Program</h2>
        </div>

      </div>
      <div class="custom-form-background pt-5 pb-5">
        <div class="row custom-row-section align-items-center ">
          <div class="col-lg-3">

          </div>
          <div class="col-lg-6">
            <div class="post-container text-white">


              <div class="custom-row">
              <span class="mr-3 ">
                <b-icon class="" icon="check2" font-scale="2"></b-icon>
              </span>
                <p class="custom-service-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
              <div class="custom-row">
              <span class="mr-3  ">
                <b-icon class="" icon="check2" font-scale="2"></b-icon>
              </span>
                <p class="custom-service-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
              <div class="custom-row">
              <span class="mr-3  ">
                <b-icon class="" icon="check2" font-scale="2"></b-icon>
              </span>
                <p class="custom-service-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
              <div class="custom-row">
              <span class="mr-3  ">
                <b-icon class="" icon="check2" font-scale="2"></b-icon>
              </span>
                <p class="custom-service-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
              <div class="custom-row">
              <span class="mr-3  ">
                <b-icon class="" icon="check2" font-scale="2"></b-icon>
              </span>
                <p class="custom-service-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
              <div class="custom-row">
              <span class="mr-3  ">
                <b-icon class="" icon="check2" font-scale="2"></b-icon>
              </span>
                <p class="custom-service-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
              <div>
                <div class="text-center clear-bottom mt-2">
                  <router-link to="/" class="btn link-theme text-decoration-none" href="#">
                    Apply Now
                    <i aria-hidden="true" class="fa fa-arrow-alt-circle-right"> </i></router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3"></div>

        </div>

      </div>
      <div class="pt-5"></div>
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="post-container">

            <div class=" custom-text-color">
              <h2 class="font-weight-bold">Our Big Misson</h2>
            </div>
            <div>
              <p class="custom-service-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <p class="custom-service-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>

          </div>
        </div>
        <div class="col-lg-6">
          <div >
            <img src="../assets/images/about-img7.png" width="100%">
          </div>

        </div>
      </div>
      <div class="pt-5"></div>
      <div class="text-center mb-5 ml-auto mr-auto w-75">

        <div class="custom-row justify-content-center mt-2">
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
          <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
<!--            <i class="far fa-user"></i>-->
            <b-icon icon="person"></b-icon>
          </span>
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
        </div>
        <div class="custom-widget  mb-2">

          <h2 class=" custom-text-color ">What questions do you have about our mentorship program?</h2>
        </div>

      </div>

      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="post-container">


            <div>

              <p class="custom-service-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <form>

              <div class="form-group mb-4">

                <input type="text" class="form-control custom-input-gotopage custom-job-search-input" id="inputFirstName" placeholder="First Name">
              </div>
              <div class="form-group mb-4">

                <input type="text" class="form-control custom-input-gotopage custom-job-search-input" id="inputLastName" placeholder="Last Name">
              </div>
              <div class="form-group mb-4">

                <input type="email" class="form-control custom-input-gotopage custom-job-search-input" id="inputEmail" placeholder="Email">
              </div>
              <div class="form-group mb-4">

                <textarea type="text" class="form-control custom-input-gotopage custom-job-search-input" id="inputHowCanHelp" placeholder="How can we Help you?"></textarea>
              </div>


              <div class="pt-3"></div>
              <!--  Load more    -->
              <div>
                <div class="text-center clear-bottom">
                  <button type="button" class="btn btn-outline-secondary btn-theme">

                    Get in touch

                  </button
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-lg-6">
          <div id="accordion">
            <div  class="row">

              <div  class="col-lg-12 mb-2">
                <div class="card custom-faq-wrap">
                  <a href="javascript:void(0)"   data-toggle="collapse" data-target="#collapse1" aria-expanded="false" aria-controls="collapse1" >
                    <div class="custom-faq-collapse">
                      <div class="custom-row">
                <span class="mr-2">
                  <b-icon icon="chevron-right"></b-icon>
                </span>
                        <span>
                  Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid ?
                </span>
                      </div>

                    </div>
                  </a>
                  <div class="">
                    <div id="collapse1" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                      <div class="card-body">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 mb-2">
                <div class="card custom-faq-wrap">
                  <a href="javascript:void(0)"   data-toggle="collapse" data-target="#collapse2" aria-expanded="false" aria-controls="collapse2" >
                    <div class="custom-faq-collapse">
                      <div class="custom-row">
                <span class="mr-2">
                  <b-icon icon="chevron-right"></b-icon>
                </span>
                        <span>
                  Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid ?
                </span>
                      </div>

                    </div>
                  </a>
                  <div class="">
                    <div id="collapse2" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                      <div class="card-body">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 mb-2">
                <div class="card custom-faq-wrap">
                  <a href="javascript:void(0)"   data-toggle="collapse" data-target="#collapse3" aria-expanded="false" aria-controls="collapse3" >
                    <div class="custom-faq-collapse">
                      <div class="custom-row">
                <span class="mr-2">
                  <b-icon icon="chevron-right"></b-icon>
                </span>
                        <span>
                  Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid ?
                </span>
                      </div>

                    </div>
                  </a>
                  <div class="">
                    <div id="collapse3" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                      <div class="card-body">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div  class="col-lg-12 mb-2">
                <div class="card custom-faq-wrap">
                  <a href="javascript:void(0)"   data-toggle="collapse" data-target="#collapse4" aria-expanded="false" aria-controls="collapse4" >
                    <div class="custom-faq-collapse">
                      <div class="custom-row">
                <span class="mr-2">
                  <b-icon icon="chevron-right"></b-icon>
                </span>
                        <span>
                  Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid ?
                </span>
                      </div>

                    </div>
                  </a>
                  <div class="">
                    <div id="collapse4" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                      <div class="card-body">
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <div>
              <div class="text-center clear-bottom mt-2">
                <router-link to="/faqs" class="btn link-theme text-decoration-none" href="#">
                  View all FAQs
                  <i aria-hidden="true" class="fa fa-arrow-alt-circle-right"> </i></router-link
                >
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="pt-5"></div>


    </div>
    <div class="w-100 text-center custom-icon-wrap">
      <span class="b-avatar custom-icon-border badge-info rounded-circle">
        <span class="b-avatar-img">
          <img src="../assets/images/avatar-2.jpg" alt="avatar">
        </span>
      </span>

    </div>
    <div class="custom-form-background">
      <div class="text-center text-white mb-5">
        <h2 class=" ">Got any questions?</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <div>
          <div class="text-center clear-bottom mt-2">
            <router-link to="/" class="btn link-theme text-decoration-none" href="#">
              Book a meeting
            </router-link
            >
            <br/>
            <br/>
            <router-link to="/faqs" class="text-decoration-none w3-hover-text-light-gray" href="#">
              or check out the FAQs
              <i aria-hidden="true" class="fa fa-arrow-alt-circle-right"> </i></router-link
            >
          </div>
        </div>
      </div>

    </div>


  </div>
</template>

<script>


import axios from 'axios'
import VueSlickCarousel from 'vue-slick-carousel'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// import { RlCarouselSlide } from 'vue-renderless-carousel'
export default {
  name: 'MentorshipProgram',
  components: {
    // SlidesComponent,
    // RlCarouselSlide
    VueSlickCarousel
  },
  data () {
    return {

      settingsForBrands: {
        infinite: true,
        slidesToShow: 4,
        speed: 500,
        rows: 1,
        slidesPerRow: 1,
        arrows: true,
        'autoplay': true,
        'autoplaySpeed': 2000,

        'pauseOnFocus': true,
        'pauseOnHover': true,
        'responsive': [
          {
            'breakpoint': 768,
            'settings': {
              'slidesToShow': 3,

              'infinite': true,

            }
          },
          {
            'breakpoint': 500,
            'settings': {
              'slidesToShow': 2,

              'infinite': true,

            }
          }
        ]
      },

      url: axios.defaults.baseURL,
      currentPage: 1,
      total: 1,

      list: null,
      PageOfItems: [],
      info: [],
      option: 0,

      loading: true,
      errored: false,
      //form
      show: true,
      showCloseButton: true,
      showResetButton: true,

      showSaveButton: true,
      //Form Validation Bool
      isValidated: false,
      componentKey: 0,
    }
  },
  //carousel_latest_jobs
  methods: {
    AddOrUpdate () {
      this.isValidated = true
    },

    showPrevious () {
      this.$refs.carousel.prev()
    },
    showNext () {
      this.$refs.carousel.next()
    },
    showPreviousLatestJob () {
      this.$refs.carousel_latest_jobs.prev()
    },
    showNextLatestJob () {
      this.$refs.carousel_latest_jobs.next()
    },
    showPreviousExecutiveJobs () {
      this.$refs.carousel_Executive_Jobs.prev()
    },
    showNextExecutiveJobs () {
      this.$refs.carousel_Executive_Jobs.next()
    },
    showPreviousTopEmployee () {
      this.$refs.carousel_Top_Employee.prev()
    },
    showNextTopEmployee () {
      this.$refs.carousel_Top_Employee.next()
    },
    showPreviousBrandsCar () {
      this.$refs.brands_carousel.prev()
    },
    showNextBrandsCar () {
      this.$refs.brands_carousel.next()
    },
    showPreviousTopEmployers () {
      this.$refs.top_employers_carousel.prev()
    },
    showNextTopEmployers () {
      this.$refs.top_employers_carousel.next()
    },

    //Rerender Component
    rerender () {
      this.componentKey += 1
    },
  },

  mounted () {


  }
}
</script>
