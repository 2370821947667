<template>
  <header class="custom-mainmenu">

    <div class="custom-header">
      <!--      :class="[!$auth.check() ? 'custom-header-wrap':'', $auth.check() ? 'custom-header-height':'']"-->
      <div class="custom-header-wrap" >
        <nav class="custom-navbar navbar navbar-expand-lg navbar-light">

          <div></div>
          <router-link class="navbar-brand custom-navbar-brand py-0" to="/">
            <img
              src="../assets/images/VLearn.png"
              class="d-inline-block align-top custom-navbar-logo"
            />
          </router-link>



          <div
            class="navbar-collapse custom-multi-navbar ml-5"
            id="headerSupportedContent"
          >



            <div id="ad-banner-id" class="mr-auto">

              <div v-if="topBarAdd.length != 0">
                <a :href="topBarAdd[0].url" :title="topBarAdd[0].url" target="_blank" class="navbar-brand mr-0">
                  <img :src="url+topBarAdd[0].image" class="w-100" style="height: 90px;object-fit: cover" />
                </a>
              </div>
              <div v-else>
                <router-link to="/" target="_blank" class="navbar-brand mr-0">
                  <img src="../assets/images/wide.f3b806b1.png" class="w-100" />
                </router-link>
              </div>
            </div>

            <!--            <template v-if="$auth.check()" >
                          &lt;!&ndash; <ul v-if="$auth.check()" class="navbar-nav mr-auto"> &ndash;&gt;

                             <ul  v-if="$auth.user().user.role == 'Employer'" class="navbar-nav mr-auto">
                          <li class="nav-item mr-2">
                            <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/employer-dashboard">
                              Dashboard
                            </router-link
                            >
                          </li>
                          <li class="nav-item dropdown mr-2">
                            <a class="nav-link custom-menu-link py-1 px-0 mr-3 dropdown-toggle" href="#" id="myJobsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              My Jobs
                            </a>
                            <div class="dropdown-menu w3-animate-opacity" aria-labelledby="myJobsDropdown">
                              <router-link class="dropdown-item" to="/employer-jobs">All Listed Jobs</router-link>
                              <router-link class="dropdown-item" to="/employer-candidates/0">All Employee Profile</router-link>


                            </div>
                          </li>

            &lt;!&ndash;              <li class="nav-item dropdown mr-2">
                            <a class="nav-link custom-menu-link py-1 px-0 mr-3 dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              CV Folder
                            </a>
                            <div class="dropdown-menu w3-animate-opacity" aria-labelledby="navbarDropdown">
                              <router-link class="dropdown-item" to="/employer-jobs">Description of Jobs</router-link>
                              <router-link class="dropdown-item" to="/employer-candidates">CV via Unassisted Hiring</router-link>
                              <router-link class="dropdown-item" to="/employer-candidates">CV via Assisted Hiring</router-link>
                              <router-link class="dropdown-item" to="/employer-candidates">CV upload within a Department</router-link>

                            </div>
                          </li>&ndash;&gt;


                          <li class="nav-item dropdown mr-2">
                            <a class="nav-link custom-menu-link py-1 px-0 mr-3 dropdown-toggle" href="#" id="myToolsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              My Tools
                            </a>
                            <div class="dropdown-menu w3-animate-opacity" aria-labelledby="myToolsDropdown">
            &lt;!&ndash;                  <router-link class="dropdown-item" to="/employer-analytics">Company</router-link>&ndash;&gt;
            &lt;!&ndash;                  <router-link class="dropdown-item" to="/employer-about-us">Company Public Profile</router-link>&ndash;&gt;
            &lt;!&ndash;                  <router-link class="dropdown-item" to="/employer-teamlist">Department</router-link>&ndash;&gt;


                              <router-link class="dropdown-item" to="/employer-profile">Update Company Profile</router-link>
                              <router-link class="dropdown-item" to="/employer-teamlist">Team Members</router-link>
                              <router-link class="dropdown-item" to="/change-password">Account Settings</router-link>
                              <router-link class="dropdown-item" to="/employer-accountsetting">Alerts / Notifications </router-link>
                              <router-link class="dropdown-item" to="/employer-packagehistory">Purchase History </router-link>
                              <router-link class="dropdown-item" to="/employer-package">Packages </router-link>


                            </div>
                          </li>
                          &lt;!&ndash;              <li class="nav-item mr-2">
                                          <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/">
                                            My Account
                                          </router-link>

                                        </li>&ndash;&gt;

                          &lt;!&ndash; <li class="nav-item mr-2">
                            <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/">
                              Employer
                            </router-link>
                          </li> &ndash;&gt;

                         <li class="nav-item mr-2">
                           <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/cvsearch">
                             CV Search
                           </router-link>
                         </li>
                          <li class="nav-item mr-2">
                            <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/employer-about-us/0">
                              About Us
                            </router-link>
                          </li>
                           <li class="nav-item mr-2">
                             <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/employer-postjob">
                               Post A Job
                             </router-link>
                           </li>
                        </ul>
                            <ul v-else class="navbar-nav mr-auto">
                          <li class="nav-item mr-2">
                            <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/employee-dashboard">
                              Dashboard
                            </router-link
                            >
                          </li>
                          <li class="nav-item dropdown mr-2">
                            <a class="nav-link custom-menu-link py-1 px-0 mr-3 dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              My Account
                            </a>
                            <div class="dropdown-menu w3-animate-opacity" aria-labelledby="navbarDropdown">
                              <router-link class="dropdown-item" to="/employee-profile">Profile</router-link>
                              <router-link class="dropdown-item" to="/employee-jobs">Applications</router-link>
                              <router-link class="dropdown-item" to="/purchase-history">Purchase History</router-link>
                              <router-link class="dropdown-item" :to="{ name: 'ChangePassword'}">Change Password</router-link>

                            </div>
                          </li>
                          &lt;!&ndash;              <li class="nav-item mr-2">
                                          <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/">
                                            My Account
                                          </router-link>

                                        </li>&ndash;&gt;
                          <li class="nav-item mr-2">
                            <router-link
                                class="nav-link custom-menu-link py-1 px-0 mr-3"
                                to="/all-jobs"
                            >
                              Find Job
                            </router-link>
                          </li>
                          <li class="nav-item mr-2">
                            <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/">
                              Employer
                            </router-link>
                          </li>


                          <li class="nav-item mr-2">
                            <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/about-us">
                              About Us
                            </router-link>
                          </li>
                          <li class="nav-item mr-2">
                            <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/add-profile-detail">
                              Complete Your Profile
                            </router-link>
                          </li>
                        </ul>
                      </template>-->
          </div>

          <div></div>
        </nav>

      </div>
    </div>

    <!--    shows on mobile view-->
    <div class="custom-header-ad">
      <div class="custom-header-wrap">
        <nav class="custom-navbar navbar navbar-expand-lg navbar-light justify-content-center">
          <div class="custom-mobile-view-ad">
            <div class="" style="">

              <div v-if="topBarAdd.length != 0">
                <a :href="topBarAdd[0].url" :title="topBarAdd[0].url" target="_blank" class="navbar-brand mr-0">
                  <img :src="url+topBarAdd[0].image" class="w-100" />
                </a>
              </div>
              <div v-else>
                <router-link to="/" target="_blank" class="navbar-brand mr-0">
                  <img src="../assets/images/wide.f3b806b1.png" class="w-100" />
                </router-link>
              </div>
            </div>
          </div>
        </nav>

      </div>
    </div>
  </header>
</template>

<script>

import axios from "axios";
export default {
  name: "BannerHeader",
  components:{


  },
  data(){
    return{
      status:0,

      // bool
      loggedOut:true,
      loggedIn:false,
      //userName: this.$auth.user().fullName,
      //email: this.$auth.user().email,
      //phoneNo:this.$auth.user().phoneNumber,
      //mailtoEmail:"mailto:"+this.$auth.user().email,

      url:axios.defaults.baseURL,



    };

  },
  computed:{
    topBarAdd(){
      console.log("topbarAdd : ", this.$store.getters.availableTopBarAdds)
      return this.$store.getters.availableTopBarAdds
    }
  },
  mounted(){

    this.getAdds();
    //this.getAll();


    // this.$nextTick(() => {
    //   // Change top navbar on scroll
    //   $(window).on("scroll", function() {
    //     if($(window).scrollTop() > 100) {
    //       $("#ad-banner-id").addClass("active");
    //     } else {
    //       $("#ad-banner-id").removeClass("active");
    //     }
    //   });
    // });

  },



  methods: {

    getAdds(){
      this.$store.dispatch("fetchAdds");
    },

  },
};
</script>

<style>
/*#ad-banner-id{
  transition: all 0.2s ease-in-out;
  display: block;
}
#ad-banner-id.active{
  display: none;
  transition: all 0.2s ease-in-out;
}*/
</style>
