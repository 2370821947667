<template>
  <div :key="componentKey">

    <!--  Content Start  -->
    <div class="custom-row-element-wrap mt-5 mb-5">
      <div class="row no-gutters">
        <div class="col-lg-12">
          <div class="p-3">
            <!--      <div class="custom-ribbon-background text-center">
              <div class="row no-gutters">
                <div class="col-lg-1"></div>
                <div class="col-lg-10">
                  <div class="row">
                    <div class="col-lg-4">

                      <div class="custom-row">
                          <span>
                            <b-avatar variant="info" src="https://placekitten.com/300/300" size="3rem" class="mr-3"></b-avatar>
                          </span>
                        <div class="text-left">
                          <span style="font-size: 15px; font-weight: 700">Estimated 4 Weeks</span><br/>
                          <span class="mr-auto">9-10 hour per week</span>
                        </div>

                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="custom-row">
                          <span>
                            <b-avatar variant="info" src="https://placekitten.com/300/300" size="3rem" class="mr-3"></b-avatar>
                          </span>
                        <div class="text-left">
                          <span style="font-size: 15px; font-weight: 700">Self-Paced</span><br/>
                          <span class="mr-auto">Progress at your own Speed</span>
                        </div>

                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="custom-row">
                          <span>
                            <b-avatar variant="info" src="https://placekitten.com/300/300" size="3rem" class="mr-3"></b-avatar>
                          </span>
                        <div class="text-left">
                          <span style="font-size: 15px; font-weight: 700">Free</span><br/>
                          <span class="mr-auto">Optional upgrade available</span>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-1"></div>
              </div>


            </div>-->
            <div class="w-100">
              <div class="custom-column-banner-heading  ">

                <div class="custom-heading-without-background  border-bottom mb-4">

                  <h2 v-if="info.viiLearnType == 'OurViiLearnStory'" class="text-capitalize">Knowledge & Training For Everyone</h2>
                  <h2 v-else-if="info.viiLearnType == 'BuildYouOwnCourses'" class="text-capitalize">Our Partners & Services for Corporates</h2>
                  <h2 v-else-if="info.viiLearnType == 'SkillHer'" class="text-capitalize">Spare a smile children welfare foundation & skill women Pakistan Mentorship Programme</h2>
                  <h2 v-else class="text-capitalize">{{ info.title }}</h2>
                </div>
                <!--                <div class="custom-widget mb-4 border-bottom">
                                        <span class="mr-2">
                                          <b-icon icon="clock-history"></b-icon>
                                        </span>
                                  <span class="custom-service-text text-muted">
                                          Updated On 12 Dec 2021
                                        </span>
                                </div>-->
                <div class="pt-2"></div>
                <div v-if="info.viiLearnType != 'OurViiLearnStory' && info.viiLearnType != 'SkillHer'">
                  <div v-if="info.image != '' && info.image != null" class="w-100">
                    <img :src="url+info.image" class="w-100 custom-post-detail" />
                  </div>
                  <div v-else class="w-100">
                    <b-alert  show>Loading...</b-alert>
                  </div>
                </div>


              </div>


            </div>
            <!-- <div class="pt-5"><img style="
                width: 90%;
    margin-left: 4%;
    height: 295px;
            " src="../assets/images/knowledge and training.jpg"/></div> -->
            
            <div style="margin-top: 4%;" v-html="info.longDescription">
            </div>



          </div>

        </div>

      </div>
      <!--   Spacer   -->
      <div class="pt-5"></div>
      <!--  Navigate To WhyVentureHer    -->
      <!--      <div class="text-center  mb-5">
              &lt;!&ndash;      <div class="custom-widget mt-2 ">
                      <h2 class=" ">Our Services</h2>
                    </div>&ndash;&gt;
              <div class="custom-row justify-content-center">
                <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
                <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
      &lt;!&ndash;            <i class="far fa-user"></i>&ndash;&gt;
                  <b-icon icon="trophy"></b-icon>
                </span>
                <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
              </div>

            </div>
            <div>
              <div class="text-center clear-bottom">
                <div class="row no-gutters">
                  <div class="col-lg-4"></div>

                  <div class="col-lg-4">
                    <div  v-if="info.type == 3" class="row no-gutters">
                      <div  class="col-lg-6">
                        <router-link  :to="{ name: 'Home', hash : '#ourWhyVentureHer_id' }"
                                      class="btn link-theme text-decoration-none">Why Venture(H)er?</router-link>
                      </div>
                      <div class="col-lg-6">
                        <router-link :to="{ name: 'ContactUs' }"

                                     class="btn link-theme text-decoration-none">Contact Us</router-link>
                      </div>

                    </div>
                    <div v-else>
                      <router-link :to="{ name: 'ContactUs' }"

                                   class="btn link-theme text-decoration-none">Contact Us</router-link>
                    </div>

                  </div>

                  <div class="col-lg-4"></div>
                </div>

              </div>

            </div>-->

    </div>




    <!--   Spacer   -->
    <div class="pt-5"></div>

    <!-- Banner Section For Web Start -->
    <!--    <section class="webSection  ">
          <div class="custom-banner-background-overlay"></div>
          <div class="custom-section">
            <div class="custom-row-wrap">
              <div class="custom-row-element ">
                <div class="custom-row-element-wrap ">
                  <div class="text-center mb-5">

                    <div class="custom-row justify-content-center mt-2">
                      <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
                      <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
    &lt;!&ndash;            <i class="far fa-user"></i>&ndash;&gt;
                <b-icon icon="person"></b-icon>
              </span>
                      <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
                    </div>
                    <div class="custom-widget  mb-2">
                      <h2 class=" ">Wanted to build your Own Courses with Skill Academy?</h2>
                    </div>
                  </div>
                  <div class="row custom-row-section align-items-center">

                    <div class="col-lg-7 custom-column-banner-heading  ">
                      <img src="../assets/images/about-img7.png" width="100%"/>
                    </div>
                    <div class="col-lg-5 custom-column-form-widget ">

                      <form>

                        <div class="form-group mb-4">

                          <input type="text" class="form-control custom-input-gotopage custom-job-search-input" id="inputFirstName" placeholder="First Name">
                        </div>
                        <div class="form-group mb-4">

                          <input type="text" class="form-control custom-input-gotopage custom-job-search-input" id="inputLastName" placeholder="Last Name">
                        </div>
                        <div class="form-group mb-4">

                          <input type="email" class="form-control custom-input-gotopage custom-job-search-input" id="inputPassword4" placeholder="Work Email">
                        </div>
                        <div class="form-group mb-4">

                          <input type="text" class="form-control custom-input-gotopage custom-job-search-input" id="inputCompanyName" placeholder="Company Name">
                        </div>
                        <div class="form-group mb-5">

                          <input type="text" class="form-control custom-input-gotopage custom-job-search-input" id="inputJobTitle" placeholder="Job Title">
                        </div>

                        <div class="pt-3"></div>
                        &lt;!&ndash;  Load more    &ndash;&gt;
                        <div>
                          <div class="text-center clear-bottom">
                            <button type="button" class="btn btn-outline-secondary btn-theme">

                              Submit

                            </button
                            >
                          </div>
                        </div>
                      </form>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>-->
    <!--    <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <section class="webSection  custom-landing-banner-section1">
                <div class="custom-banner-background-overlay"></div>
                <div class="custom-section">
                  <div class="custom-row-wrap">
                    <div class="custom-row-element ">
                      <div class="custom-row-element-wrap ">
                        <div class="row custom-row-section ">
                          <div class="col-lg-12 custom-column-banner-heading custom-carousel-left-z-index text-center ">
                            <div>
                              <h4><b-badge  class=" pt-1 pb-1 pl-3 pr-3 font-weight-normal" style="background-color: limegreen">Culture</b-badge></h4>
                            </div>
                            <div class="custom-banner-heading">
                              <span>Badshahi Mosque is an important remarkable of Mughal Architecture</span>
                            </div>

    &lt;!&ndash;                        <div class="row mt-4 w3-animate-bottom">
                              <div class="col-lg-6">

                                <div class="custom-form-submit-btn">

                                  <router-link :to="{name: 'SignUp'}"
                                               class="btn custom-btn-search custom-employee-btn my-2 ">
                                <span class="custom-btn-more-text font-weight-bold">
                                  <i class="fas fa-plus-circle"></i>
                                  Register As Employee
                                </span>
                                  </router-link>
                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div class="custom-form-submit-btn">

                                  <router-link :to="{name: 'SignUp'}"
                                               class="btn custom-btn-search custom-employer-btn my-2">
                                    <span class="custom-btn-more-text font-weight-bold">
                                      <i class="fas fa-plus-circle"></i>
                                      Register As Employer
                                    </span>
                                  </router-link>
                                </div>
                              </div>
                              &lt;!&ndash;                          <div class="col-lg-2"></div>&ndash;&gt;
                            </div>&ndash;&gt;
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div class="carousel-item">
              <section class="webSection custom-landing-banner-section2">
                <div class="custom-banner-background-overlay"></div>
                <div class="custom-section">
                  <div class="custom-row-wrap">
                    <div class="custom-row-element">
                      <div class="custom-row-element-wrap">
                        <div class="row custom-row-section ">
                          <div class="col-lg-12 custom-column-banner-heading custom-carousel-left-z-index ">
                            <div class="custom-banner-heading">
                              <span>Best Place To <span class="custom-text-color">Grow</span></span><br>
                              <span>Your Career</span>
                            </div>
                            <div class="custom-banner-p">
                              <p>Find Jobs, Employment and Career Opportunities</p>
                            </div>
    &lt;!&ndash;                        <div class="row mt-4 w3-animate-bottom">
                              <div class="col-lg-6">
                                <div class="custom-form-submit-btn">

                                  <router-link :to="{name: 'SignUp'}"
                                               class="btn custom-btn-search custom-employee-btn my-2 ">
                                <span class="custom-btn-more-text font-weight-bold">
                                  <i class="fas fa-plus-circle"></i>
                                  Register As Employee
                                </span>
                                  </router-link>
                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div class="custom-form-submit-btn">

                                  <router-link :to="{name: 'SignUp'}"
                                               class="btn custom-btn-search custom-employer-btn my-2 ">
                                    <span class="custom-btn-more-text font-weight-bold">
                                      <i class="fas fa-plus-circle"></i>
                                      Register As Employer
                                    </span>
                                  </router-link>
                                </div>
                              </div>
                              &lt;!&ndash;                          <div class="col-lg-2"></div>&ndash;&gt;
                            </div>&ndash;&gt;
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div class="carousel-item">
              <section class="webSection custom-landing-banner-section3">
                <div class="custom-banner-background-overlay"></div>
                <div class="custom-section">
                  <div class="custom-row-wrap">
                    <div class="custom-row-element">
                      <div class="custom-row-element-wrap">
                        <div class="row custom-row-section ">
                          <div class="col-lg-12 custom-column-banner-heading custom-carousel-left-z-index ">
                            <div class="custom-banner-heading">
                              <span>Lets Start Your Job </span><br>
                              <span>With <span class="custom-text-color">SheMeanWork</span></span>
                            </div>
                            <div class="custom-banner-p">
                              <p>Find Jobs, Employment and Career Opportunities</p>
                            </div>
    &lt;!&ndash;                        <div class="row mt-4 w3-animate-bottom">
                              <div class="col-lg-6">
                                <div class="custom-form-submit-btn">

                                  <router-link :to="{name: 'SignUp'}"
                                               class="btn custom-btn-search custom-employee-btn my-2 ">
                                <span class="custom-btn-more-text font-weight-bold">
                                  <i class="fas fa-plus-circle"></i>
                                  Register As Employee
                                </span>
                                  </router-link>
                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div class="custom-form-submit-btn">

                                  <router-link :to="{name: 'SignUp'}"
                                               class="btn custom-btn-search custom-employer-btn my-2 ">
                                    <span class="custom-btn-more-text font-weight-bold">
                                      <i class="fas fa-plus-circle"></i>
                                      Register As Employer
                                    </span>
                                  </router-link>
                                </div>
                              </div>

                            </div>&ndash;&gt;
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <a class="carousel-control-prev custom-carousel-prev" href="#carouselExampleControls" role="button"
             data-slide="prev">
            <span class="carousel-control-prev-icon " aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next custom-carousel-next" href="#carouselExampleControls" role="button"
             data-slide="next">
            <span class="carousel-control-next-icon " aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>-->


    <!--  Content Start  -->
    <!--    <div class="custom-row-element-wrap mt-5 mb-5">

          <div class="pt-4"></div>
          <div class="text-justify">
            <p>
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              <br>
              Section 1.10.32 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC
              "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"
              <br>
              1914 translation by H. Rackham
              "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?"
            </p>
          </div>





        </div>-->
    <!--   Spacer   -->
    <!--    <div class="pt-5"></div>-->
    <!-- Banner Section For Web Start -->
    <!--    <section class="webSection pt-5 pb-5" style="background-color: #171a28">
          <div class="custom-banner-background-overlay"></div>
          <div class="custom-section">
            <div class="custom-row-wrap">
              <div class="custom-row-element ">
                <div class="custom-row-element-wrap ">
                  <div class="row custom-row-section ">
                    <div class="col-lg-12 custom-column-banner-heading  text-center ">
                      <div class="custom-row justify-content-center">
                        <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
                        <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
                  &lt;!&ndash;            <i class="far fa-user"></i>&ndash;&gt;
                            <b-icon icon="trophy"></b-icon>
                          </span>
                        <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
                      </div>
                      <div class="text-white">

                        <h3>We are your strategic learning partners.</h3>
                        <h3>Let's move skills forward together.</h3>
                      </div>

                        <div class="row mt-4 w3-animate-bottom">
                          <div class="col-lg-2"></div>
                          <div class="col-lg-4">

                            <div class="custom-form-submit-btn">

                              <router-link :to="{name: 'SignUp'}"
                                           class="btn custom-btn-search custom-employee-btn my-2 ">
                            <span class="custom-btn-more-text font-weight-bold">
                              Request a Demo
                               <i class="fas fa-arrow-alt-circle-right"></i>
                            </span>
                              </router-link>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="custom-form-submit-btn">

                              <router-link :to="{name: 'SignUp'}"
                                           class="btn custom-btn-search custom-employer-btn my-2">
                                <span class="custom-btn-more-text font-weight-bold">
                                  Contact Us
                                  <i class="fas fa-arrow-alt-circle-right"></i>
                                </span>
                              </router-link>
                            </div>
                          </div>
                          <div class="col-lg-2"></div>
                        </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>-->

  </div>
</template>

<script>

// optional style for arrows & dots

import axios from 'axios'

// import { RlCarouselSlide } from 'vue-renderless-carousel'
export default {
  name: 'Detail',
  components: {
    // SlidesComponent,
    // RlCarouselSlide

  },
  data () {
    return {



      url: axios.defaults.baseURL,
      currentPage: 1,
      total: 1,

      list: null,
      PageOfItems: [],
      info: [],
      option: 0,




      loading: true,
      errored: false,


      componentKey: 0,
    }
  },
  //carousel_latest_jobs
  methods: {
    get() {
      console.log("id : ", this.$route.params.id)
      this.info = []
      axios.get('/api/V_Learn/Detail?id='+this.$route.params.id)
        .then(res => {
          this.info = res.data.data


          console.log('VentureHer Detail : ', this.info)






        }).catch(error => {
        console.log(error)
        this.errored = true
      })
        .finally(() => {
          if (this.errored) {

            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        })
    },

    closeModal(){
      $("#videoModal").on('hidden.bs.modal', function (e) {
        $("#videoModal iframe").attr("src", $("#videoModal iframe").attr("src"));
      });
    },



    //Rerender Component
    rerender () {
      this.componentKey += 1
    },
  },

  mounted () {


    this.get();
  }
}
</script>



