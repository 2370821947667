<template>
  <div id="main-container" :key="componentKey">


    <!-- Banner Section For Web Start -->
<!--    <section class="webSection  custom-form-background">
      <div class="custom-banner-background-overlay"></div>
      <div class="custom-section">
        <div class="custom-row-wrap">
          <div class="custom-row-element ">
            <div class="custom-row-element-wrap ">
              <div class="row custom-row-section align-items-center ">
                <div class="col-lg-6">
                  <div >
                    <img src="../assets/images/about-img5.jpg" width="100%">
                  </div>

                </div>
                <div class="col-lg-6">
                  <div class="post-container">

                    <div class="">
                      <h1 class=" ">Learn, Business</h1>
                      <h1 class=" ">Visibility & Networking</h1>
                    </div>
                    <div>
                      <p class="custom-service-text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                      </p>
                    </div>

                    &lt;!&ndash;  Load more    &ndash;&gt;
                    <div>
                      <div class="clear-bottom mt-2">
                        <router-link to="/" class="btn link-theme text-decoration-none" href="#">
                          View More
                          <i aria-hidden="true" class="fa fa-arrow-alt-circle-right"> </i></router-link
                        >
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>-->





    <!--  Posts Start  -->
    <div class="custom-row-element-wrap mt-5 mb-5">

<!--      <div class="row align-items-center ">
        <div class="col-lg-6">
          <div class="post-container">

            <div class="">
              <h2 class=" ">MWM (Million Women Mentor Program)</h2>
            </div>
            <div>
              <p class="custom-service-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>

            &lt;!&ndash;  Load more    &ndash;&gt;
            <div>
              <div class="clear-bottom mt-2">
                <router-link to="/" class="btn link-theme text-decoration-none" href="#">
                  Find out more
                  <i aria-hidden="true" class="fa fa-arrow-alt-circle-right"> </i></router-link
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div >
            <img src="../assets/images/blog4.jpg" width="100%">
          </div>

        </div>
      </div>
      <div class="pt-5"></div>-->
      <div class="text-center mb-5 ml-auto mr-auto w-75">

        <div v-if="ourMission.length != 0" class="custom-row justify-content-center mt-2">
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
          <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
<!--            <i class="far fa-user"></i>-->
            <b-icon icon="person"></b-icon>
          </span>
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
        </div>
        <div v-if="ourMission.length != 0">
          <div class="custom-widget  mb-2">
            <!--          <span style="color: #ea216c;font-size: 22px">Mentoring was never so easy</span>-->
            <h2 class="custom-text-color">{{ ourMission[0].title }}</h2>
          </div>
          <div class="text-muted">
            <p>
              {{ ourMission[0].shortDescription }}
            </p>
          </div>
        </div>
      </div>
      <div class="pt-4"></div>
      <!--  Read More    -->
      <div>
        <div v-if="ourMission.length != 0" class="text-center clear-bottom">
          <router-link :to="{ name: 'Detail',
          params: { id: ourMission[0].id }
          }"
                       class="btn link-theme text-decoration-none">
            Read More
            <!--            <b-icon class="ml-2" icon="arrow-right-circle-fill"></b-icon>-->
          </router-link>
        </div>
      </div>
      <div class="pt-5"></div>
      <div class="row align-items-center ">
        <div class="col-lg-4">
          <div v-if="spareASmile.length != 0">
            <img :src="url+spareASmile[0].image" class="custom-post-large-image-partner">
          </div>
          <div v-else>
            <b-alert  show>Loading...</b-alert>
          </div>
        </div>
        <div class="col-lg-8">
          <div v-if="spareASmile.length != 0" class="post-container">


            <div class="">
              <h2 class=" ">{{ spareASmile[0].title }}</h2>
            </div>
            <div>
              <p class="custom-service-text">
                {{ spareASmile[0].shortDescription }}
              </p>
            </div>

            <!--  Load more    -->
            <div class="row">
              <div class="col-md-9"></div>
              <div class="col-md-3">
                <div>
                  <div v-if="spareASmile.length != 0" class="clear-bottom mt-2">
                    <router-link :to="{ name: 'Detail',
                              params: { id: spareASmile[0].id }
                              }"
                                 class="btn link-theme text-decoration-none" href="#">
                      Read More
                      <!--                  <i aria-hidden="true" class="fa fa-arrow-alt-circle-right"> </i>-->
                    </router-link>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="pt-5"></div>


      <div class="text-center mb-5 ml-auto mr-auto w-75">

        <div class="custom-row justify-content-center mt-2">
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
          <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
<!--            <i class="far fa-user"></i>-->
            <b-icon icon="person"></b-icon>
          </span>
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
        </div>
        <div class="custom-widget  mb-2">
<!--          <span style="color: #ea216c;font-size: 22px">Mentoring was never so easy</span>-->
          <h2 class="custom-text-color">Our Programs</h2>
        </div>
<!--        <div class="text-muted">
          <p>
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          </p>
        </div>-->
      </div>

      <div class=" ">
        <div class="">
          <div class="row no-gutters">

            <div class="col-lg-12">
              <VueSlickCarousel class="" v-bind="settingsForBrands" ref="brands_carousel" v-if="ourPrograms.length != 0">
                <div v-for="br in ourPrograms" :key="br.id">

                  <article class="mb-5">
                    <div class="post-container">

                      <div class="post-thumbnail mt-4">
                        <div class="sh-ratio">
                          <!--                    sh-ratio-container-->
                          <div class="">
                            <!--                      class="sh-ratio-content"
                                                  style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"-->
                            <div class="custom-post-large-image-course">
                              <img v-if="br.image != null" :src="url+br.image" class="" />
                              <img v-else src="../assets/images/no-image.png" />
                            </div>
                          </div>
                        </div>
                        <router-link
                          :to="{ name: 'Detail',
                         params: { id: br.id }
                        }"
                          :title="br.title"
                          class="post-overlay">
                          <div class=""><span></span> <span></span> <span></span></div>
                        </router-link>
                      </div>
                      <div class="">
                        <div class="post-content-container mt-3">
                          <div class="post-title">
                            <h5> {{ br.title }} </h5>
                          </div>
                        </div>
                      </div>
                      <div class="container">
                        <div class="post-content-container mt-3">

                          <div class="post-content">
                            <p>
                              {{ br.shortDescription }}
                            </p>
                          </div>
                          <div class="row no-gutters mb-2">
                            <div class="col-md-8"></div>
                            <div class="col-md-4">
                            <span>
                              <router-link
                                :to="{ name: 'Detail',
                                  params: { id: br.id }
                                }"
                                :title="br.title"
                                class="text-decoration-none post-categories-link">
                            <span>
                              Read More
                              <!--                          Category-->
                            </span>
                          </router-link>
                            </span>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </article>

<!--                  <router-link :to="{ name: 'Detail',
                      params: { id: br.id }
                          }"  :title="br.title">
                    <img :src="url+br.image" />
                  </router-link>
                  <div class="pt-4"></div>
                  &lt;!&ndash;  Read More    &ndash;&gt;
                  <div>
                    <div class="text-center clear-bottom">
                      <router-link
                        :to="{ name: 'Detail',
                      params: { id: br.id }
                          }"
                        :title="br.title"
                        class="text-decoration-none post-categories-link">
                        <span>
                          Read More
                          &lt;!&ndash;                          Category&ndash;&gt;
                        </span>
                      </router-link>
                    </div>
                  </div>-->
                </div>
              </VueSlickCarousel>
            </div>

          </div>
        </div>
      </div>
<!--      <div class="row">
        <div class="col-lg-4">
          <div class="text-center mb-5 ml-auto mr-auto w-50">

            <div class="custom-row custom-text-color justify-content-center mt-2">


              &lt;!&ndash;            <i class="far fa-user"></i>&ndash;&gt;
              <b-icon icon="arrow-right-circle" font-scale="5"></b-icon>


            </div>
            <div class=" mt-4  mb-2">

              <h6 class=" font-weight-bold custom-text-color ">Always know what to cover next</h6>
            </div>
            <div class=" mt-4  mb-2">

              <p class=" ">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="text-center mb-5 ml-auto mr-auto w-50">

            <div class="custom-row custom-text-color justify-content-center mt-2">


              &lt;!&ndash;            <i class="far fa-user"></i>&ndash;&gt;
              <b-icon icon="globe2" font-scale="5"></b-icon>


            </div>
            <div class=" mt-4  mb-2">

              <h6 class=" font-weight-bold  custom-text-color ">Collaborate when and where you want</h6>
            </div>
            <div class=" mt-4  mb-2">

              <p class=" ">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>

          </div>
        </div>
        <div class="col-lg-4">
          <div class="text-center  mb-5 ml-auto mr-auto w-50">

            <div class="custom-row custom-text-color justify-content-center mt-2">


              &lt;!&ndash;            <i class="far fa-user"></i>&ndash;&gt;
              <b-icon icon="file-person" font-scale="5"></b-icon>


            </div>
            <div class=" mt-4  mb-2">

              <h6 class=" font-weight-bold  custom-text-color ">Never be alone</h6>
            </div>
            <div class=" mt-4  mb-2">

              <p class=" ">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>

          </div>
        </div>
      </div>-->

<!--      <div class="pt-5"></div>
      <div class="row">
        <div class="col-lg-3">
          <div class="text-center mb-5 ml-auto mr-auto">

            <div class="custom-row  justify-content-center mt-2">


              &lt;!&ndash;            <i class="far fa-user"></i>&ndash;&gt;
              <span style="color: #ea216c;opacity: .2">
                <b-icon icon="circle-fill" font-scale="6"></b-icon>
              </span>

              <span class="custom-text-color custom-text-margin">
                <div class=" mt-4  mb-2">

              <h6 class=" font-weight-bold custom-text-color ">Mentor a project you feel passionate about</h6>
            </div>
                <div class=" mt-4  mb-2">

                  <p class=" ">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
              </span>

            </div>

          </div>
        </div>
        <div class="col-lg-3">
          <div class="text-center mb-5 ml-auto mr-auto">

            <div class="custom-row custom-text-color justify-content-center mt-2">


              &lt;!&ndash;            <i class="far fa-user"></i>&ndash;&gt;
              <span style="color: #ea216c;opacity: .2">
                <b-icon icon="circle-fill" font-scale="6"></b-icon>
              </span>

              <span class="custom-text-color custom-text-margin" >
                <div class=" mt-4  mb-2">

              <h6 class=" font-weight-bold custom-text-color ">Grow personally and professionally</h6>
            </div>
                <div class=" mt-4  mb-2">

                  <p class=" ">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
              </span>


            </div>


          </div>
        </div>
        <div class="col-lg-3">
          <div class="text-center  mb-5 ml-auto mr-auto">

            <div class="custom-row custom-text-color justify-content-center mt-2">


              &lt;!&ndash;            <i class="far fa-user"></i>&ndash;&gt;
              <span style="color: #ea216c;opacity: .2">
                <b-icon icon="circle-fill" font-scale="6"></b-icon>
              </span>

              <span class="custom-text-color custom-text-margin">
                <div class=" mt-4  mb-2">

              <h6 class=" font-weight-bold custom-text-color ">Create a more inclusive & efficient society</h6>
            </div>
                <div class=" mt-4  mb-2">

                  <p class=" ">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
              </span>

            </div>


          </div>
        </div>
        <div class="col-lg-3">
          <div class="text-center  mb-5 ml-auto mr-auto">

            <div class="custom-row custom-text-color justify-content-center mt-2">


              &lt;!&ndash;            <i class="far fa-user"></i>&ndash;&gt;
              <span style="color: #ea216c;opacity: .2">
                <b-icon icon="circle-fill" font-scale="6"></b-icon>
              </span>

              <span class="custom-text-color custom-text-margin" >
                <div class=" mt-4  mb-2">

                  <h6 class=" font-weight-bold custom-text-color ">Become that mentor</h6>
                </div>
                <div class=" mt-4  mb-2">

                  <p class=" ">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
              </span>

            </div>


          </div>
        </div>
      </div>-->

      <div class="pt-5"></div>
      <div v-if="beAMentor.length != 0" class="row">

        <div class="col-lg-2"></div>
        <div  class="col-lg-4">
          <article class="mb-5">
            <div class="post-container">

              <div class="post-thumbnail mt-4">
                <div class="sh-ratio">
                  <!--                    sh-ratio-container-->
                  <div class="">
                    <div class="custom-post-large-image-course ">
                      <img v-if="beAMentor[0].image != null" :src="url+beAMentor[0].image" class="" />
                      <img v-else src="../assets/images/no-image.png" />
                    </div>
                  </div>
                </div>
                <router-link
                  :to="{ name: 'Detail',
                                  params: { id: beAMentor[0].id }
                                }"
                  :title="beAMentor[0].title"
                  class="post-overlay">
                  <div class=""><span></span> <span></span> <span></span></div>
                </router-link>
              </div>
              <div class="container">
                <div class="post-content-container mt-3">
                  <router-link
                    :to="{ name: 'Detail',
                                  params: { id: beAMentor[0].id }
                                }"
                    :title="beAMentor[0].title"
                    class="post-title">
                    <h2> {{ beAMentor[0].title }} </h2>
                  </router-link>
                  <div class="post-content">
                    <p>
                      {{ beAMentor[0].shortDescription }}
                    </p>
                  </div>
                  <!--  Learn more    -->
                  <div>
                    <div class=" clear-bottom">
                      <router-link :to="{ name: 'BeAMenteeForm'
                                }" class="btn link-theme text-decoration-none">
                        Register
                        <i aria-hidden="true" class="fa fa-arrow-alt-circle-right"> </i>
                      </router-link>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </article>
        </div>
        <div  class="col-lg-4">
          <article class="mb-5">
            <div class="post-container">

              <div class="post-thumbnail mt-4">
                <div class="sh-ratio">
                  <!--                    sh-ratio-container-->
                  <div class="">
                    <div class=" custom-post-large-image-course">
                      <img v-if="beAMentor[1].image != null" :src="url+beAMentor[1].image" class="" />
                      <img v-else src="../assets/images/no-image.png" />
                    </div>
                  </div>
                </div>
                <router-link
                  :to="{ name: 'Detail',
                                  params: { id: beAMentor[1].id }
                                }"
                  :title="beAMentor[1].title"
                  class="post-overlay">
                  <div class=""><span></span> <span></span> <span></span></div>
                </router-link>
              </div>
              <div class="container">
                <div class="post-content-container mt-3">
                  <router-link
                    :to="{ name: 'Detail',
                                  params: { id: beAMentor[1].id }
                                }"
                    :title="beAMentor[1].title"
                    class="post-title">
                    <h2> {{ beAMentor[1].title }} </h2>
                  </router-link>
                  <div class="post-content">
                    <p>
                      {{ beAMentor[1].shortDescription }}
                    </p>
                  </div>
                  <!--  Learn more    -->
                  <div>
                    <div class=" clear-bottom">
                      <router-link :to="{ name: 'BeAMentorForm'
                                }" class="btn link-theme text-decoration-none">
                        Register
                        <i aria-hidden="true" class="fa fa-arrow-alt-circle-right"> </i>
                      </router-link>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </article>
        </div>
        <div class="col-lg-2"></div>

      </div>



      <!--SPACER-->
      <div class="style-spacer"></div>

      <!--ADVERTISEMENT START--->
      <div class="style-advertisement-portion">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="style-category-wrapper-item">
                <div v-if="vlearnSkillHerPageBottomAd.length != 0" class="w-100 text-center">
                  <a :href="vlearnSkillHerPageBottomAd[0].url" :title="vlearnSkillHerPageBottomAd[0].url" target="_blank" class="navbar-brand mr-0">
                    <img :src="url+vlearnSkillHerPageBottomAd[0].image" class="w-100" />
                  </a>
                </div>
                <a v-else href="#">
                  <div class="text-center">Advertisement</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--SPACER-->
      <div class="style-spacer"></div>
<!--      <div class="custom-form-background">
        <div class="row custom-row-section align-items-center ">
          <div class="col-lg-6">
            <div class="post-container">

              <div class="">
                <h2 class=" ">Looking for an ideal opportunity to grow your business?</h2>
              </div>
              <div class="mt-3">
                <p class="custom-service-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>

              &lt;!&ndash;  Load more    &ndash;&gt;
&lt;!&ndash;              <div>
                <div class="clear-bottom mt-2">
                  <router-link to="/" class="btn link-theme text-decoration-none" href="#">
                    View More
                    <i aria-hidden="true" class="fa fa-arrow-alt-circle-right"> </i></router-link
                  >
                </div>
              </div>&ndash;&gt;
            </div>
          </div>
          <div class="col-lg-6">
            <div >
              <img src="../assets/images/whychooseus.jpg" width="100%">
            </div>

          </div>


        </div>

      </div>-->

    </div>



  </div>
</template>

<script>



import axios from 'axios'

import VueSlickCarousel from "vue-slick-carousel";
export default {
  name: 'SkillHer',
  components: {
    VueSlickCarousel
  },
  data () {
    return {




      settingsForBrands: {
        infinite: true,
        slidesToShow: 4,
        speed: 500,
        rows: 1,
        slidesPerRow: 1,
        arrows: true,
        "autoplay": true,
        "autoplaySpeed": 2000,

        "pauseOnFocus": true,
        "pauseOnHover": true,
        "responsive": [
          {
            "breakpoint": 768,
            "settings": {
              "slidesToShow": 3,

              "infinite": true,

            }
          },
          {
            "breakpoint": 500,
            "settings": {
              "slidesToShow": 2,

              "infinite": true,

            }
          }
        ]
      },


      url: axios.defaults.baseURL,
      currentPage: 1,
      total: 1,

      list: null,
      PageOfItems: [],
      info: [],
      option: 0,




      loading: true,
      errored: false,


      componentKey: 0,
    }
  },
  computed:{
    ourMission(){
      console.log("this.$store.state.ourMission", this.$store.state.ourMission)
      return this.$store.state.ourMission
    },
    spareASmile(){
      console.log("this.$store.state.spareASmile", this.$store.state.spareASmile)
      return this.$store.state.spareASmile
    },
    ourPrograms(){
      console.log("this.$store.state.ourPrograms", this.$store.state.ourPrograms)
      return this.$store.state.ourPrograms
    },
    beAMentor(){
      console.log("this.$store.state.beAMentor", this.$store.state.beAMentor)
      return this.$store.state.beAMentor
    },
    vlearnSkillHerPageBottomAd(){
      console.log("this.$store.getters.availableVlearnSkillHerPageBottomAd", this.$store.getters.availableVlearnSkillHerPageBottomAd)
      return this.$store.getters.availableVlearnSkillHerPageBottomAd
    }
  },
  methods: {
    getAll() {
      this.rerender()
      this.$store.dispatch("fetchAdds")
      this.$store.dispatch("fetchOurMission");
      this.$store.dispatch("fetchSpareASmile");
      this.$store.dispatch("fetchOurPrograms");
      this.$store.dispatch("fetchBeAMentor");

    },






    //Rerender Component
    rerender () {
      this.componentKey += 1
    },
  },

  mounted () {

    this.getAll();


  }
}
</script>
