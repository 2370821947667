import 'aes-js'
 function getValues() {

 }
 function loadIframe(iframeName, url) {

 var storeID = document.getElementById("storeId").value;
 var amount = document.getElementById("amount").value;
 var orderID = document.getElementById("orderId").value;
 var email = document.getElementById("email").value;
 var cellNo = document.getElementById("cellNo").value;
 var token = document.getElementById("token").value;
 var bankId = document.getElementById("bankId").value;
 var postBackURL = document.getElementById("postBackURL").value;
 var merchantPaymentMethod =
document.getElementById("merchantPaymentMethod").value;
var signature = document.getElementById("signature").value;
var params = { storeId: storeID, orderId: orderID, transactionAmount: amount,
mobileAccountNo: cellNo, emailAddress: email, transactionType: "InitialRequest", tokenExpiry: token,
bankIdentificationNumber: bankId, encryptedHashRequest:
encryptedHashRequest,merchantPaymentMethod : merchantPaymentMethod,
postBackURL:postBackURL,signature:signature};
var $iframe = $('#' + iframeName);

if ( $iframe.length ) {
if(params.storeId != "" && params.orderId !="") {
var str = jQuery.param( params);
$iframe.attr('src',url+'?'+str); // here you can change src
}
return false;
}

return true;
}
$( "#submitPaymentMethod" ).click(function() {
$("#iframe-class").addClass("show-iframe");
return loadIframe('easypay-iframe','http://localhost:3000/:0');
});

// function getencriptedstring(sampleString){
//     debugger
// var aesjs = require("aes-js")
// const keyBuffer = aesjs.utils.utf8.toBytes('GH2VBWF7FGLI1JG2');
// const inputBuffer = aesjs.padding.pkcs7.pad(aesjs.utils.utf8.toBytes(sampleString));
// const escEcb = new aesjs.ModeOfOperation.ecb(keyBuffer);
// const encryptedBytes = escEcb.encrypt(inputBuffer);
// const encryptedData = Buffer.from(encryptedBytes).toString('base64');
// }
// function getstring(){
//     debugger
//     var storeID = document.getElementById("storeId").value;
//  var amount = document.getElementById("amount").value;
//  var orderID = document.getElementById("orderId").value;
//  var postBackURL = document.getElementById("postBackURL").value;

//  var data = 'storeId='+storeID+'&'+'amount='+amount+'&'+'orderId='+orderID+'&'+'postBackURL='+postBackURL;
//  getencriptedstring(data);
// }