<template>
  <div :key="componentKey">

    <!--  Content Start  -->
    <div class="custom-row-element-wrap mt-5 mb-5">
      <div class="row no-gutters">
        <div class="col-lg-12">
          <div class="p-3">
            <div class="w-100">
              <div class="custom-column-banner-heading  ">

                <div class="custom-heading-without-background  border-bottom mb-4">

                  <h2 class="text-capitalize">Spare a smile children welfare foundation & skill women Pakistan Mentorship
                    Programme</h2>
                </div>

                <div class="pt-2"></div>
                <div>
                  <div class="w-100">
                    <img src="../assets/images/saslogo.png" class="w-100 custom-post-detail" />
                  </div>
                </div>


              </div>


            </div>

            <div style="margin-top: 4%; text-align: center; padding: 0 43px 0 43px;">
              <h2>WHO WE ARE?</h2>
              <p>The charity aims to establish a link between philanthropists and kind-hearted people of society and
                underprivileged women, mums and children. We are a non-profit children welfare organization that aims to
                bring smiles on the faces of special needs and underprivileged kids with an array of activities by every
                Saturday/Sunday making it a fun day. The idea is inspired by the initiative of a Dubai volunteering
                program where volunteers work closely with the special need children and organized various fun filled
                actives.</p>
              <p>We work closely with orphanages, special needs kid’s schools, Blind’s schools and other organizations. We
                take kids from these well-known and registered institutes for a fun filled day. Activities include JoyLand
                visit, movie day, field trips, joint birthdays at the institute premises, Eid and other festival
                celebrations and outing to fast food outlets like McDonald’s & Fatburger etc.</p>
              <p>These activites are mostly sponsored by various organizations. We encourage the staff of our sponsors to
                volunteer in these events and be part of a noble cause. The idea is to provide happy memories and unique
                experiences to these underprivileged kids who can only dream of visiting an outlet like FatBurger or
                taking a Joy Ride at an entertainment park. We believe in giving back the childhood to these
                underprivileged kids who have lost it to poverty or disability. They are the focus of attention and
                celebrations for a day. These events release some of the pain and stress of their daily life and leave a
                pleasant childhood memory.</p>
              <h3>Visit the website : <u><a target="_blank" href="https://spareasmile.com/">Spare A Smile (SAS)</a></u></h3>
              <hr style="border-top: 1px solid #000;" />
              <h2 style="margin-top: 1rem;">Volunteering with Spare a Smile (SAS)</h2>
              <p>Join SAS, an NGO dedicated to making a lasting impact. By volunteering, you directly address global
                issues like poverty, education, healthcare, and the environment. Gain valuable skills in project
                management, teamwork, and problem-solving. Engage with diverse communities, fostering empathy and cultural
                understanding.</p>
              <p>SAS values your contributions, providing a supportive and inclusive environment where your ideas matter.
                Experience the fulfillment of transforming lives, from bringing joy to children's faces to supporting
                sustainable development. Appreciation events and training sessions recognize your dedication.</p>
              <h2>Join SAS to make a difference in society and get acknowledged for your efforts. SAS recognizes your
                volunteering hours through certifying you.</h2>
              

                <div style="margin-top: 3rem;" id="defnic" class="gps-button-wrapper">
                  <router-link class="gps-button" to="/BeAVolunteer">
                Be A Volunteer
              </router-link>
                </div>

            </div>




          </div>

        </div>

      </div>
      <!--   Spacer   -->
      <div class="pt-5"></div>

    </div>




    <!--   Spacer   -->
    <div class="pt-5"></div>
  </div>
</template>

<script>

// optional style for arrows & dots

import axios from 'axios'

// import { RlCarouselSlide } from 'vue-renderless-carousel'
export default {
  name: 'Detail',
  components: {
    // SlidesComponent,
    // RlCarouselSlide

  },
  data() {
    return {



      url: axios.defaults.baseURL,
      currentPage: 1,
      total: 1,

      list: null,
      PageOfItems: [],
      info: [],
      option: 0,




      loading: true,
      errored: false,


      componentKey: 0,
    }
  },
  //carousel_latest_jobs
  methods: {
    get() {
      console.log("id : ", this.$route.params.id)
      this.info = []
      axios.get('/api/V_Learn/Detail?id=' + this.$route.params.id)
        .then(res => {
          this.info = res.data.data


          console.log('VentureHer Detail : ', this.info)






        }).catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => {
          if (this.errored) {

            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        })
    },

    closeModal() {
      $("#videoModal").on('hidden.bs.modal', function (e) {
        $("#videoModal iframe").attr("src", $("#videoModal iframe").attr("src"));
      });
    },



    //Rerender Component
    rerender() {
      this.componentKey += 1
    },
  },

  mounted() {


    this.get();
  }
}
</script>


<style scoped>
.wrapper {
		display: flex;
		flex-flow: row wrap;
		margin: 0 auto;
		max-width: 990px;
		padding-top: 15px;
}

.wrapper.stripe {
		background-color: #3ac0ca;
		padding: 50px 50px 75px;
		margin: 50px auto;
}

h1 {
		display: block;
		font: normal 600 35px / 1 "Work Sans", Helvetica, Arial, Verdana, sans-serif;
		margin-bottom: 50px;
		text-align: center;
		text-transform: uppercase;
		width: 100%;
}

.stripe h1 {
	color: #fff;
}

.block-wrapper {
		display: inline-block;
		margin-bottom: 25px;
		text-align: center;
		width: calc(33.33% - 15px);
}

/* DEFNIC BUTTONS
------------------------*/
#defnic.gps-button-wrapper a.gps-button {
    background-color: rgba(255,255,255,0);
    border: 2px solid #ea216c;
    border-radius: 100px;
    color: #ea216c;
    display: inline-block;
    font: normal normal 18px / 18px "Palanquin Dark", Helvetica, Arial, Verdana, sans-serif;
    overflow: hidden;
    padding: 8px 25px 10px;
    position: relative;
    text-decoration: none;
    transition: all .75s ease-in-out; 
    z-index: 1; }
#defnic.gps-button-wrapper a.gps-button:hover, 
#defnic.gps-button-wrapper a.gps-button:focus {
    color: #fff; 
    transition: all .75s ease-in-out; }

#defnic.gps-button-wrapper a.gps-button::before {
    background: linear-gradient(#ea216c, #ea216c) -100px 0 no-repeat;
    content: "";
    display: inline-block;
    
    height: 10em;
    width: 200%;

    opacity: 0;
    
    position: absolute;
    top: 0;
    left: -30%;

    -webkit-transform: skewX(-30deg) translateX(-80%);
        -ms-transform: skewX(-30deg) translateX(-80%);
            transform: skewX(-30deg) translateX(-80%); 
    
    transition: opacity .75s ease-in-out, -webkit-transform 0s .85s;
    transition: opacity .75s ease-in-out, -ms-transform 0s .85s;
    transition: opacity .75s ease-in-out, transform 0s .85s; 

    z-index: -1; }
#defnic.gps-button-wrapper a.gps-button:hover::before, 
#defnic.gps-button-wrapper a.gps-button:focus::before {
    opacity: 1;
    
    -webkit-transform: skewX(-30deg) translateX(0);
        -ms-transform: skewX(-30deg) translateX(0);
            transform: skewX(-30deg) translateX(0); 
    
    transition: -webkit-transform 1s ease-in-out;
    transition: -ms-transform 1s ease-in-out;
    transition: transform 1s ease-in-out; }

/* STRIPE BUTTONS */
#defnic.stripe {
		background-image:	linear-gradient(to bottom, #106fe7, #084c9d);
}

.stripe #defnic.gps-button-wrapper a.gps-button {
    background-color: #fff;
    border-color: #fff;
    color: #106fe7; }
.stripe #defnic.gps-button-wrapper a.gps-button:not(.subtle)::before {
    background-image: none; }

.stripe #defnic.gps-button-wrapper a.gps-button:hover, 
.stripe #defnic.gps-button-wrapper a.gps-button:focus {
    border-color: #23bc82;  
    box-shadow: 0 0 15px 5px rgba(18, 236, 192, .35), 0 0 0 7px rgba(0, 255, 198,.15), inset 0 0 15px 2px #c6f3ff; }

.stripe #defnic.gps-button-wrapper a.gps-button.subtle {
    background-color: rgba(255,255,255,0); 
    color: #fff; }
.stripe #defnic.gps-button-wrapper a.gps-button.subtle::before {
    background-image: linear-gradient(#fff, #fff); }
.stripe #defnic.gps-button-wrapper a.gps-button.subtle:hover, 
.stripe #defnic.gps-button-wrapper a.gps-button.subtle:focus {
    border-color: #23bc82; 
    box-shadow: 0 0 15px 5px rgba(18, 236, 192, .35), 0 0 0 7px rgba(0, 255, 198,.15), inset 0 0 15px 2px #c6f3ff;
    color: #106fe7; }

.stripe #defnic.gps-button-wrapper a.gps-button.button-2:hover, 
.stripe #defnic.gps-button-wrapper a.gps-button.button-2:focus {
    border-color: #49b5fd;  
    box-shadow: 0 0 15px 5px rgba(18, 236, 192, .35), 0 0 0 7px rgba(0,255,239,.1), inset 0 0 15px 2px #e1e6f7; }

.stripe #defnic.gps-button-wrapper a.gps-button.button-2.subtle:hover, 
.stripe #defnic.gps-button-wrapper a.gps-button.button-2.subtle:focus {
    border-color: #49b5fd;    
    box-shadow: 0 0 15px 5px rgba(18, 236, 192, .35), 0 0 0 7px rgba(0,255,239,.1), inset 0 0 15px 2px #e1e6f7; 
    color: #00d463; }

.stripe #defnic.gps-button-wrapper a.gps-button.button-2:hover, 
.stripe #defnic.gps-button-wrapper a.gps-button.button-2:focus {
	color: #00d463; }

.stripe #defnic.gps-button-wrapper a.gps-button.small:hover, 
.stripe #defnic.gps-button-wrapper a.gps-button.small:focus, 
.stripe #defnic.gps-button-wrapper a.gps-button.subtle.small:hover, 
.stripe #defnic.gps-button-wrapper a.gps-button.subtle.small:focus {
    box-shadow: 0 0 15px 5px rgba(18, 236, 192, .35), 0 0 0 7px rgba(0, 255, 198,.12), inset 0 0 15px 2px #c6f3ff; }
.stripe #defnic.gps-button-wrapper a.gps-button.button-2.small:hover, 
.stripe #defnic.gps-button-wrapper a.gps-button.button-2.small:focus, 
.stripe #defnic.gps-button-wrapper a.gps-button.button-2.subtle.small:hover, 
.stripe #defnic.gps-button-wrapper a.gps-button.button-2.subtle.small:focus {
    box-shadow: 0 0 15px 5px rgba(18, 236, 192, .3), 0 0 0 7px rgba(0,255,239,.09), inset 0 0 15px 2px #e1e6f7;
}

.stripe #defnic.gps-button-wrapper a.gps-button.subtle.dull:hover, 
.stripe #defnic.gps-button-wrapper a.gps-button.subtle.dull:focus {
    border-color: #fff; 
    box-shadow: none !important; }


/* RFL BUTTONS
------------------------*/
#rfl.gps-button-wrapper, 
#rfl.gps-button-wrapper a.gps-button {
    color: #333;
    display: inline-block;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    transition: all ease-out 0.5s .2s;
    z-index: 5; }
#rfl.gps-button-wrapper a.gps-button {
    background-color: rgba(255,255,255,.25); 
    border: 2px solid #84c538;
    border-radius: 0;
    font-size: 18px;
    line-height: 1; 
    margin-bottom: 0 !important;
    overflow: visible;
    padding: 11px 30px 10px; }

#rfl.gps-button-wrapper::before, 
#rfl.gps-button-wrapper::after {
    background: #336028;
    content: "";
    position: absolute;

    width: 2px;
    height: 100%;

    z-index: 10; }
#rfl.gps-button-wrapper::before {
    top: 0;
    left: 0;
   
        -ms-transform: translateY(100%);
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
#rfl.gps-button-wrapper::after {
    right: 0px;
    bottom: 0px;

        -ms-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }

#rfl.gps-button-wrapper a.gps-button::before, 
#rfl.gps-button-wrapper a.gps-button::after {
    background: #336028;
    content: "";
    position: absolute; 

    width: calc(100% + 2px);
    height: 2px; }
#rfl.gps-button-wrapper a.gps-button::before {
    top: -2px;
    left: -2px;

        -ms-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
#rfl.gps-button-wrapper a.gps-button::after {
    right: -2px;
    bottom: -2px;

        -ms-transform: translateX(100%);
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }

#rfl.gps-button-wrapper a.gps-button:hover, 
#rfl.gps-button-wrapper a.gps-button:focus {
    background-color: rgba(255,255,255,.65);
    border-color: #336028;
    color: #336028;
    transition: background .75s ease-in, border .5s ease-in-out 1.1s, color .75s ease-in; }

#rfl.gps-button-wrapper:hover::before, #rfl.gps-button-wrapper:hover::after, 
#rfl.gps-button-wrapper:focus::before, #rfl.gps-button-wrapper:focus::after, 
#rfl.gps-button-wrapper:hover .gps-button::before, #rfl.gps-button-wrapper:hover .gps-button::after, 
#rfl.gps-button-wrapper:focus .gps-button::before, #rfl.gps-button-wrapper:focus .gps-button::after {
    opacity: 1;

        -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
            transform: translateX(0); }

#rfl.gps-button-wrapper:hover a.gps-button::before, 
#rfl.gps-button-wrapper:focus a.gps-button::before {
    transition: -webkit-transform 0.35s;
    transition: -ms-transform 0.35s; 
    transition: transform 0.35s; }
#rfl.gps-button-wrapper:hover::after, 
#rfl.gps-button-wrapper:focus::after {
    transition: -webkit-transform 0.2s 0.35s; 
    transition: -ms-transform 0.2s 0.35s; 
    transition: transform 0.2s 0.35s; }
#rfl.gps-button-wrapper:hover a.gps-button::after, 
#rfl.gps-button-wrapper:focus a.gps-button::after {
    transition: -webkit-transform 0.35s .55s; 
    transition: -ms-transform 0.35s .55s; 
    transition: transform 0.35s .55s; }
#rfl.gps-button-wrapper:hover::before, 
#rfl.gps-button-wrapper:focus::before {
    transition: -webkit-transform 0.2s 0.9s; 
    transition: -ms-transform 0.2s 0.9s; 
    transition: transform 0.2s 0.9s; }

#rfl.gps-button-wrapper::before, #rfl.gps-button-wrapper::after, 
#rfl.gps-button-wrapper a.gps-button::before, #rfl.gps-button-wrapper a.gps-button::after {
    opacity: 0;    

    transition: opacity .5s ease-out, -webkit-transform .2s .6s; 
    transition: opacity .5s ease-out, -ms-transform .2s .6s; 
    transition: opacity .5s ease-out, transform .2s .6s; }

/* CRISS CROSS BUTTON
------------------------*/
#criss-cross .gps-button {
    background: linear-gradient(to right, rgba(255,255,255,0), #fff 50%) 250% 100% / 100% 0 no-repeat, linear-gradient(to right, #3eaad8, #3eaad8) 100% 100% / 100% 2px no-repeat, linear-gradient(to right, #3eaad8, #3eaad8) 100% 0% / 100% 2px no-repeat, linear-gradient(to right, #3eaad8, #3eaad8) 0 0 / 2px 100% no-repeat, linear-gradient(to right, #3eaad8, #3eaad8) 100% 0 / 2px 100% no-repeat;
    color: #008cd8;
    cursor: pointer !important;
		display: inline-block;
		font: normal normal 20px / 1.35 "Work Sans", Helvetica, Arial, Verdana, sans-serif;
		padding: 5px 8px;
    text-decoration: none !important;
    transition: all .45s ease-in-out; }

#criss-cross .gps-button:hover, #criss-cross .gps-button:focus {
    background-position: 0 100%, 100% 0;
    color: #000;
    outline: none;
    transition: all .45s ease-in-out; }

/* BORDER WEIGHT BUTTON
--------------------------*/
#border-weight .gps-button {
		display: inline-block;
		position: relative;
  	padding: 25px 25px 26px;
		text-decoration: none;
		text-transform: uppercase;
		font-family: "Work Sans", Helvetica, Arial, Verdana, sans-serif;
  	font-size: 18px;
	  letter-spacing: 2px;
  	color: #1a2026;
  	font-weight: bold;
		z-index: 5;
}

#border-weight .gps-button:before,
#border-weight .gps-button:after {
  content: "";
  position: absolute;
}

#border-weight .gps-button:before {
	background-color: #fff;
  width: calc(100% - 16px);
	height: calc(100% - 16px);
	top: 8px;
	left: 8px;
	z-index: -1;
  
  -webkit-transition: all .15s;
  -moz-transition: all .15s;
  transition: all .15s;
}

#border-weight .gps-button:after {
  background-color: #999;
	width: 100%;
	height: 100%;
  top: 0;
  left: 0;
	z-index: -2;
}

#border-weight .gps-button:hover:before {
  width: calc(100% - 8px);
	height: calc(100% - 8px);
	transform: translate(-4px, -4px)
}
</style>
