<template>
  <div  :key="componentKey">




    <!--  Content Start  -->
    <div class="custom-row-element-wrap mt-5 mb-5">

      <div class="mb-5 ml-auto mr-auto">

        <div class="text-center mb-5">
          <div class="custom-row justify-content-center mt-2">
            <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
            <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
<!--            <i class="far fa-user"></i>-->
            <b-icon icon="person"></b-icon>
          </span>
            <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
          </div>
          <div  class="custom-widget  ">
            <!--          <span style="color: #ea216c;font-size: 18px">{{ ourViiLearnStory[0].title }}</span>-->
            <h2 class="custom-text-color">Our Vii Learn Story</h2>
          </div>
        </div>

        <div class="text-muted">
          <p v-if="ourViiLearnStory != [] && ourViiLearnStory != null">
            <span v-html="ourViiLearnStory[0].longDescription"></span>
          </p>
        </div>
      </div>

    </div>


    <!-- Banner Section For Web Start -->
<!--    <section class="webSection mt-5 ">
      <div class="custom-banner-background-overlay"></div>
      <div class="custom-section">
        <div class="custom-row-wrap">
          <div class="custom-row-element ">
            <div class="custom-row-element-wrap ">
              <div class="row custom-row-section ">
                <div class="col-lg-4 custom-column-banner-heading  ">
                  <div class="custom-banner-p">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                  </div>
                  <div class="custom-banner-heading custom-heading-without-background">
                    <span>Our <span class="custom-text-color">Vii Learn</span></span><br>
                    <span>Story</span>
                  </div>
                  <div class="custom-banner-p text-muted">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                  </div>
                  <div class="row mt-4 w3-animate-bottom">
                    <div class="col-lg-12">

                      <div class="custom-form-submit-btn">

                        <router-link :to="{name: 'SignUp'}"  class="btn custom-btn-search custom-employee-btn my-2 ">
                            <span class="custom-btn-more-text font-weight-bold">
                              View More
                              <i class="fas fa-arrow-alt-circle-right"></i>
                            </span>
                        </router-link>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="col-lg-8 custom-column-form-widget ">
                  <img src="../assets/images/banner-x800.png" width="100%"/>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>-->



    <!--  Content Start  -->
<!--    <div class="custom-row-element-wrap mt-5 mb-5">

      <div class="pt-4"></div>
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="post-container">
            <div class="custom-widget mt-2  ">
              <h2 class="widget-title text-uppercase custom-text-color">Distance Learning</h2>
            </div>
            <div class="custom-widget mb-4 border-bottom">
              <h2 class=" ">Our Vii Learn Story</h2>
            </div>
            <div>
              <p class="custom-service-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            &lt;!&ndash;  Load more    &ndash;&gt;
            <div>
              <div class="clear-bottom mt-2">
                <router-link to="/" class="btn link-theme text-decoration-none" href="#">
                  Explore Learning
                  <i aria-hidden="true" class="fa fa-arrow-alt-circle-right"> </i></router-link
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="post-thumbnail mt-4">
            <div class="sh-ratio">
              &lt;!&ndash;                    sh-ratio-container&ndash;&gt;
              <div class="">
                &lt;!&ndash;                      class="sh-ratio-content"
                                      style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"&ndash;&gt;
                <div >
                  <img src="../assets/images/whychooseus.jpg" >
                </div>
              </div>
            </div>
            <a
              data-toggle="modal" data-target="#videoModal" title="Add Course"
              class="post-overlay">
              <div class="post-overlay-content custom-post-overlay-content"><b-icon icon="play-circle"></b-icon></div>
            </a>
          </div>

        </div>
      </div>

      &lt;!&ndash;   Spacer   &ndash;&gt;
      <div class="pt-5"></div>
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div >
            <img src="../assets/images/about-img7.png" width="100%">
          </div>
        </div>
        <div class="col-lg-6">
          <div class="post-container">
            <div class="custom-widget mt-2  ">
              <h2 class="widget-title text-uppercase custom-text-color">Distance Learning</h2>
            </div>
            <div class="custom-widget mb-4 border-bottom">
              <h2 class=" ">Our Vii Learn Story</h2>
            </div>
            <div>
              <p class="custom-service-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            &lt;!&ndash;  Load more    &ndash;&gt;
            <div>
              <div class="clear-bottom mt-2">
                <router-link to="/" class="btn link-theme text-decoration-none" href="#">
                  Explore Learning
                  <i aria-hidden="true" class="fa fa-arrow-alt-circle-right"> </i></router-link
                >
              </div>
            </div>
          </div>
        </div>

      </div>



    </div>-->
    <!--   Spacer   -->
<!--    <div class="pt-5"></div>-->

    <!--  Contact Us    -->
<!--    <div>
      <div class="text-center clear-bottom">
        <router-link to="/" class="btn link-theme text-decoration-none">Contact Us</router-link>
      </div>
    </div>-->
    <!--   Spacer   -->
<!--    <div class="pt-5"></div>-->
    <!-- Video Modal-->

<!--    <div class="modal fade" id="videoModal" tabindex="-1" role="dialog" aria-labelledby="videoModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <button type="button" @click="closeModal" class="close custom-close-btn" data-dismiss="modal"  aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>

          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item"
                    src="https://www.youtube.com/embed/Zv11L-ZfrSg"
                    allowfullscreen></iframe>
          </div>


        </div>
      </div>
    </div>-->
  </div>
</template>

<script>

// optional style for arrows & dots

import axios from 'axios'

// import { RlCarouselSlide } from 'vue-renderless-carousel'
export default {
  name: 'OurViiLearnStory',
  components: {
    // SlidesComponent,
    // RlCarouselSlide

  },
  data () {
    return {



      url: axios.defaults.baseURL,
      currentPage: 1,
      total: 1,

      list: null,
      PageOfItems: [],
      info: [],
      option: 0,

      //posts
      featuredPosts: [],
      latestPosts: [],
      postType: null,


      //Posts by categories
      postList:[],
      healthAndBeauty: {},
      beveragesAndDining: {},
      hotelsAndStaycations: {},
      sportsAndLeisure: {},
      kidsAndFamily: {},
      flowersAndGardening: {},



      loading: true,
      errored: false,


      componentKey: 0,
    }
  },
  computed:{
    ourViiLearnStory(){
      console.log("this.$store.state.ourViiLearnStory", this.$store.state.ourViiLearnStory)
      return this.$store.state.ourViiLearnStory
    },
  },
  methods: {
    getAll(){


      this.$store.dispatch("fetchOurViiLearnStory");



    },

    closeModal(){
      $("#videoModal").on('hidden.bs.modal', function (e) {
        $("#videoModal iframe").attr("src", $("#videoModal iframe").attr("src"));
      });
    },

    showPrevious () {
      this.$refs.carousel.prev()
    },
    showNext () {
      this.$refs.carousel.next()
    },
    showPreviousLatestJob () {
      this.$refs.carousel_latest_jobs.prev()
    },
    showNextLatestJob () {
      this.$refs.carousel_latest_jobs.next()
    },
    showPreviousExecutiveJobs () {
      this.$refs.carousel_Executive_Jobs.prev()
    },
    showNextExecutiveJobs () {
      this.$refs.carousel_Executive_Jobs.next()
    },
    showPreviousTopEmployee () {
      this.$refs.carousel_Top_Employee.prev()
    },
    showNextTopEmployee () {
      this.$refs.carousel_Top_Employee.next()
    },
    showPreviousBrandsCar () {
      this.$refs.brands_carousel.prev()
    },
    showNextBrandsCar () {
      this.$refs.brands_carousel.next()
    },
    showPreviousTopEmployers () {
      this.$refs.top_employers_carousel.prev()
    },
    showNextTopEmployers () {
      this.$refs.top_employers_carousel.next()
    },


    //Rerender Component
    rerender () {
      this.componentKey += 1
    },
  },

  mounted () {

    this.getAll();

  }
}
</script>
