<template>
  <div id="main-container" :key="componentKey">
    <!-- Banner Section For Web Start -->
    <section class="webSection mt-5 ">
      <div class="custom-banner-background-overlay"></div>
      <div class="custom-section">
        <div class="custom-row-wrap">
          <div class="custom-row-element ">
            <div class="custom-row-element-wrap ">
              <div class="row custom-row-section ">
                <div class="col-lg-7 custom-column-banner-heading  ">
                  <div class="custom-banner-heading custom-heading-without-background">
                    <span>{{ list.courseTitle }}</span>
                  </div>
                  <div class="custom-banner-p text-muted">
                    <p>{{ list.courseShortDescription }}</p>
                  </div>
                  <div class="row mt-4 w3-animate-bottom">
                    <div class="col-lg-12">

                      <div class="custom-form-submit-btn">
                        <button type="button" @click="addToCart(list)" class="btn custom-btn-search custom-employee-btn my-2 ">
                        <span class="custom-btn-more-text font-weight-bold">
                              Add to Cart
                              <i class="fas fa-shopping-cart"></i>
                            </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row  w3-animate-bottom">
                    <div class="col-lg-12">
                      <div class="custom-form-submit-btn">
                        <button type="button" @click="addToCart(list)" class="btn custom-btn-search custom-employee-btn my-2 ">
                        <span class="custom-btn-more-text font-weight-bold">
                              Buy Now
                            </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row  w3-animate-bottom">
                    <div class="col-lg-12">
                   <!-- New button for downloading PDF -->
    <div class="custom-form-submit-btn">
      <button type="button" @click="downloadPDF" class="btn custom-btn-search custom-employee-btn my-2">
        <span class="custom-btn-more-text font-weight-bold">
          DOWNLOAD APTITUDE TEST
          <i class="fas fa-download"></i>
        </span>
      </button>
    </div>
    </div>
    </div>
                  <div class="row  w3-animate-bottom">
                    <div class="col-lg-12">
                      <div v-if="list.courseCategoryId == 3" class="custom-form-submit-btn">
                        <router-link
                  to="/digital-courses"
                  class="btn custom-btn-search custom-employee-btn my-2 "
                >
                        <span class="custom-btn-more-text font-weight-bold">
                            Demo
                            </span>
                      </router-link>
                      </div>
                    </div>
                  </div>
                  <div v-if="$auth.check()" class="row  w3-animate-bottom">
                    <div class="col-lg-12">
                      <div class="custom-form-submit-btn">
                        <router-link :to="{name: 'SignUp'}"  class="btn custom-btn-search custom-employee-btn my-2 ">
                            <span class="custom-btn-more-text font-weight-bold">
                              Enroll Now
                            </span>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-5 custom-column-form-widget ">
                  <div >
                    <div v-if="list.courseImage != '' && list.courseImage != null" class="post-thumbnail mt-4">
                      <div class="sh-ratio">
                        <div class="">
                          <div class="custom-post-large-image">
                            <img :src="url+list.courseImage"  />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else class="post-thumbnail mt-4">
                      <div class="sh-ratio">
                        <div class="">
                          <div class="custom-post-large-image" >
                            <img src="../../assets/images/no-image.png" >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--  Content Start  -->
    <div class="custom-row-element-wrap mt-5 mb-5">
      <div class="pt-4"></div>
      <div class="row align-items-center">
        <div class="col-lg-12">
          <div class="post-container">

            <div class="custom-widget mb-4 border-bottom">
              <h2 class=" ">About This Course</h2>
              <p class="custom-service-text text-muted font-weight-bold">
                142,110 recent views
              </p>
            </div>
            <div>
              <div class="custom-service-text" v-html="list.courseDescription">
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--   Spacer   -->
      <div class="pt-5"></div>
      <div class="row align-items-center custom-bg">
        <div class="col-lg-12">
          <div class="mb-2">
            <div class="custom-widget mb-4 border-bottom">
              <h3 class=" ">Related Courses</h3>
            </div>
            <ul class="custom-alphabet-list">
              <li><button class="btn btn-outline-secondary">Data Sciences</button></li>
              <li><button class="btn btn-outline-secondary">Information Technology</button></li>
              <li><button class="btn btn-outline-secondary">Python</button></li>
              <li><button class="btn btn-outline-secondary">Java Development</button></li>
              <li><button class="btn btn-outline-secondary ">Learn HTML5 & CSS</button></li>
              <li><button class="btn btn-outline-secondary ">ASP.net</button></li>
            </ul>
            <div class=" clearfix"></div>
          </div>
        </div>
      </div>
      <!--   Spacer   -->
      <div class="pt-5"></div>
      <div class="row align-items-center custom-bg">
        <div class="col-lg-12">
          <div class="mt-2 mb-3">
            <div class="custom-widget mb-4 border-bottom">
              <h3 class=" text-center">Share This Course</h3>
            </div>
            <div class="custom-row justify-content-center ">
              <a :href="'https://'" type="url" target="_blank" class="custom-facebook-icon custom-icon-style mr-2 custom-social-link">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a :href="'https://'" type="url" target="_blank" class="custom-googleplus-icon custom-icon-style mr-2 custom-social-link">
                <i class="fab fa-google"></i>
              </a>
              <a :href="'https://'" type="url" target="_blank" class="custom-linkedin-icon custom-icon-style mr-2 custom-social-link">
                <i class="fab fa-linkedin-in"></i>
              </a>
              <a :href="'https://'" type="url" target="_blank" class="custom-twitter-icon custom-icon-style  custom-social-link">
                <i class="fab fa-twitter"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!--   Spacer   -->
      <div class="pt-5"></div>
      <div class="custom-bg pl-3 pr-3 pt-4 pb-4">
        <div v-if="courseDetailBeforeFooterAdd.length != 0" class="w-100 text-center">
          <a :href="courseDetailBeforeFooterAdd[0].url" :title="courseDetailBeforeFooterAdd[0].url" target="_blank" class="navbar-brand mr-0">
            <img :src="url+courseDetailBeforeFooterAdd[0].image" class="w-100" />
          </a>
        </div>
        <a v-else href="#">
          <div class="text-center">Advertisement</div>
        </a>
      </div>
    </div>
    <!--   Spacer   -->
    <div class="pt-5"></div>
    <!-- Video Modal-->
    <div class="modal fade" id="videoModal" tabindex="-1" role="dialog" aria-labelledby="videoModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <button type="button" @click="closeModal" class="close custom-close-btn" data-dismiss="modal"  aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item"
                    src="https://www.youtube.com/embed/Zv11L-ZfrSg"
                    allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// optional style for arrows & dots
import axios from 'axios'
import { UsersIcon } from 'vue-feather-icons'
export default {
  name: 'CourseDetail',
  components: {
    UsersIcon
  },
  data () {
    return {
      url: axios.defaults.baseURL,
      currentPage: 1,
      total: 1,
      list: [],
      PageOfItems: [],
      info: [],
      option: 0,
      loading: true,
      errored: false,
      componentKey: 0,
    }
  },
  computed:{
    courseDetailBeforeFooterAdd(){
      console.log("topbarAdd : ", this.$store.getters.availableCourseDetailBeforeFooterAdd)
      return this.$store.getters.availableCourseDetailBeforeFooterAdd
    }
  },
  methods: {
    getAdds(){
      this.$store.dispatch("fetchAdds");
    },
    downloadPDF() {
      const pdfUrl = 'https://smw-live.azurewebsites.net/UserFiles/disc-test-1aB2c3D4e.pdf';
      const anchor = document.createElement('a');
      anchor.href = pdfUrl;
      anchor.target = '_blank';
      console.log('file downloaded');
      anchor.click();
    },
    getAll() {
      this.rerender()
      this.list = [];
      console.log("id : ", this.$route.params.id)
      axios.get('/api/V_Learn/CourseDetail?Id='+this.$route.params.id)
        .then(res => {
          this.list = res.data.data
          console.log('course Detail : ', this.list)
        }).catch(error => {
        console.log(error)
        this.errored = true
      })
        .finally(() => {
          if (this.errored) {
            console.log('Error : Sorry there is no response')
          }
        })
    },
    closeModal(){
      $("#videoModal").on('hidden.bs.modal', function (e) {
        $("#videoModal iframe").attr("src", $("#videoModal iframe").attr("src"));
      });
    },
    //Cart Start
    addToCart(course){
      this.$store.dispatch("addCourseToCart", course)
      this.$router.push({ name: 'Checkout' })
    },
    showPrevious () {
      this.$refs.carousel.prev()
    },
    showNext () {
      this.$refs.carousel.next()
    },
    showPreviousLatestJob () {
      this.$refs.carousel_latest_jobs.prev()
    },
    showNextLatestJob () {
      this.$refs.carousel_latest_jobs.next()
    },
    showPreviousExecutiveJobs () {
      this.$refs.carousel_Executive_Jobs.prev()
    },
    showNextExecutiveJobs () {
      this.$refs.carousel_Executive_Jobs.next()
    },
    showPreviousTopEmployee () {
      this.$refs.carousel_Top_Employee.prev()
    },
    showNextTopEmployee () {
      this.$refs.carousel_Top_Employee.next()
    },
    showPreviousBrandsCar () {
      this.$refs.brands_carousel.prev()
    },
    showNextBrandsCar () {
      this.$refs.brands_carousel.next()
    },
    showPreviousTopEmployers () {
      this.$refs.top_employers_carousel.prev()
    },
    showNextTopEmployers () {
      this.$refs.top_employers_carousel.next()
    },
    //Rerender Component
    rerender () {
      this.componentKey += 1
    },
  },
  mounted () {
    this.getAdds();
    this.getAll();
  }
}
</script>
