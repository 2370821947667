<template>
  <div id="main-container" :key="componentKey">


    <!-- Banner Section For Web Start -->
<!--    <section class="webSection  custom-landing-banner-section1">
      <div class="custom-banner-background-overlay"></div>
      <div class="custom-section">
        <div class="custom-row-wrap">
          <div class="custom-row-element ">
            <div class="custom-row-element-wrap ">
              <div class="row custom-row-section ">
                <div class="col-lg-12 ">
                  <div class="text-center text-uppercase custom-banner-heading">
&lt;!&ndash;                    <span>Creativity</span>&ndash;&gt;
                    <span class="ml-4 mr-4" style="color: #ea216c">
                                      <b-icon width="1.5rem" icon="circle-fill"></b-icon>
                                    </span>
                    <span>Free Courses</span>
                    <span class="ml-4 mr-4" style="color: #ea216c">
                                      <b-icon width="1.5rem" icon="circle-fill"></b-icon>
                                    </span>
&lt;!&ndash;                    <span>Desire</span>&ndash;&gt;
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </section>-->





    <!--  Posts Start  -->
    <div class="custom-row-element-wrap mt-5 mb-5">
      <div class="row">
        <div class="col-lg-9">

        </div>
        <div class="col-lg-3"></div>
      </div>

      <div class="row">

        <div class="col-lg-9">
          <div class="custom-widget mt-2 mb-2 border-bottom ">
            <form class="mb-2">
              <div class="row">

                <div class="col-md-9">
                  <div class="">

                    <input type="text" class="form-control custom-input-gotopage custom-job-search-input" v-model="course.courseFilter.CourseTitle" placeholder="Search Our Courses">
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="">
                    <button type="button" @click="getAll(null)" class="btn btn-primary custom-employer-btn custom-btn-apply-now custom-btn-search ">
                      Search
                      <!--                      <b-icon icon="search"></b-icon>-->

                    </button>
                  </div>
                </div>

              </div>


            </form>
            <!--              <h2 class="widget-title text-uppercase">Latest Articles</h2>-->
          </div>
          <div class="post-container">

            <div class="custom-widget mt-2 mb-2 border-bottom ">
              <h2 class="widget-title text-uppercase">Latest Courses</h2>
            </div>
            <div v-for="(post, index) in courses" :key="post.courseId" class="blog-mini-post blog-mini-post-large">



              <div class="row no-gutters">
                <div class="col-lg-10">
                  <div class="blog-mini-post-thumb sh-post-review-mini">
                    <div v-if="post.courseImage != '' && post.courseImage != null">

                      <img :src="url+post.courseImage" class="custom-post-small-image"/>

                    </div>
                    <div v-else>

                      <img src="../../assets/images/no-image.png" class="custom-post-small-image"/>
                    </div>
                    <router-link
                      :to="{ name: 'CourseDetail',
                         params: { id: post.courseId }
                        }"
                      class=" post-overlay-small"
                    >

                      <div class="post-overlay-small"></div>
                    </router-link>
                  </div>

                  <div class="blog-mini-post-content">
                    <div class="blog-mini-post-content-container">
                      <router-link :to="{ name: 'CourseDetail',
                         params: { id: post.courseId }
                        }">
                        <h4 class="post-title">
                          {{ post.courseTitle }}
                        </h4>
                      </router-link>
                      <h5  class="post-title font-italic">
                        {{ post.courseShortDescription }}
                      </h5>
                      <p class="text-muted">{{ post.instructorName }}</p>
                      <div class="post-meta">
                        <div class="post-meta-content">
                      <span class="post-auhor-date row">
                        <div class="col-sm-4">
                          <b-icon class="icon" icon="book"></b-icon>
                          <span class="post-author ml-2">{{ post.totalHours }} Total Hours</span>
                        </div>
                          <div class="col-sm-4" >
                            <users-icon size="1.5x" class="custom-text-color custom-class"></users-icon>
                            <span class="post-author ml-2">{{ post.totalLectures}} Total Lectures</span>
                        </div>
                        <div class="col-sm-4" >
                            <users-icon size="1.5x" class="custom-text-color custom-class"></users-icon>
                            <span class="post-author ml-2">{{ post.courseType }}</span>
                        </div>
                      </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="">
                    <button type="button" @click="addToCart(post)" class="btn btn-primary custom-employer-btn custom-btn-apply-now custom-btn-search ">
                      Add to Cart
                      <!--                      <b-icon icon="search"></b-icon>-->

                    </button>
                  </div>
<!--                  <div class="mt-4">
                    <button type="button" @click="getAll(null)" class="btn btn-primary custom-employer-btn custom-btn-apply-now custom-btn-search custom-btn-bookmark ">
                      Search
                      &lt;!&ndash;                      <b-icon icon="search"></b-icon>&ndash;&gt;

                    </button>
                  </div>-->
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="col-lg-3">
          <div class="ml-1 mr-1 mt-2 mb-2">


            <div
              id="filter-tabs"
              class="nav nav-tabs custom-sidebar-nav-tabs w3-card-2"
              role="tablist"
            >


              <a
                href="#"
                class="w3-bar-item w3-button custom-filter custom-filter-item custom-active"
                data-toggle="collapse"
                data-target="#id-categoryFilter"
                aria-expanded="true"
                ariacontrols="id-categoryFilter"
              >
                <div class="custom-sidebar-heading">
                  <div class="custom-row">
                    <div class="custom-sidebar-icons">
                      <b-icon icon="funnel"></b-icon>
                    </div>
                    <div class="custom-sidebar-text">
                      <span>Category Filter</span>
                    </div>
                  </div>
                  <div class="custom-sidebar-icons custom-add-btn">
                    <a href="#" class="custom-sidebar-link">
                      <b-icon class="custom-arrow-icon" icon="chevron-down"></b-icon>
                    </a>
                  </div>
                </div>
              </a>
              <div
                id="id-categoryFilter"
                class="collapse custom-collapse custom-collapse-category show"
                aria-labelledby="filter-tabs"
                data-parent="#filter-tabs"
              >
                <a
                  v-for="cat in categories"
                  :key="cat.id"
                  href="javascript:void(0)"
                  class="w3-bar-item w3-button custom-filter-item custom-active"
                  @click="getAll(cat.id)"
                >
                  <div class="custom-sidebar-heading">
                    <div class="custom-row">
                      <div class="custom-sidebar-icons">
                      </div>
                      <div class="custom-sidebar-text">
                        <span>{{ cat.name }}</span>
                      </div>

                    </div>
                    <div class="custom-sidebar-icons custom-add-btn">

                        <i style="margin-left: -82px; font-size: 0.7rem;" class="custom-text-color">(Click to Filter)</i>

                    </div>
                  </div>
                </a>
<!--                <router-link
                  to="/"
                  class="w3-bar-item w3-button custom-filter-item custom-active"
                >
                  <div class="custom-sidebar-heading">
                    <div class="custom-row">
                      <div class="custom-sidebar-icons">
                      </div>
                      <div class="custom-sidebar-text">
                        <span>Category 2</span>
                      </div>
                    </div>
                    <div class="custom-sidebar-icons custom-add-btn">

                      <i class="custom-text-color">(1)</i>

                    </div>
                  </div>
                </router-link>
                <router-link
                  to="/"
                  class="w3-bar-item w3-button custom-filter-item custom-active"
                >
                  <div class="custom-sidebar-heading">
                    <div class="custom-row">
                      <div class="custom-sidebar-icons">
                      </div>
                      <div class="custom-sidebar-text">
                        <span>Category 3</span>
                      </div>
                    </div>
                    <div class="custom-sidebar-icons custom-add-btn">

                      <i class="custom-text-color">(1)</i>

                    </div>
                  </div>
                </router-link>
                <router-link
                  to="/"
                  class="w3-bar-item w3-button custom-filter-item custom-active"
                >
                  <div class="custom-sidebar-heading">
                    <div class="custom-row">
                      <div class="custom-sidebar-icons">
                      </div>
                      <div class="custom-sidebar-text">
                        <span>Category 4</span>
                      </div>
                    </div>
                    <div class="custom-sidebar-icons custom-add-btn">

                      <i class="custom-text-color">(1)</i>

                    </div>
                  </div>
                </router-link>-->
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>



  </div>
</template>

<script>

// optional style for arrows & dots

import axios from 'axios'
import { UsersIcon } from 'vue-feather-icons'
// import { RlCarouselSlide } from 'vue-renderless-carousel'
export default {
  name: 'Courses',
  components: {
    // SlidesComponent,
    // RlCarouselSlide
    UsersIcon
  },
  data () {
    return {


      url: axios.defaults.baseURL,
      currentPage: 1,
      total: 1,
      categories : [],
      list: null,
      PageOfItems: [],
      info: [],
      option: 0,

      //categories:[],

      course:{
        courseFilter:{
          CourseCategoryId: null,
          CourseTitle :null
        },
        typeId:0
      },





      loading: true,
      errored: false,


      componentKey: 0,
    }
  },
  //carousel_latest_jobs
  methods: {
    getCategories() {

debugger

      
      axios
        .post('/api/V_Learn/GetAllCourse1')
        .then(res => {
          // code that we will 'try' to run
          debugger

          this.categories =  res.data.data;
        })
      
      //Old
      /*for (const item of jobRecord) {
        if(!map.has(item.jobTypeId)){
          map.set(item.jobTypeId, true);    // set any value to Map
          this.categories.push({
            id: item.jobTypeId,
            name: item.jobTypeTitle
          });
        }
      }*/


      console.log("this.categories in getall : ",this.categories);
    },
    getAll(id, tid) {
      this.rerender()

      if(id != null){
        this.course.courseFilter.CourseCategoryId = Number(id);
        this.course.courseFilter.CourseTitle = null;
        // this.$store.dispatch("fetchCourses", this.courseFilter, tid)

      }
      else {
        this.course.courseFilter.CourseCategoryId = null;

      }

      this.course.typeId = tid;



      console.log("courseFilter : ",this.course);
      this.$store.dispatch("fetchCourses", this.course)



    /*  this.list = [];



      axios
        .post('/api/V_Learn/GetAllCourse', this.courseFilter)
        .then(res => {
          // code that we will 'try' to run
          let list = [];
          list = res.data.data

          console.log('Add Response portion')

          console.log('list : ', list)
          if(tid != undefined && tid != 0){
            for(let i in list){
              if(tid == list[i].courseType){
                this.list.push(list[i]);
              }
            }
            console.log('in if list : ', this.list)

            this.getCategories(this.list);
          }
      else {
            this.list = list.slice().reverse();
            console.log('list : ', this.list)
            this.getCategories(this.list);
          }



        })
        .catch(error => {
          console.log('Errorrrrrr : ', error)
          this.errored = true
        })
        .finally(() => {
          if (this.errored) {

            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {

          }
        })*/
    },


   

    showPrevious () {
      this.$refs.carousel.prev()
    },
    showNext () {
      this.$refs.carousel.next()
    },
    showPreviousLatestJob () {
      this.$refs.carousel_latest_jobs.prev()
    },
    showNextLatestJob () {
      this.$refs.carousel_latest_jobs.next()
    },
    showPreviousExecutiveJobs () {
      this.$refs.carousel_Executive_Jobs.prev()
    },
    showNextExecutiveJobs () {
      this.$refs.carousel_Executive_Jobs.next()
    },
    showPreviousTopEmployee () {
      this.$refs.carousel_Top_Employee.prev()
    },
    showNextTopEmployee () {
      this.$refs.carousel_Top_Employee.next()
    },
    showPreviousBrandsCar () {
      this.$refs.brands_carousel.prev()
    },
    showNextBrandsCar () {
      this.$refs.brands_carousel.next()
    },
    showPreviousTopEmployers () {
      this.$refs.top_employers_carousel.prev()
    },
    showNextTopEmployers () {
      this.$refs.top_employers_carousel.next()
    },


    //Rerender Component
    rerender () {
      this.componentKey += 1
    },
    addToCart(course){
      this.$store.dispatch("addCourseToCart", course)
    },
    removeFromCart(course){
      this.$store.dispatch("removeCourseFromCart", course)
    },
  },
  computed:{
    courses(){
      console.log("Available Courses", this.$store.getters.availableCourses)
      return this.$store.getters.availableCourses
    },
    categories(){
      console.log("Available availableCategories", this.$store.getters.availableCategories)
      return this.$store.getters.availableCategories
    }
  },

  created () {

    //console.log("Available Courses: ", this.courses)
    console.log("id : ",this.$route.params.id)
    console.log("tId : ",this.$route.params.tid)

     if(this.$route.params.id == 0){
       this.getAll(null, this.$route.params.tid);
     }
     else {
       this.getAll(this.$route.params.id, this.$route.params.tid);
     }


  },

  mounted () {
    this.getCategories();
    //console.log("Available Courses: ", this.courses)
    /*console.log("id : ",this.$route.params.id)
    console.log("tId : ",this.$route.params.tid)

    if(this.$route.params.id == 0){
      this.getAll(null, this.$route.params.tid);
    }
    else {
      this.getAll(this.$route.params.id, this.$route.params.tid);
    }*/





  }
}
</script>
