<template>
  <header class="custom-mainmenu">
    <div class="custom-topbar">
      <div class="custom-topbar-wrap">
        <nav class="custom-navbar-topbar navbar navbar-expand-lg navbar-light">
          <button
            id="btn-open-sidebar"
            class="custom-user-button navbar-toggler custom-sidebar-toggler"
            type="button"
            @click="w3_open()"
          >
            <b-icon
              class="custom-sidebar-toggler-icon w3-animate-opacity"
              icon="list"
            ></b-icon>
          </button>
          <button
            id="btn-close-sidebar"
            class="custom-user-button navbar-toggler custom-sidebar-toggler"
            type="button"
            @click="w3_close()"
          >
            <b-icon
              class="custom-sidebar-toggler-icon w3-animate-left"
              icon="x"
            ></b-icon>
          </button>
          <div class="custom-contact-info">
            <a
              class="text-decoration-none w3-hover-text-light-gray"
              href="tel:0092 423 7243810"
            >
              <span><i class="fas fa-phone-alt fa-md fa-fw mr-2"></i></span>
              <!--              <span>Phone: </span> -->
              <span class="custom-user-contacts custom-contact-info"
                >0092 423 7243810</span
              >
            </a>
          </div>
          <div class="custom-border-right"></div>
          <div class="custom-contact-info">
            <a
              class="text-decoration-none w3-hover-text-light-gray"
              href="mailto:info@viilearn.com"
            >
              <span><i class="fas fa-envelope fa-md fa-fw mr-2"></i></span>
              <!--            <span>Email:</span>-->

              <span class="custom-user-contacts custom-contact-info"
                >info@viilearn.com</span
              >
            </a>
          </div>

          <!--           <button
                      class="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#topbarSupportedContent"
                      aria-controls="topbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span class="navbar-toggler-icon"></span>
                    </button>-->

          <div
            class="custom-navbar-collapse navbar-collapse mr-3"
            id="topbarSupportedContent"
          >
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <a
                  class="nav-link custom-nav-link py-0"
                  href="https://www.facebook.com/Venterher.Offical/"
                  target="_blank"
                >
                  <i class="fab fa-facebook-square fa-md fa-fw"></i>
                  <span class="sr-only">(current)</span></a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link custom-nav-link py-0"
                  href="https://twitter.com/ventur_h"
                  target="_blank"
                >
                  <i class="fab fa-twitter fa-md fa-fw"></i>
                </a>
              </li>

              <li class="nav-item">
                <a
                  class="nav-link custom-nav-link py-0"
                  href="https://www.linkedin.com/company/ventureheroffical/"
                  target="_blank"
                >
                  <i class="fab fa-linkedin fa-md fa-fw"></i>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link custom-nav-link py-0"
                  href="https://www.youtube.com/channel/UCetNHbRcnPDyPdPlKwhGPjw"
                  target="_blank"
                >
                  <i class="fab fa-youtube fa-md fa-fw"></i>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link custom-nav-link py-0"
                  href="https://www.instagram.com/Ventureher1.offical/"
                  target="_blank"
                >
                  <i class="fab fa-instagram fa-md fa-fw"></i>
                </a>
              </li>
            </ul>
          </div>
          <!--     Vertical Border     -->
          <!--          <div v-if="!$auth.check()" class="custom-border-right"></div>
                    <div v-if="!$auth.check()" class="row no-gutters align-items-center">

                      <div class="custom-user-button">
                        <router-link :to="{ name: 'SignUp' }" class="nav-link custom-nav-link py-0">
                          <i class="fas fa-user fa-md fa-fw"></i>
                          SIGN UP
                        </router-link>

          &lt;!&ndash;              <a class="nav-link custom-nav-link py-0" href="javascript:void(0)"
                           @click="signUp">
                          <i class="fas fa-user fa-md fa-fw"></i>
                          SIGN UP
                        </a>&ndash;&gt;
                      </div>

                    </div>
                    &lt;!&ndash;     Vertical Border     &ndash;&gt;
                    <div v-if="!$auth.check()" class="custom-border-right"></div>
                    <div v-if="!$auth.check()" class="row no-gutters align-items-center">

                      <div class="custom-user-button">
                        <router-link :to="{ name: 'SignIn' }" class="nav-link custom-nav-link py-0">
                          <i class="fas fa-sign-in-alt fa-md fa-fw"></i>
                          SIGN IN
                        </router-link>

                      </div>

                    </div>
                    &lt;!&ndash;     Vertical Border     &ndash;&gt;
                    <div v-if="$auth.check()" class="custom-border-right"></div>
                    <div v-if="$auth.check()" class="row no-gutters align-items-center">

                        <div class="ml-4">
                          <a
                              class="custom-collapse-dropdown custom-nav-link nav-link py-0 dropdown-toggle custom-avatar-icon"
                              href="#"
                              id="navbar-user-dropdown"
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                          >
          &lt;!&ndash;                            <user-icon
                                size="1.3x"
                                stroke-width="1.3"
                                class="custom-class"
                            ></user-icon>&ndash;&gt;

                             <span class="b-avatar badge-info rounded-circle">
                              <span  v-if="$auth.user().user.role == 'Employer'" class="b-avatar-img">
                                <span v-if="$auth.user().employeeImage != null">
                                   <img :src="url+$auth.user().employeeImage">
                                 </span>
                                 <span v-else>

                                      <img src="../assets/images/no-image.png" >


                                 </span>
          &lt;!&ndash;                                  {{ usernamefirstletter }}&ndash;&gt;
          &lt;!&ndash;                        <img src="../assets/images/avatar-2.jpg">&ndash;&gt;

                              </span>
                               <span v-else class="b-avatar-img">

          &lt;!&ndash;                                  {{ usernamefirstletter }}&ndash;&gt;
                                 <span v-if="$auth.user().employeeImage != null">
                                   <img :src="url+$auth.user().employeeImage">
                                 </span>
                                 <span v-else>

                                      <img src="../assets/images/no-image.png" >


                                 </span>


                              </span>
                            </span>

                              <span class="text-capitalize custom-user-name custom-contact-info">{{ $auth.user().user.fullName }}</span>


                          </a>
                          <div
                              class="dropdown-menu dropdown-menu-right custom-user-dropdown"
                              aria-labelledby="navbar-user-dropdown"
                          >

                            <a
                                class="custom-row dropdown-item custom-dropdown-item"
                                href="javascript:void(0)"
                                @click="$auth.logout()"
                            >
                              <div>
                                <b-icon
                                    class="custom-ellipsis-icon"
                                    icon="box-arrow-right"
                                ></b-icon>
                              </div>
                              <div class="ml-3">Logout</div>
                            </a>
                          </div>
                        </div>
                    </div>-->
        </nav>
      </div>
    </div>
  </header>
</template>

<script>


import { UserIcon } from 'vue-feather-icons'
import axios from "axios";
export default {
  name: "Topbar",
  components:{
    UserIcon,

  },
  data(){
    return{
      status:0,

      // bool
      loggedOut:true,
      loggedIn:false,
      //userName: this.$auth.user().fullName,
      //email: this.$auth.user().email,
      //phoneNo:this.$auth.user().phoneNumber,
      //mailtoEmail:"mailto:"+this.$auth.user().email,

      //url:axios.defaults.baseURL,



    };

  },
  mounted(){

    //this.getAll();


    // this.$nextTick(() => {
    //   // Change top navbar on scroll
    //   $(window).on("scroll", function() {
    //     if($(window).scrollTop() > 100) {
    //       $("#ad-banner-id").addClass("active");
    //     } else {
    //       $("#ad-banner-id").removeClass("active");
    //     }
    //   });
    // });

  },



  methods: {
    getAll(){
      //this.rerender();
      this.employeelist = null;
      axios.get('/api/Employee/GetEmployeeProfile')
        .then(res =>{
          if (res.status != '200') {
            console.log('error.....')
            const error = new Error('Failed to fetch!');
            return error;
          }

          this.employeelist = res.data.employee;
          console.log("employeelist : ", this.employeelist);




          this.emp.FullName = this.employeelist.fullName;


          this.emp.mobileNumber = this.employeelist.mobileNumber;
          this.emp.Email = this.employeelist.email;

          this.emp.EmployeeImage = this.employeelist.employeeImage;


          console.log("img : ", this.url+this.emp.EmployeeImage);




          this.emp.Gender = this.employeelist.gender;








        }).catch(error => {
        console.log(error);
        this.errored = true;
      })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        });
    },
    w3_open: function() {
      document.getElementById("mySidebar").style.display = "block";

      document.getElementById("btn-open-sidebar").style.display = "none";
      document.getElementById("btn-close-sidebar").style.display = "block";
    },
    w3_close: function() {
      document.getElementById("mySidebar").style.display = "none";

      document.getElementById("btn-open-sidebar").style.display = "block";
      document.getElementById("btn-close-sidebar").style.display = "none";
    },


  },
};
</script>

<style>
/*#ad-banner-id{
  transition: all 0.2s ease-in-out;
  display: block;
}
#ad-banner-id.active{
  display: none;
  transition: all 0.2s ease-in-out;
}*/
</style>
