<template>
  <div class="container-fluid style-black-bg pt-3 pb-3">
    <div class="custom-row-element-wrap">
      <div class="row style-light-grey align-items-center">
        <div class="col-lg-6">
          <div class="style-right-reserved">
            <span>© {{ new Date().getFullYear() }}. All Rights Reserved.</span>
          </div>
        </div>
        <!--        <div class="col-lg-2">
          <div class="style-up-icon">
            <a href="#topbar_id" id="back-to-top" class="add-fix-top active">
              <i class="fa fa-angle-up" aria-hidden="true"></i>
            </a>
          </div>
        </div>-->
        <div class="col-lg-6">
          <div class="social">
            <span class="style-social-icons"
              ><a
                href="https://www.facebook.com/Shemeanswork.offical"
                class="facebook"
                target="_blank"
              >
                <i class="fab fa-facebook"></i
              ></a>
            </span>
            <span class="style-social-icons"
              ><a
                href="https://twitter.com/shemeanswork1"
                class="twitter"
                target="_blank"
              >
                <i class="fab fa-twitter"></i> </a
            ></span>
            <span class="style-social-icons"
              ><a
                href="https://www.youtube.com/channel/UCetNHbRcnPDyPdPlKwhGPjw"
                class="youtube"
                target="_blank"
              >
                <i class="fab fa-youtube"></i
              ></a>
            </span>
            <span class="style-social-icons"
              ><a
                href="https://www.linkedin.com/company/shemeanswork/"
                class="linkedin"
                target="_blank"
              >
                <i class="fab fa-linkedin"></i></a
            ></span>

            <span class="style-social-icons"
              ><a
                href="https://www.instagram.com/shemeanswork.official/"
                class="instagram"
                target="_blank"
              >
                <i class="fab fa-instagram"></i
              ></a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:"Footer"
}
</script>
