<template>
  <header class="custom-mainmenu sticky-top">

    <div class="">
      <nav class="custom-navbar navbar navbar-expand-lg navbar-light">

        <div></div>




        <div
          class="navbar-collapse custom-multi-navbar ml-3 mr-3"
          id="headerSupportedContent"
        >

          <div  class="navbar mr-auto w-100">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item mr-5">
                <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" :to="{ name: 'Home'}">
                  Home
                </router-link
                >
              </li>
              <li class="nav-item dropdown mr-5">
                <a class="nav-link custom-menu-link py-1 px-0 mr-3 dropdown-toggle" href="#" id="aboutusDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  About Us
                </a>
                <div class="dropdown-menu w3-animate-opacity" aria-labelledby="aboutusDropdown">
                  <router-link v-if="ourViiLearnStory.length != 0" class="dropdown-item custom-menu-link"
                               :to="{ name: 'Detail',
                                params: { id: ourViiLearnStory[0].id }
                                }"
                  >Knowledge & Training For Everyone</router-link>
                  <router-link v-if="buildYouOwnCourses.length != 0" class="dropdown-item custom-menu-link"
                               :to="{ name: 'Detail',
                                params: { id: buildYouOwnCourses[0].id }
                                }"
                  >Our Partners & Services For Corporates</router-link>

<!--                  <router-link class="dropdown-item custom-menu-link" to="/courses/0,0">Free Courses</router-link>-->
                  <router-link :to="{ name: 'spareasmile'}" class="dropdown-item custom-menu-link" >Spare a Smile</router-link>
                  <router-link class="dropdown-item custom-menu-link" to="/mentorship-sessions">Our Mentorship Sessions</router-link>

                  <router-link class="dropdown-item custom-menu-link" to="/faqs">FAQ's</router-link>
                </div>
              </li>
              <li class="nav-item dropdown mr-5">
                <a class="nav-link custom-menu-link py-1 px-0 mr-3 dropdown-toggle" href="#" id="coursesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Courses
                </a>
                <div class="dropdown-menu w3-animate-opacity" aria-labelledby="coursesDropdown">
                  <router-link class="dropdown-item custom-menu-link" to="/courses/0,0">All Courses</router-link>
<!--                  <router-link class="dropdown-item custom-menu-link" to="/courses/0,1">Take A Free Course</router-link>
                  <router-link class="dropdown-item custom-menu-link" to="/course-categories">Categories</router-link>-->
                  <router-link class="dropdown-item custom-menu-link" to="/courses/0,2">Courses With Partners</router-link>



                </div>
              </li>
              <li class="nav-item dropdown mr-5">
                <a class="nav-link custom-menu-link py-1 px-0 mr-3 dropdown-toggle" href="#" id="resourcesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Resources
                </a>
                <div class="dropdown-menu w3-animate-opacity" aria-labelledby="resourcesDropdown">
<!--                  <router-link class="dropdown-item custom-menu-link" to="/articles">Articles</router-link>
                  <router-link class="dropdown-item custom-menu-link" to="/mentorship-program">Mentorship Programs</router-link>-->
                  <router-link class="dropdown-item custom-menu-link" to="/videos">Videos & Webinars</router-link>
<!--                  <router-link class="dropdown-item custom-menu-link" to="/">Webinars</router-link>-->


                </div>
              </li>
              <li class="nav-item dropdown mr-5">
                <a class="nav-link custom-menu-link py-1 px-0 mr-3 dropdown-toggle" href="#" id="smwDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  SMW
                </a>
                <div class="dropdown-menu w3-animate-opacity" aria-labelledby="smwDropdown">
                  <a class="dropdown-item custom-menu-link" href="https://shemeanswork.com/" target="_blank">She Means Work</a>
                  <a class="dropdown-item custom-menu-link" href="https://harshehar.com/" target="_blank">Harshehar</a>
                  <a class="dropdown-item custom-menu-link" href="https://ventureher.com/" target="_blank">Ventureher</a>
                  <a class="dropdown-item custom-menu-link" href="https://www.hecancy.com/" target="_blank" >Hecancy</a>


                </div>
              </li>
              <li class="nav-item dropdown mr-5">
                <a class="nav-link custom-menu-link py-1 px-0 mr-3 dropdown-toggle" href="#" id="registerMeDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Register Me
                </a>
                <div class="dropdown-menu w3-animate-opacity" aria-labelledby="registerMeDropdown">
                  <router-link class="dropdown-item custom-menu-link" to="/mentee-form">Be A Mentee</router-link>
                  <router-link class="dropdown-item custom-menu-link" to="/mentor-form">Be A Mentor</router-link>
                  <router-link class="dropdown-item custom-menu-link" to="/BeAVolunteer">Be A Volunteer</router-link>
                  <router-link class="dropdown-item custom-menu-link" to="/partner-form">Register As Partner</router-link>
                  <router-link class="dropdown-item custom-menu-link" to="/alumni-form">Register As Alumni</router-link>
                </div>
              </li>
            </ul>
            <div class="">
              <div class="input-group custom-search-input">
                <div class="input-group-prepend">
                    <span
                      class="input-group-text custom-input-group-icon"
                      id="basic-addon1"
                    ><b-icon icon="Search"></b-icon
                    ></span>
                </div>
                <input
                  type="text"
                  class="form-control custom-filter-input form-control-sm"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
            <div class="ml-2 custom-cart-wrapper " v-if="cartTotalItems">
              <router-link :to="{name: 'Checkout'}" class="text-decoration-none custom-cart-link">
                <b-icon icon="cart4" font-scale="1.5">

                </b-icon>
                <span class="custom-cart-badge-position" >
                  <b-badge class="custom-cart-badge">{{ cartTotalItems }} <span class="sr-only">unread messages</span></b-badge>
                </span>

              </router-link>

            </div>
          </div>

          <!--            <template v-if="$auth.check()" >
                        &lt;!&ndash; <ul v-if="$auth.check()" class="navbar-nav mr-auto"> &ndash;&gt;

                           <ul  v-if="$auth.user().user.role == 'Employer'" class="navbar-nav mr-auto">
                        <li class="nav-item mr-2">
                          <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/employer-dashboard">
                            Dashboard
                          </router-link
                          >
                        </li>
                        <li class="nav-item dropdown mr-2">
                          <a class="nav-link custom-menu-link py-1 px-0 mr-3 dropdown-toggle" href="#" id="myJobsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            My Jobs
                          </a>
                          <div class="dropdown-menu w3-animate-opacity" aria-labelledby="myJobsDropdown">
                            <router-link class="dropdown-item" to="/employer-jobs">All Listed Jobs</router-link>
                            <router-link class="dropdown-item" to="/employer-candidates/0">All Employee Profile</router-link>


                          </div>
                        </li>

          &lt;!&ndash;              <li class="nav-item dropdown mr-2">
                          <a class="nav-link custom-menu-link py-1 px-0 mr-3 dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            CV Folder
                          </a>
                          <div class="dropdown-menu w3-animate-opacity" aria-labelledby="navbarDropdown">
                            <router-link class="dropdown-item" to="/employer-jobs">Description of Jobs</router-link>
                            <router-link class="dropdown-item" to="/employer-candidates">CV via Unassisted Hiring</router-link>
                            <router-link class="dropdown-item" to="/employer-candidates">CV via Assisted Hiring</router-link>
                            <router-link class="dropdown-item" to="/employer-candidates">CV upload within a Department</router-link>

                          </div>
                        </li>&ndash;&gt;


                        <li class="nav-item dropdown mr-2">
                          <a class="nav-link custom-menu-link py-1 px-0 mr-3 dropdown-toggle" href="#" id="myToolsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            My Tools
                          </a>
                          <div class="dropdown-menu w3-animate-opacity" aria-labelledby="myToolsDropdown">
          &lt;!&ndash;                  <router-link class="dropdown-item" to="/employer-analytics">Company</router-link>&ndash;&gt;
          &lt;!&ndash;                  <router-link class="dropdown-item" to="/employer-about-us">Company Public Profile</router-link>&ndash;&gt;
          &lt;!&ndash;                  <router-link class="dropdown-item" to="/employer-teamlist">Department</router-link>&ndash;&gt;


                            <router-link class="dropdown-item" to="/employer-profile">Update Company Profile</router-link>
                            <router-link class="dropdown-item" to="/employer-teamlist">Team Members</router-link>
                            <router-link class="dropdown-item" to="/change-password">Account Settings</router-link>
                            <router-link class="dropdown-item" to="/employer-accountsetting">Alerts / Notifications </router-link>
                            <router-link class="dropdown-item" to="/employer-packagehistory">Purchase History </router-link>
                            <router-link class="dropdown-item" to="/employer-package">Packages </router-link>


                          </div>
                        </li>
                        &lt;!&ndash;              <li class="nav-item mr-2">
                                        <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/">
                                          My Account
                                        </router-link>

                                      </li>&ndash;&gt;

                        &lt;!&ndash; <li class="nav-item mr-2">
                          <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/">
                            Employer
                          </router-link>
                        </li> &ndash;&gt;

                       <li class="nav-item mr-2">
                         <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/cvsearch">
                           CV Search
                         </router-link>
                       </li>
                        <li class="nav-item mr-2">
                          <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/employer-about-us/0">
                            About Us
                          </router-link>
                        </li>
                         <li class="nav-item mr-2">
                           <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/employer-postjob">
                             Post A Job
                           </router-link>
                         </li>
                      </ul>
                          <ul v-else class="navbar-nav mr-auto">
                        <li class="nav-item mr-2">
                          <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/employee-dashboard">
                            Dashboard
                          </router-link
                          >
                        </li>
                        <li class="nav-item dropdown mr-2">
                          <a class="nav-link custom-menu-link py-1 px-0 mr-3 dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            My Account
                          </a>
                          <div class="dropdown-menu w3-animate-opacity" aria-labelledby="navbarDropdown">
                            <router-link class="dropdown-item" to="/employee-profile">Profile</router-link>
                            <router-link class="dropdown-item" to="/employee-jobs">Applications</router-link>
                            <router-link class="dropdown-item" to="/purchase-history">Purchase History</router-link>
                            <router-link class="dropdown-item" :to="{ name: 'ChangePassword'}">Change Password</router-link>

                          </div>
                        </li>
                        &lt;!&ndash;              <li class="nav-item mr-2">
                                        <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/">
                                          My Account
                                        </router-link>

                                      </li>&ndash;&gt;
                        <li class="nav-item mr-2">
                          <router-link
                              class="nav-link custom-menu-link py-1 px-0 mr-3"
                              to="/all-jobs"
                          >
                            Find Job
                          </router-link>
                        </li>
                        <li class="nav-item mr-2">
                          <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/">
                            Employer
                          </router-link>
                        </li>


                        <li class="nav-item mr-2">
                          <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/about-us">
                            About Us
                          </router-link>
                        </li>
                        <li class="nav-item mr-2">
                          <router-link class="nav-link custom-menu-link py-1 px-0 mr-3" to="/add-profile-detail">
                            Complete Your Profile
                          </router-link>
                        </li>
                      </ul>
                    </template>-->
        </div>

        <div></div>
      </nav>
    </div>




  </header>
</template>

<script>

export default {
  name: "MainMenu",
  components:{

  },
  data(){
    return{
      status:0,

      // bool
      loggedOut:true,
      loggedIn:false,
      //userName: this.$auth.user().fullName,
      //email: this.$auth.user().email,
      //phoneNo:this.$auth.user().phoneNumber,
      //mailtoEmail:"mailto:"+this.$auth.user().email,

      //url:axios.defaults.baseURL,



    };

  },
  mounted(){

    this.getAll();


    // this.$nextTick(() => {
    //   // Change top navbar on scroll
    //   $(window).on("scroll", function() {
    //     if($(window).scrollTop() > 100) {
    //       $("#ad-banner-id").addClass("active");
    //     } else {
    //       $("#ad-banner-id").removeClass("active");
    //     }
    //   });
    // });

  },
  beforeCreate () {

  },
  computed:{
    cartTotalItems(){
      return this.$store.getters.cartTotalCourses
    },
    ourViiLearnStory(){
      console.log("this.$store.state.ourViiLearnStory", this.$store.state.ourViiLearnStory)
      return this.$store.state.ourViiLearnStory
    },
    buildYouOwnCourses(){
      console.log("this.$store.state.buildYouOwnCourses", this.$store.state.buildYouOwnCourses)
      return this.$store.state.buildYouOwnCourses
    },
  },


  methods: {
    getAll(){


      this.$store.dispatch("fetchOurViiLearnStory");
      this.$store.dispatch("fetchBuildYouOwnCourses");



    },



  },
};
</script>

<style>
/*#ad-banner-id{
  transition: all 0.2s ease-in-out;
  display: block;
}
#ad-banner-id.active{
  display: none;
  transition: all 0.2s ease-in-out;
}*/
</style>
