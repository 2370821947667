<template>
  <div id="main-container" :key="componentKey">


    <!-- Banner Section For Web Start -->
<!--    <section class="webSection  custom-landing-banner-section1">
      <div class="custom-banner-background-overlay"></div>
      <div class="custom-section">
        <div class="custom-row-wrap">
          <div class="custom-row-element ">
            <div class="custom-row-element-wrap ">
              <div class="row custom-row-section ">
                <div class="col-lg-12 ">
                  <div class="text-center text-uppercase custom-banner-heading">
                    &lt;!&ndash;                    <span>Creativity</span>&ndash;&gt;
                    <span class="ml-4 mr-4" style="color: #ea216c">
                      <b-icon width="1.5rem" icon="circle-fill"></b-icon>
                    </span>
                    <span>Frequently Asked Questions</span>
                    <span class="ml-4 mr-4" style="color: #ea216c">
                      <b-icon width="1.5rem" icon="circle-fill"></b-icon>
                    </span>
                    &lt;!&ndash;                    <span>Desire</span>&ndash;&gt;
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </section>-->

    <!--  Posts Start  -->
    <div class="custom-row-element-wrap mt-3 mb-5">
      <div class="pt-4"></div>
      <div class="text-center mb-5 ml-auto mr-auto w-75">

        <div class="custom-row justify-content-center mt-2">
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
          <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
<!--            <i class="far fa-user"></i>-->
            <b-icon icon="question-square"></b-icon>
          </span>
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
        </div>
        <div  class="custom-widget  mb-2">
          <!--          <span style="color: #ea216c;font-size: 18px">{{ ourViiLearnStory[0].title }}</span>-->
          <h2 class="custom-text-color">Frequently Asked Questions</h2>
          <!-- <img style="
                width: 90%;
    margin-left: 4%;
    height: 295px;
            " src="../assets/images/FAQ1.jpg"/> -->
        </div>
      </div>
      <div id="accordion">
        <div class="custom-widget mt-2 mb-3 border-bottom ">
          <h2 class="widget-title text-uppercase">Courses</h2>
        </div>
        <div  class="row no-gutters">

          <div  class="col-lg-12 mb-2" v-for="(faq, index) in courses" :key="faq.faqId">
            <div class="card custom-faq-wrap border-0">
              <div class="custom-faq-collapse">
                <div class="custom-row custom-text-color">
                <span class="mr-2">
                  <b-icon icon="chevron-right"></b-icon>
                </span>
                  <span class="">
                  {{ faq.question }}
                </span>
                </div>

              </div>
              <div class="custom-row">
                <span class="mr-4">

                </span>
                <span v-html="faq.answer">

                </span>
              </div>
            </div>
          </div>
<!--          <div class="col-lg-4 mb-2">
            <div class="card custom-faq-wrap">
              <a href="javascript:void(0)"   data-toggle="collapse" data-target="#collapse2" aria-expanded="false" aria-controls="collapse2" >
                <div class="custom-faq-collapse">
                  <div class="custom-row">
                <span class="mr-2">
                  <b-icon icon="chevron-right"></b-icon>
                </span>
                    <span>
                  Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid ?
                </span>
                  </div>

                </div>
              </a>
              <div class="">
                <div id="collapse2" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                  <div class="card-body">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 mb-2">
            <div class="card custom-faq-wrap">
              <a href="javascript:void(0)"   data-toggle="collapse" data-target="#collapse3" aria-expanded="false" aria-controls="collapse3" >
                <div class="custom-faq-collapse">
                  <div class="custom-row">
                <span class="mr-2">
                  <b-icon icon="chevron-right"></b-icon>
                </span>
                    <span>
                  Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid ?
                </span>
                  </div>

                </div>
              </a>
              <div class="">
                <div id="collapse3" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                  <div class="card-body">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                  </div>
                </div>
              </div>
            </div>
          </div>-->


        </div>
        <div class="custom-widget mt-3 mb-3 border-bottom ">
          <h2 class="widget-title text-uppercase">Mentorship Programs</h2>
        </div>
        <div class="row no-gutters">

          <div  class="col-lg-12 mb-2" v-for="(faq, index) in mentorshipPrograms" :key="faq.faqId">
            <div class="card custom-faq-wrap border-0">
              <div class="custom-faq-collapse">
                <div class="custom-row custom-text-color">
                <span class="mr-2">
                  <b-icon icon="chevron-right"></b-icon>
                </span>
                  <span>
                  {{ faq.question }}
                </span>
                </div>

              </div>
              <div class="custom-row">
                <span class="mr-4">

                </span>
                <span v-html="faq.answer">

                </span>
              </div>

            </div>
          </div>

        </div>
        <div class="custom-widget mt-3 mb-3 border-bottom ">
          <h2 class="widget-title text-uppercase">Become The Partner</h2>
        </div>
        <div class="row no-gutters">

          <div  class="col-lg-12 mb-2" v-for="(faq, index) in becomePartner" :key="faq.faqId">
            <div class="card custom-faq-wrap border-0">
              <div class="custom-faq-collapse">
                <div class="custom-row custom-text-color">
                <span class="mr-2">
                  <b-icon icon="chevron-right"></b-icon>
                </span>
                  <span>
                  {{ faq.question }}
                </span>
                </div>

              </div>
              <div class="custom-row">
                <span class="mr-4">

                </span>
                <span v-html="faq.answer">

                </span>
              </div>

            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>

// optional style for arrows & dots

import axios from 'axios'

// import { RlCarouselSlide } from 'vue-renderless-carousel'
export default {
  name: 'FAQs',
  components: {
    // SlidesComponent,
    // RlCarouselSlide

  },
  data () {
    return {
      url: axios.defaults.baseURL,
      currentPage: 1,
      total: 1,

      list: null,
      PageOfItems: [],
      info: [],
      option: 0,

      courses:[],
      mentorshipPrograms:[],
      becomePartner:[],



      loading: true,
      errored: false,


      componentKey: 0,
    }
  },
  //carousel_latest_jobs
  methods: {
    getAll() {
      this.rerender()

      this.list = null;
      let model = {
        "pageSize": 0,
        "pageNumber": 0,
        "totalRecord": 0,
        "text": ""
      };

      axios.post('/api/V_Learn/GetFAQ', model)
        .then(res => {


          let list = [];
          list = res.data.data

          console.log('Add Response portion')

          console.log('list : ', list)
          this.list = list.slice().reverse();
          console.log('list : ', this.list)
          //let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
          for (let data in this.list){
            console.log("this.list[post]: ",this.list[data])
            if(this.list[data].faqCategory  === "Course"){
              this.courses.push(this.list[data]);
            }

          }

          console.log("courses faq : ", this.courses);
          for (let data in this.list){
            console.log("this.list[post]: ",this.list[data])
            if(this.list[data].faqCategory  === "MentorShipPrograms"){
              this.mentorshipPrograms.push(this.list[data]);
            }

          }

          console.log("mentorshipPrograms faq : ", this.mentorshipPrograms);
          for (let data in this.list){
            console.log("this.list[post]: ",this.list[data])
            if(this.list[data].faqCategory  === "BecomeThePartner"){
              this.becomePartner.push(this.list[data]);
            }

          }

          console.log("becomePartner faq : ", this.becomePartner);


        }).catch(error => {
        console.log(error)
        this.errored = true
      })
        .finally(() => {
          if (this.errored) {

            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        })
    },



    showPrevious () {
      this.$refs.carousel.prev()
    },
    showNext () {
      this.$refs.carousel.next()
    },
    showPreviousLatestJob () {
      this.$refs.carousel_latest_jobs.prev()
    },
    showNextLatestJob () {
      this.$refs.carousel_latest_jobs.next()
    },
    showPreviousExecutiveJobs () {
      this.$refs.carousel_Executive_Jobs.prev()
    },
    showNextExecutiveJobs () {
      this.$refs.carousel_Executive_Jobs.next()
    },
    showPreviousTopEmployee () {
      this.$refs.carousel_Top_Employee.prev()
    },
    showNextTopEmployee () {
      this.$refs.carousel_Top_Employee.next()
    },
    showPreviousBrandsCar () {
      this.$refs.brands_carousel.prev()
    },
    showNextBrandsCar () {
      this.$refs.brands_carousel.next()
    },
    showPreviousTopEmployers () {
      this.$refs.top_employers_carousel.prev()
    },
    showNextTopEmployers () {
      this.$refs.top_employers_carousel.next()
    },


    //Rerender Component
    rerender () {
      this.componentKey += 1
    },
  },

  mounted () {

    this.getAll();
  }
}
</script>
