var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.componentKey},[_c('div',{staticClass:"custom-row-element-wrap mt-5 mb-5"},[_c('div',{staticClass:"post-container"},[_vm._m(0),_c('div',[(_vm.show)?_c('form',{staticClass:"needs-validation",class:[!_vm.isValidated  ? '':'', _vm.isValidated ? 'was-validated':''],attrs:{"autocomplete":"off","novalidate":""}},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"row"},[_c('legend',{staticClass:"col-form-label col-sm-2 pt-0"},[_vm._v("NTN Obtained")]),_c('div',{staticClass:"col-sm-10"},[_c('b-form-group',{staticClass:"border-0"},[_c('b-form-radio-group',{attrs:{"options":_vm.ntnObtainedOptions,"name":"radio-inline1","required":""}})],1)],1)])]),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_c('div',{staticClass:"modal-footer"},[(_vm.showCloseButton)?_c('div',[_c('b-button',{staticClass:"form-control form-control-sm custom-form-btn custom-btn-gotopage",attrs:{"id":"showCloseButton","type":"button","size":"sm","variant":"outline-dark","data-dismiss":"modal"},on:{"click":_vm.close}},[_vm._v("Close ")])],1):_vm._e(),(_vm.showResetButton)?_c('div',[_c('b-button',{staticClass:"form-control form-control-sm custom-form-btn custom-btn-gotopage",attrs:{"id":"showResetButton","type":"reset","size":"sm","variant":"outline-dark"}},[_vm._v("Reset ")])],1):_vm._e(),_c('div',[(_vm.showSaveButton)?_c('b-button',{staticClass:"form-control form-control-sm custom-form-btn custom-btn-gotopage",attrs:{"size":"sm","variant":"outline-dark"},on:{"click":function($event){return _vm.AddOrUpdate()}}},[_vm._v("Submit ")]):_vm._e()],1)])]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-widget mt-2 mb-4 border-bottom"},[_c('h2',{staticClass:"widget-title text-uppercase"},[_vm._v("Contact Us")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label",attrs:{"for":"inputName"}},[_vm._v(" Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-10"},[_c('input',{staticClass:"form-control form-control-sm custom-input-gotopage",attrs:{"type":"text","id":"inputName","placeholder":"Name","required":""}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',{staticClass:"w3-animate-opacity"},[_vm._v("Please Enter Your Name")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label",attrs:{"for":"inputAddress"}},[_vm._v(" Address "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-10"},[_c('input',{staticClass:"form-control form-control-sm custom-input-gotopage",attrs:{"type":"text","id":"inputAddress","placeholder":"Address","required":""}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',{staticClass:"w3-animate-opacity"},[_vm._v("Please Enter Your Address")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label",attrs:{"for":"inputPhoneNo"}},[_vm._v(" Phone # "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-10"},[_c('input',{staticClass:"form-control form-control-sm custom-input-gotopage",attrs:{"type":"text","id":"inputPhoneNo","placeholder":"Phone #","required":""}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',{staticClass:"w3-animate-opacity"},[_vm._v("Please Enter Your Phone Number")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label",attrs:{"for":"inputEmail"}},[_vm._v(" Email "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-10"},[_c('input',{staticClass:"form-control form-control-sm custom-input-gotopage",attrs:{"type":"email","id":"inputEmail","placeholder":"Email","required":""}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',{staticClass:"w3-animate-opacity"},[_vm._v("Please Enter Your Email Address")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label",attrs:{"for":"inputCompanyName"}},[_vm._v(" Company Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-10"},[_c('input',{staticClass:"form-control form-control-sm custom-input-gotopage",attrs:{"type":"text","id":"inputCompanyName","placeholder":"Company Name","required":""}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',{staticClass:"w3-animate-opacity"},[_vm._v("Please Enter your Company Name")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label",attrs:{"for":"inputWebsite"}},[_vm._v("Website")]),_c('div',{staticClass:"col-sm-10"},[_c('input',{staticClass:"form-control form-control-sm custom-input-gotopage",attrs:{"type":"text","id":"inputWebsite","placeholder":"Website"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label",attrs:{"for":"inputMonthlyTurnover"}},[_vm._v("Monthly Turnover")]),_c('div',{staticClass:"col-sm-10"},[_c('input',{staticClass:"form-control form-control-sm custom-input-gotopage",attrs:{"type":"number","id":"inputMonthlyTurnover","placeholder":"Monthly Turnover"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label",attrs:{"for":"inputServicesRequired"}},[_vm._v(" Services Required "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-10"},[_c('select',{staticClass:"custom-select-option form-control form-control-sm",attrs:{"id":"inputServicesRequired"}},[_c('option',{attrs:{"value":""}},[_vm._v("Choose ...")])]),_c('div',{staticClass:"invalid-feedback"},[_c('span',{staticClass:"w3-animate-opacity"},[_vm._v("Please select the Services Required")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-sm-2 col-form-label",attrs:{"for":"inputDetailedDescription"}},[_vm._v("Detailed Description")]),_c('div',{staticClass:"col-sm-10"},[_c('textarea',{staticClass:"form-control form-control-sm custom-input-gotopage",attrs:{"type":"text","rows":"3","id":"inputDetailedDescription","placeholder":"Detailed Description","maxlength":"2000"}})])])
}]

export { render, staticRenderFns }