var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.componentKey,attrs:{"id":"main-container"}},[_c('div',{staticClass:"custom-row-element-wrap mt-5 mb-5"},[_c('div',{staticClass:"text-center mb-5 ml-auto mr-auto w-75"},[(_vm.ourMission.length != 0)?_c('div',{staticClass:"custom-row justify-content-center mt-2"},[_c('span',{staticStyle:{"width":"25px","border-bottom":"2px solid  #ea216c","height":"10px"}}),_c('span',{staticClass:"ml-1 mr-1",staticStyle:{"color":"#ea216c","font-size":"24px"}},[_c('b-icon',{attrs:{"icon":"person"}})],1),_c('span',{staticStyle:{"width":"25px","border-bottom":"2px solid  #ea216c","height":"10px"}})]):_vm._e(),(_vm.ourMission.length != 0)?_c('div',[_c('div',{staticClass:"custom-widget mb-2"},[_c('h2',{staticClass:"custom-text-color"},[_vm._v(_vm._s(_vm.ourMission[0].title))])]),_c('div',{staticClass:"text-muted"},[_c('p',[_vm._v(" "+_vm._s(_vm.ourMission[0].shortDescription)+" ")])])]):_vm._e()]),_c('div',{staticClass:"pt-4"}),_c('div',[(_vm.ourMission.length != 0)?_c('div',{staticClass:"text-center clear-bottom"},[_c('router-link',{staticClass:"btn link-theme text-decoration-none",attrs:{"to":{ name: 'Detail',
          params: { id: _vm.ourMission[0].id }
          }}},[_vm._v(" Read More ")])],1):_vm._e()]),_c('div',{staticClass:"pt-5"}),_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-4"},[(_vm.spareASmile.length != 0)?_c('div',[_c('img',{staticClass:"custom-post-large-image-partner",attrs:{"src":_vm.url+_vm.spareASmile[0].image}})]):_c('div',[_c('b-alert',{attrs:{"show":""}},[_vm._v("Loading...")])],1)]),_c('div',{staticClass:"col-lg-8"},[(_vm.spareASmile.length != 0)?_c('div',{staticClass:"post-container"},[_c('div',{},[_c('h2',{staticClass:""},[_vm._v(_vm._s(_vm.spareASmile[0].title))])]),_c('div',[_c('p',{staticClass:"custom-service-text"},[_vm._v(" "+_vm._s(_vm.spareASmile[0].shortDescription)+" ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-9"}),_c('div',{staticClass:"col-md-3"},[_c('div',[(_vm.spareASmile.length != 0)?_c('div',{staticClass:"clear-bottom mt-2"},[_c('router-link',{staticClass:"btn link-theme text-decoration-none",attrs:{"to":{ name: 'Detail',
                              params: { id: _vm.spareASmile[0].id }
                              },"href":"#"}},[_vm._v(" Read More ")])],1):_vm._e()])])])]):_vm._e()])]),_c('div',{staticClass:"pt-5"}),_c('div',{staticClass:"text-center mb-5 ml-auto mr-auto w-75"},[_c('div',{staticClass:"custom-row justify-content-center mt-2"},[_c('span',{staticStyle:{"width":"25px","border-bottom":"2px solid  #ea216c","height":"10px"}}),_c('span',{staticClass:"ml-1 mr-1",staticStyle:{"color":"#ea216c","font-size":"24px"}},[_c('b-icon',{attrs:{"icon":"person"}})],1),_c('span',{staticStyle:{"width":"25px","border-bottom":"2px solid  #ea216c","height":"10px"}})]),_vm._m(0)]),_c('div',{staticClass:""},[_c('div',{},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-lg-12"},[(_vm.ourPrograms.length != 0)?_c('VueSlickCarousel',_vm._b({ref:"brands_carousel"},'VueSlickCarousel',_vm.settingsForBrands,false),_vm._l((_vm.ourPrograms),function(br){return _c('div',{key:br.id},[_c('article',{staticClass:"mb-5"},[_c('div',{staticClass:"post-container"},[_c('div',{staticClass:"post-thumbnail mt-4"},[_c('div',{staticClass:"sh-ratio"},[_c('div',{},[_c('div',{staticClass:"custom-post-large-image-course"},[(br.image != null)?_c('img',{attrs:{"src":_vm.url+br.image}}):_c('img',{attrs:{"src":require("../assets/images/no-image.png")}})])])]),_c('router-link',{staticClass:"post-overlay",attrs:{"to":{ name: 'Detail',
                         params: { id: br.id }
                        },"title":br.title}},[_c('div',{},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')])])],1),_c('div',{},[_c('div',{staticClass:"post-content-container mt-3"},[_c('div',{staticClass:"post-title"},[_c('h5',[_vm._v(" "+_vm._s(br.title)+" ")])])])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"post-content-container mt-3"},[_c('div',{staticClass:"post-content"},[_c('p',[_vm._v(" "+_vm._s(br.shortDescription)+" ")])]),_c('div',{staticClass:"row no-gutters mb-2"},[_c('div',{staticClass:"col-md-8"}),_c('div',{staticClass:"col-md-4"},[_c('span',[_c('router-link',{staticClass:"text-decoration-none post-categories-link",attrs:{"to":{ name: 'Detail',
                                  params: { id: br.id }
                                },"title":br.title}},[_c('span',[_vm._v(" Read More ")])])],1)])])])])])])])}),0):_vm._e()],1)])])]),_c('div',{staticClass:"pt-5"}),(_vm.beAMentor.length != 0)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-2"}),_c('div',{staticClass:"col-lg-4"},[_c('article',{staticClass:"mb-5"},[_c('div',{staticClass:"post-container"},[_c('div',{staticClass:"post-thumbnail mt-4"},[_c('div',{staticClass:"sh-ratio"},[_c('div',{},[_c('div',{staticClass:"custom-post-large-image-course"},[(_vm.beAMentor[0].image != null)?_c('img',{attrs:{"src":_vm.url+_vm.beAMentor[0].image}}):_c('img',{attrs:{"src":require("../assets/images/no-image.png")}})])])]),_c('router-link',{staticClass:"post-overlay",attrs:{"to":{ name: 'Detail',
                                  params: { id: _vm.beAMentor[0].id }
                                },"title":_vm.beAMentor[0].title}},[_c('div',{},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')])])],1),_c('div',{staticClass:"container"},[_c('div',{staticClass:"post-content-container mt-3"},[_c('router-link',{staticClass:"post-title",attrs:{"to":{ name: 'Detail',
                                  params: { id: _vm.beAMentor[0].id }
                                },"title":_vm.beAMentor[0].title}},[_c('h2',[_vm._v(" "+_vm._s(_vm.beAMentor[0].title)+" ")])]),_c('div',{staticClass:"post-content"},[_c('p',[_vm._v(" "+_vm._s(_vm.beAMentor[0].shortDescription)+" ")])]),_c('div',[_c('div',{staticClass:"clear-bottom"},[_c('router-link',{staticClass:"btn link-theme text-decoration-none",attrs:{"to":{ name: 'BeAMenteeForm'
                                }}},[_vm._v(" Register "),_c('i',{staticClass:"fa fa-arrow-alt-circle-right",attrs:{"aria-hidden":"true"}})])],1)])],1)])])])]),_c('div',{staticClass:"col-lg-4"},[_c('article',{staticClass:"mb-5"},[_c('div',{staticClass:"post-container"},[_c('div',{staticClass:"post-thumbnail mt-4"},[_c('div',{staticClass:"sh-ratio"},[_c('div',{},[_c('div',{staticClass:"custom-post-large-image-course"},[(_vm.beAMentor[1].image != null)?_c('img',{attrs:{"src":_vm.url+_vm.beAMentor[1].image}}):_c('img',{attrs:{"src":require("../assets/images/no-image.png")}})])])]),_c('router-link',{staticClass:"post-overlay",attrs:{"to":{ name: 'Detail',
                                  params: { id: _vm.beAMentor[1].id }
                                },"title":_vm.beAMentor[1].title}},[_c('div',{},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')])])],1),_c('div',{staticClass:"container"},[_c('div',{staticClass:"post-content-container mt-3"},[_c('router-link',{staticClass:"post-title",attrs:{"to":{ name: 'Detail',
                                  params: { id: _vm.beAMentor[1].id }
                                },"title":_vm.beAMentor[1].title}},[_c('h2',[_vm._v(" "+_vm._s(_vm.beAMentor[1].title)+" ")])]),_c('div',{staticClass:"post-content"},[_c('p',[_vm._v(" "+_vm._s(_vm.beAMentor[1].shortDescription)+" ")])]),_c('div',[_c('div',{staticClass:"clear-bottom"},[_c('router-link',{staticClass:"btn link-theme text-decoration-none",attrs:{"to":{ name: 'BeAMentorForm'
                                }}},[_vm._v(" Register "),_c('i',{staticClass:"fa fa-arrow-alt-circle-right",attrs:{"aria-hidden":"true"}})])],1)])],1)])])])]),_c('div',{staticClass:"col-lg-2"})]):_vm._e(),_c('div',{staticClass:"style-spacer"}),_c('div',{staticClass:"style-advertisement-portion"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"style-category-wrapper-item"},[(_vm.vlearnSkillHerPageBottomAd.length != 0)?_c('div',{staticClass:"w-100 text-center"},[_c('a',{staticClass:"navbar-brand mr-0",attrs:{"href":_vm.vlearnSkillHerPageBottomAd[0].url,"title":_vm.vlearnSkillHerPageBottomAd[0].url,"target":"_blank"}},[_c('img',{staticClass:"w-100",attrs:{"src":_vm.url+_vm.vlearnSkillHerPageBottomAd[0].image}})])]):_c('a',{attrs:{"href":"#"}},[_c('div',{staticClass:"text-center"},[_vm._v("Advertisement")])])])])])])]),_c('div',{staticClass:"style-spacer"})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-widget mb-2"},[_c('h2',{staticClass:"custom-text-color"},[_vm._v("Our Programs")])])
}]

export { render, staticRenderFns }