<template>
  <div  :key="componentKey">





    <!--  Content Start  -->
    <div class="custom-row-element-wrap mt-5 mb-5">
      <div class="post-container">
        <div class="custom-widget mt-2 mb-4 border-bottom ">
          <h2 class="widget-title text-uppercase">Contact Us</h2>
        </div>

        <div>
          <form v-if="show"
                class="needs-validation" :class="[!isValidated  ? '':'', isValidated ? 'was-validated':'']" autocomplete="off" novalidate>
            <div class="form-group row">
              <label for="inputName" class="col-sm-2 col-form-label">
                Name
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-10">
                <input type="text" class="form-control form-control-sm custom-input-gotopage" id="inputName" placeholder="Name" required>
                <div class="invalid-feedback">
                  <span  class="w3-animate-opacity">Please Enter Your Name</span>

                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputAddress" class="col-sm-2 col-form-label">
                Address
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-10">
                <input type="text" class="form-control form-control-sm custom-input-gotopage" id="inputAddress" placeholder="Address" required>
                <div class="invalid-feedback">
                  <span  class="w3-animate-opacity">Please Enter Your Address</span>

                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPhoneNo" class="col-sm-2 col-form-label">
                Phone #
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-10">
                <input type="text" class="form-control form-control-sm custom-input-gotopage" id="inputPhoneNo" placeholder="Phone #" required>
                <div class="invalid-feedback">
                  <span  class="w3-animate-opacity">Please Enter Your Phone Number</span>

                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputEmail" class="col-sm-2 col-form-label">
                Email
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-10">
                <input type="email" class="form-control form-control-sm custom-input-gotopage" id="inputEmail" placeholder="Email" required>
                <div class="invalid-feedback">
                  <span  class="w3-animate-opacity">Please Enter Your Email Address</span>

                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <legend class="col-form-label col-sm-2 pt-0">NTN Obtained</legend>
                <div class="col-sm-10">
                  <b-form-group class="border-0">
                    <b-form-radio-group

                      :options="ntnObtainedOptions"

                      name="radio-inline1"
                      required
                    ></b-form-radio-group>
                  </b-form-group>

                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputCompanyName" class="col-sm-2 col-form-label">
                Company Name
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-10">
                <input type="text" class="form-control form-control-sm custom-input-gotopage" id="inputCompanyName" placeholder="Company Name" required>
                <div class="invalid-feedback">
                  <span  class="w3-animate-opacity">Please Enter your Company Name</span>

                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputWebsite" class="col-sm-2 col-form-label">Website</label>
              <div class="col-sm-10">
                <input type="text" class="form-control form-control-sm custom-input-gotopage" id="inputWebsite" placeholder="Website" >
              </div>
            </div>
            <div class="form-group row">
              <label for="inputMonthlyTurnover" class="col-sm-2 col-form-label">Monthly Turnover</label>
              <div class="col-sm-10">
                <input type="number" class="form-control form-control-sm custom-input-gotopage" id="inputMonthlyTurnover" placeholder="Monthly Turnover">
              </div>
            </div>
            <div class="form-group row">
              <label for="inputServicesRequired" class="col-sm-2 col-form-label">
                Services Required
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-10">
                <select id="inputServicesRequired"
                        class="custom-select-option form-control form-control-sm">
                  <option value="">Choose ...</option>
<!--                  <option
                    v-for="option in degreeLevels"
                    :key="option.key"
                    :value="option.key"
                  >
                    {{ option.value }}
                  </option>-->
                </select>
                <div class="invalid-feedback">
                  <span  class="w3-animate-opacity">Please select the Services Required</span>

                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputDetailedDescription" class="col-sm-2 col-form-label">Detailed Description</label>
              <div class="col-sm-10">
                <textarea type="text" class="form-control form-control-sm custom-input-gotopage" rows="3" id="inputDetailedDescription" placeholder="Detailed Description" maxlength="2000"></textarea>
              </div>
            </div>



            <div class="modal-footer">


              <div v-if="showCloseButton">
                <b-button
                  id="showCloseButton"
                  class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
                  type="button"
                  size="sm"
                  @click="close"
                  variant="outline-dark"
                  data-dismiss="modal"
                >Close
                </b-button>
              </div>
              <div v-if="showResetButton">
                <b-button
                  id="showResetButton"
                  class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
                  type="reset"
                  size="sm"
                  variant="outline-dark"
                >Reset
                </b-button>
              </div>
              <div>
                <b-button

                  v-if="showSaveButton"
                  class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
                  @click="AddOrUpdate()"
                  size="sm"
                  variant="outline-dark"
                >Submit
                </b-button>
              </div>
              <!-- <div>
                <b-button
                  v-if="showReassignButton"
                  class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
                  @click="AddOrUpdate()"
                  size="sm"
                  variant="outline-dark"
                  >Reassign
                </b-button>
              </div> -->
            </div>
          </form>
        </div>

      </div>


    </div>

  </div>
</template>

<script>

// optional style for arrows & dots

import axios from 'axios'

// import { RlCarouselSlide } from 'vue-renderless-carousel'
export default {
  name: 'ContactUs',
  components: {
    // SlidesComponent,
    // RlCarouselSlide

  },
  data () {
    return {

      ntnObtainedOptions:[
        { text: 'Yes', value: 1},
        { text: 'No', value: 2},
      ],

      url: axios.defaults.baseURL,
      currentPage: 1,
      total: 1,

      list: null,
      PageOfItems: [],
      info: [],
      option: 0,





      loading: true,
      errored: false,

      //form
      show: true,
      showCloseButton: true,
      showResetButton: true,

      showSaveButton: true,
      //Form Validation Bool
      isValidated:false,


      componentKey: 0,
    }
  },
  //carousel_latest_jobs
  methods: {


    AddOrUpdate(){
      this.isValidated = true;
    },



    //Rerender Component
    rerender () {
      this.componentKey += 1
    },
  },

  mounted () {

  }
}
</script>
