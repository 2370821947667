import Vue from 'vue'
import Vuex from 'vuex'
import signin from '@/pages/SignIn'
import courses from '@/pages/courses/Courses'
import axios from 'axios'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // products:[],
    courses:[],
    cats:[],
    cart:[],
    adds:[],
    educationForEveryOne:[],
    ourViiLearnStory:[],
    buildYouOwnCourses:[],
    skillHer:[],
    ourMission:[],
    spareASmile:[],
    ourPrograms:[],
    beAMentor:[],
    listcources:[],
    ourPartner:[],
    categorylist:[],
    videosAndWebinars:[],
  },
  mutations: {


    //Set Content
    setEducationForEveryOne(state, content){
      state.educationForEveryOne = content;
    },
    setOurViiLearnStory(state, content){
      state.ourViiLearnStory = content;
    },
    setBuildYouOwnCourses(state, content){
      state.buildYouOwnCourses = content;
    },
    setSkillHer(state, content){
      state.skillHer = content;
    },
    setOurMission(state, content){
      state.ourMission = content;
    },
    setSpareASmile(state, content){
      state.spareASmile = content;
    },
    setOurPrograms(state, content){
      state.ourPrograms = content;
    },
    setBeAMentor(state, content){
      state.beAMentor = content;
    },
    setOurPartner(state, content){
      state.ourPartner = content;
    },
    setVideosAndWebinars(state, content){
      state.videosAndWebinars = content;
    },

    setCourses(state, courses){
      state.courses = courses
    },
    /*setProducts(state, products){
      state.products = products
    },
    pushProductToCart(state, productId){
      state.cart.push({
        id: productId,
        quantity: 1
      })
    },
    incrementItemQuantity(state, cartItem){
      cartItem.quantity++
    },
    decrementProductInventory(state, product){
      product.inventory--
    },
    removeProductFromCart(state, cartItem){
      console.log("cart Item : ", cartItem)
      state.cart.splice(cartItem, 1)
    },
    decrementItemQuantity(state, cartItem){
      cartItem.quantity--
    },
    IncrementProductInventory(state, product){

      product.inventory++
    },*/


    pushCourseToCart(state, course){
      state.cart.push({
        id: course.courseId,
        title: course.courseTitle,
        quantity: 1
      })
    },
    incrementItemQuantity(state, cartItem){
      cartItem.quantity++
    },
    hideCourse(state, cartItem){
      console.log("cart Item : ", cartItem)
      alert(" ' "+ cartItem.title +" ' "+ "This Course is already added")
    },
    removeCourseFromCart(state, cartItem){
      console.log("cart Item : ", cartItem)
      state.cart.splice(cartItem, 1)
    },
    decrementCartCourseItemQuantity(state, cartItem){
      cartItem.quantity--
    },
    // showCourse(state, courseItem){},
    setAdds(state, adds){
      state.adds = adds;
    },



  },
  actions: {


    fetchEducationForEveryOne(context){
      let content = [];

      let contentModel = {
        pageSize: 0,
        pageNumber: 1,
        totalRecord: 0,
        text: "",
        viiLearnType:"EducationForEveryOne"
      }

      axios
        .post("/api/V_Learn/GetAll", contentModel)
        .then(res => {
          // code that we will 'try' to run

          let contentList = res.data.data;
          content = contentList
          /* let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
           for(let job in jobList){
               if(jobList[job].status == "Approved" && moment(String(jobList[job].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                   adds.push(jobList[job]);
               }
           }*/


          console.log("Add Response portion");

          console.log("response : ", content);
          context.commit("setEducationForEveryOne", content)
          /* this.getCategories(this.list);
           this.getJobTypes(this.list);
           this.getExperiences(this.list);
           this.getFilteredCities(this.list);*/
          //this.getDegreeLevel(this.list);

          /*if(adds.length == 0){
              this.$bvToast.toast("No Record Found ", {
                  toaster: "b-toaster-top-center",
                  variant: "warning",

                  solid: true
              });
          }*/

        })
        .catch(error => {
          console.log("Errorrrrrr : ",error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {

            /*this.$bvToast.toast("Updated Successfully ", {
              toaster: "b-toaster-top-center",
              variant: "success",

              solid: true
            });*/


          }
        });


    },
    fetchOurViiLearnStory(context){
      let content = [];

      let contentModel = {
        pageSize: 0,
        pageNumber: 1,
        totalRecord: 0,
        text: "",
        viiLearnType:"OurViiLearnStory"
      }

      axios
        .post("/api/V_Learn/GetAll", contentModel)
        .then(res => {
          // code that we will 'try' to run

          let contentList = res.data.data;
          content = contentList
          /* let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
           for(let job in jobList){
               if(jobList[job].status == "Approved" && moment(String(jobList[job].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                   adds.push(jobList[job]);
               }
           }*/


          console.log("Add Response portion");

          console.log("response : ", content);
          context.commit("setOurViiLearnStory", content)
          /* this.getCategories(this.list);
           this.getJobTypes(this.list);
           this.getExperiences(this.list);
           this.getFilteredCities(this.list);*/
          //this.getDegreeLevel(this.list);

          /*if(adds.length == 0){
              this.$bvToast.toast("No Record Found ", {
                  toaster: "b-toaster-top-center",
                  variant: "warning",

                  solid: true
              });
          }*/

        })
        .catch(error => {
          console.log("Errorrrrrr : ",error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {

            /*this.$bvToast.toast("Updated Successfully ", {
              toaster: "b-toaster-top-center",
              variant: "success",

              solid: true
            });*/


          }
        });


    },
    fetchBuildYouOwnCourses(context){
      let content = [];

      let contentModel = {
        pageSize: 0,
        pageNumber: 1,
        totalRecord: 0,
        text: "",
        viiLearnType:"BuildYouOwnCourses"
      }

      axios
        .post("/api/V_Learn/GetAll", contentModel)
        .then(res => {
          // code that we will 'try' to run

          let contentList = res.data.data;
          content = contentList
          /* let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
           for(let job in jobList){
               if(jobList[job].status == "Approved" && moment(String(jobList[job].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                   adds.push(jobList[job]);
               }
           }*/


          console.log("Add Response portion");

          console.log("response : ", content);
          context.commit("setBuildYouOwnCourses", content)
          /* this.getCategories(this.list);
           this.getJobTypes(this.list);
           this.getExperiences(this.list);
           this.getFilteredCities(this.list);*/
          //this.getDegreeLevel(this.list);

          /*if(adds.length == 0){
              this.$bvToast.toast("No Record Found ", {
                  toaster: "b-toaster-top-center",
                  variant: "warning",

                  solid: true
              });
          }*/

        })
        .catch(error => {
          console.log("Errorrrrrr : ",error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {

            /*this.$bvToast.toast("Updated Successfully ", {
              toaster: "b-toaster-top-center",
              variant: "success",

              solid: true
            });*/


          }
        });


    },
    fetchSkillHer(context){
      let content = [];

      let contentModel = {
        pageSize: 0,
        pageNumber: 1,
        totalRecord: 0,
        text: "",
        viiLearnType:"SkillHer"
      }

      axios
        .post("/api/V_Learn/GetAll", contentModel)
        .then(res => {
          // code that we will 'try' to run

          let contentList = res.data.data;
          content = contentList
          /* let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
           for(let job in jobList){
               if(jobList[job].status == "Approved" && moment(String(jobList[job].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                   adds.push(jobList[job]);
               }
           }*/


          console.log("Add Response portion");

          console.log("response : ", content);
          context.commit("setSkillHer", content)
          /* this.getCategories(this.list);
           this.getJobTypes(this.list);
           this.getExperiences(this.list);
           this.getFilteredCities(this.list);*/
          //this.getDegreeLevel(this.list);

          /*if(adds.length == 0){
              this.$bvToast.toast("No Record Found ", {
                  toaster: "b-toaster-top-center",
                  variant: "warning",

                  solid: true
              });
          }*/

        })
        .catch(error => {
          console.log("Errorrrrrr : ",error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {

            /*this.$bvToast.toast("Updated Successfully ", {
              toaster: "b-toaster-top-center",
              variant: "success",

              solid: true
            });*/


          }
        });


    },
    fetchOurMission(context){
      let content = [];

      let contentModel = {
        pageSize: 0,
        pageNumber: 1,
        totalRecord: 0,
        text: "",
        viiLearnType:"OurMission"
      }

      axios
        .post("/api/V_Learn/GetAll", contentModel)
        .then(res => {
          // code that we will 'try' to run

          let contentList = res.data.data;
          content = contentList
          /* let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
           for(let job in jobList){
               if(jobList[job].status == "Approved" && moment(String(jobList[job].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                   adds.push(jobList[job]);
               }
           }*/


          console.log("Add Response portion");

          console.log("response : ", content);
          context.commit("setOurMission", content)
          /* this.getCategories(this.list);
           this.getJobTypes(this.list);
           this.getExperiences(this.list);
           this.getFilteredCities(this.list);*/
          //this.getDegreeLevel(this.list);

          /*if(adds.length == 0){
              this.$bvToast.toast("No Record Found ", {
                  toaster: "b-toaster-top-center",
                  variant: "warning",

                  solid: true
              });
          }*/

        })
        .catch(error => {
          console.log("Errorrrrrr : ",error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {

            /*this.$bvToast.toast("Updated Successfully ", {
              toaster: "b-toaster-top-center",
              variant: "success",

              solid: true
            });*/


          }
        });


    },
    fetchSpareASmile(context){
      let content = [];

      let contentModel = {
        pageSize: 0,
        pageNumber: 1,
        totalRecord: 0,
        text: "",
        viiLearnType:"SpareASmile"
      }

      axios
        .post("/api/V_Learn/GetAll", contentModel)
        .then(res => {
          // code that we will 'try' to run

          let contentList = res.data.data;
          content = contentList
          /* let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
           for(let job in jobList){
               if(jobList[job].status == "Approved" && moment(String(jobList[job].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                   adds.push(jobList[job]);
               }
           }*/


          console.log("Add Response portion");

          console.log("response : ", content);
          context.commit("setSpareASmile", content)
          /* this.getCategories(this.list);
           this.getJobTypes(this.list);
           this.getExperiences(this.list);
           this.getFilteredCities(this.list);*/
          //this.getDegreeLevel(this.list);

          /*if(adds.length == 0){
              this.$bvToast.toast("No Record Found ", {
                  toaster: "b-toaster-top-center",
                  variant: "warning",

                  solid: true
              });
          }*/

        })
        .catch(error => {
          console.log("Errorrrrrr : ",error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {

            /*this.$bvToast.toast("Updated Successfully ", {
              toaster: "b-toaster-top-center",
              variant: "success",

              solid: true
            });*/


          }
        });


    },
    fetchOurPrograms(context){
      let content = [];

      let contentModel = {
        pageSize: 0,
        pageNumber: 1,
        totalRecord: 0,
        text: "",
        viiLearnType:"OurPrograms"
      }

      axios
        .post("/api/V_Learn/GetAll", contentModel)
        .then(res => {
          // code that we will 'try' to run

          let contentList = res.data.data;
          content = contentList
          /* let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
           for(let job in jobList){
               if(jobList[job].status == "Approved" && moment(String(jobList[job].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                   adds.push(jobList[job]);
               }
           }*/


          console.log("Add Response portion");

          console.log("response : ", content);
          context.commit("setOurPrograms", content)
          /* this.getCategories(this.list);
           this.getJobTypes(this.list);
           this.getExperiences(this.list);
           this.getFilteredCities(this.list);*/
          //this.getDegreeLevel(this.list);

          /*if(adds.length == 0){
              this.$bvToast.toast("No Record Found ", {
                  toaster: "b-toaster-top-center",
                  variant: "warning",

                  solid: true
              });
          }*/

        })
        .catch(error => {
          console.log("Errorrrrrr : ",error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {

            /*this.$bvToast.toast("Updated Successfully ", {
              toaster: "b-toaster-top-center",
              variant: "success",

              solid: true
            });*/


          }
        });


    },
    fetchBeAMentor(context){
      let content = [];

      let contentModel = {
        pageSize: 0,
        pageNumber: 1,
        totalRecord: 0,
        text: "",
        viiLearnType:"BeAMentor"
      }

      axios
        .post("/api/V_Learn/GetAll", contentModel)
        .then(res => {
          // code that we will 'try' to run

          let contentList = res.data.data;
          content = contentList
          /* let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
           for(let job in jobList){
               if(jobList[job].status == "Approved" && moment(String(jobList[job].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                   adds.push(jobList[job]);
               }
           }*/


          console.log("Add Response portion");

          console.log("response : ", content);
          context.commit("setBeAMentor", content)
          /* this.getCategories(this.list);
           this.getJobTypes(this.list);
           this.getExperiences(this.list);
           this.getFilteredCities(this.list);*/
          //this.getDegreeLevel(this.list);

          /*if(adds.length == 0){
              this.$bvToast.toast("No Record Found ", {
                  toaster: "b-toaster-top-center",
                  variant: "warning",

                  solid: true
              });
          }*/

        })
        .catch(error => {
          console.log("Errorrrrrr : ",error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {

            /*this.$bvToast.toast("Updated Successfully ", {
              toaster: "b-toaster-top-center",
              variant: "success",

              solid: true
            });*/


          }
        });


    },
    fetchOurPartner(context){
      let content = [];

      let contentModel = {
        pageSize: 0,
        pageNumber: 1,
        totalRecord: 0,
        text: "",
        viiLearnType:"OurPartner"
      }

      axios
        .post("/api/V_Learn/GetAll", contentModel)
        .then(res => {
          // code that we will 'try' to run

          let contentList = res.data.data;
          content = contentList
          /* let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
           for(let job in jobList){
               if(jobList[job].status == "Approved" && moment(String(jobList[job].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                   adds.push(jobList[job]);
               }
           }*/


          console.log("Add Response portion");

          console.log("response : ", content);
          context.commit("setOurPartner", content)
          /* this.getCategories(this.list);
           this.getJobTypes(this.list);
           this.getExperiences(this.list);
           this.getFilteredCities(this.list);*/
          //this.getDegreeLevel(this.list);

          /*if(adds.length == 0){
              this.$bvToast.toast("No Record Found ", {
                  toaster: "b-toaster-top-center",
                  variant: "warning",

                  solid: true
              });
          }*/

        })
        .catch(error => {
          console.log("Errorrrrrr : ",error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {

            /*this.$bvToast.toast("Updated Successfully ", {
              toaster: "b-toaster-top-center",
              variant: "success",

              solid: true
            });*/


          }
        });


    },

    fetchVideosAndWebinars(context){
      let content = [];

      let contentModel = {
        pageSize: 0,
        pageNumber: 1,
        totalRecord: 0,
        text: ""
      }

      axios
        .post("/api/V_Learn/GetAllVLearn", contentModel)
        .then(res => {
          // code that we will 'try' to run

          let contentList = res.data.data;
          content = contentList
          /* let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
           for(let job in jobList){
               if(jobList[job].status == "Approved" && moment(String(jobList[job].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                   adds.push(jobList[job]);
               }
           }*/


          console.log("Add Response portion");

          console.log("response : ", content);
          context.commit("setVideosAndWebinars", content)
          /* this.getCategories(this.list);
           this.getJobTypes(this.list);
           this.getExperiences(this.list);
           this.getFilteredCities(this.list);*/
          //this.getDegreeLevel(this.list);

          /*if(adds.length == 0){
              this.$bvToast.toast("No Record Found ", {
                  toaster: "b-toaster-top-center",
                  variant: "warning",

                  solid: true
              });
          }*/

        })
        .catch(error => {
          console.log("Errorrrrrr : ",error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {

            /*this.$bvToast.toast("Updated Successfully ", {
              toaster: "b-toaster-top-center",
              variant: "success",

              solid: true
            });*/


          }
        });


    },
// fetchcats(){
//   let cats = [];

//         return cats;
//         })
// },

    fetchCourses(context, course){


      let courses = [];
      



      axios
        .post('/api/V_Learn/GetAllCourse', course.courseFilter)
        .then(res => {
          // code that we will 'try' to run

          let list = [];
          list = res.data.data

          console.log("tid : ", course.typeId)

          if(course.typeId != undefined && course.typeId != 0){
            for(let i in list){
              if(course.typeId == list[i].courseType){
                courses.push(list[i]);
              }
            }
            console.log('in if list : ', courses)


          }
          else {
            courses = list.slice().reverse();
            console.log('list : ', courses)

          }


          console.log('Add Response portion')



          console.log("store : ",course)

          if(courses != null || courses != []){
            console.log('courses list : ', courses)
            context.commit("setCourses",  courses)
          }




        })
        .catch(error => {
          console.log('Errorrrrrr : ', error)
          this.errored = true
        })
        .finally(() => {
          if (this.errored) {

            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {

          }
        })
      
        // axios
        // .post('/api/V_Learn/GetAllCourse1', course.courseFilter)
        // .then(res => {
        //   // code that we will 'try' to run

          
        //   this.listcources= res.data.data

        //   if(course.typeId != undefined && course.typeId != 0){
        //     for(let i in this.listcources){
        //       if(course.typeId == this.listcources[i].courseType){
        //         courses1.push(this.listcources[i]);
        //       }
        //     }


        //   }
        //   else {
        //     courses1 = this.listcources.slice().reverse();
        //   }


        //   if(courses1 != null || courses1 != []){
        //     console.log('courses1 list : ', courses1)
        //     context.commit("setCourses",  courses1)
        //   }




        // })
      

    },
    addCourseToCart(context, course){
      if(course != {}){
        const cartItem = context.state.cart.find(item => item.id === course.courseId)
        if(!cartItem){
          context.commit("pushCourseToCart", course)
        }
        else{
          //context.commit("incrementItemQuantity", cartItem)
          context.commit("hideCourse", cartItem)
        }
        // context.commit("hideCourse", course)
      }
    },
    removeFromCart(context, cartcourse){
      console.log(cartcourse)
      if(cartcourse.quantity > 0){
        const cartItem = context.state.cart.find(item => item.id === cartcourse.id)

        if(cartItem){
          console.log(cartItem);
          context.commit("removeCourseFromCart", cartItem)
          context.commit("decrementCartCourseItemQuantity", cartItem)
        }
        /*else{
          context.commit("decrementCartCourseItemQuantity", cartItem)
        }*/
        /*const courseItem = context.state.courses.find(item => item.id === cartProduct.id)
        if(courseItem != {}){
          context.commit("showCourse", courseItem)
        }*/

      }
    },

    fetchAdds(context){
      let adds = [];

      let addsModel = {
        "pageSize": 0,
        "pageNumber": 1,
        "totalRecord": 0,
        "text": ""
      }

      axios
        .post("/api/Adds/GetAdd", addsModel)
        .then(res => {
          // code that we will 'try' to run

          let jobList = res.data.data;
          adds = jobList
          /* let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
           for(let job in jobList){
               if(jobList[job].status == "Approved" && moment(String(jobList[job].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate){
                   adds.push(jobList[job]);
               }
           }*/


          console.log("Add Response portion");

          console.log("response : ", adds);
          context.commit("setAdds", adds)
          /* this.getCategories(this.list);
           this.getJobTypes(this.list);
           this.getExperiences(this.list);
           this.getFilteredCities(this.list);*/
          //this.getDegreeLevel(this.list);

          /*if(adds.length == 0){
              this.$bvToast.toast("No Record Found ", {
                  toaster: "b-toaster-top-center",
                  variant: "warning",

                  solid: true
              });
          }*/

        })
        .catch(error => {
          console.log("Errorrrrrr : ",error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {

            /*this.$bvToast.toast("Updated Successfully ", {
              toaster: "b-toaster-top-center",
              variant: "success",

              solid: true
            });*/


          }
        });


    },



   /* fetchProducts(context){

      context.commit("setProducts",  signin.data().myProducts)
    },
    addProductToCart(context, product){
      if(product.inventory > 0){
        const cartItem = context.state.cart.find(item => item.id === product.id)
        if(!cartItem){
          context.commit("pushProductToCart", product.id)
        }
        else{
          context.commit("incrementItemQuantity", cartItem)
        }
        context.commit("decrementProductInventory", product)
      }
    },
    removeProductFromCart(context, cartProduct){
      if(cartProduct.quantity > 0){
        const cartItem = context.state.cart.find(item => item.id === cartProduct.id)
        if(!cartItem){
          context.commit("removeProductFromCart", cartItem.id)

        }
        else{
           context.commit("decrementItemQuantity", cartItem)
        }
        const productItem = context.state.products.find(item => item.id === cartProduct.id)
        if(productItem){
          context.commit("IncrementProductInventory", productItem)
        }

      }
    }*/
  },
  getters:{

    availableCourses(state, getters){

        return state.courses;


    },
    availableCats(state, getters){
debugger
      return state.cats;


  },
    // getallcategories(){
    //   axios
    //   .post("/api/Job/getCategories", addsModel)
    //   .then(res => {
    //     let categories = [];
    //     console.log(res.data);
    //     categories = res.data;
    //     return categories;
    //   });
    // },
    availableCategories(state, getters){
      let categories = []
      debugger
      console.log("getters.availableCourses : ",getters.availableCats)
      const map = new Map();
      //  New
      // for (const item of getters.availableCourses)

      getters.availableCats.forEach(item => {
          categories.push({
            id: item.id,
            name: item.name
        })
      });
      console.log("available categories:", categories)
      return categories

    },
    /*availableProducts(state, getters){
      return state.products.filter(product => product.inventory > 0)
    },
    cartProducts(state){

      let cartProducts = state.cart.map(cartItem => {

        const product = state.products.find(product => product.id === cartItem.id)
        return {
          id : cartItem.id,
          title : product.name,
          price : product.price,
          quantity: cartItem.quantity
        }
      })

      return cartProducts.filter(item => item.quantity > 0)
    },

    cartTotalItems(state, getters){
      let totalItems = 0;
      getters.cartProducts.forEach(product => {
        totalItems += product.quantity
      })
      return totalItems
    },
    cartTotal(state, getters){
      let total = 0;
      getters.cartProducts.forEach(product => {
        total += product.price*product.quantity
      })
      return total
    },*/


    cartCourses(state){

      let cartProducts = state.cart.map(cartItem => {

        const course = state.courses.find(course => course.courseId === cartItem.id)
        return {
          id : cartItem.id,
          title : course.courseTitle,
          price : 100,
          image : course.courseImage,
          quantity: cartItem.quantity
        }
      })

      return cartProducts.filter(item => item.quantity > 0)
    },

    cartTotalCourses(state, getters){
      let totalItems = 0;
      getters.cartCourses.forEach(cartCourse => {
        totalItems += cartCourse.quantity
      })
      return totalItems
    },
    cartTotal(state, getters){
      let total = 0;
      getters.cartCourses.forEach(course => {
        total += course.price*course.quantity
      })
      return total
    },

    //    available adds
    availableTopBarAdds(state, getters){
      let topbarAdd = []


      for (const item of state.adds) {
        if(item.title == "Vlearn/Topbar"){
          topbarAdd.push({
            id : item.addsId,
            title : item.title,
            image : item.addImage,
            url: item.bannerUrl
          });
        }
      }



      return topbarAdd.filter(item => item.id > 0)
    },
    availableVlearnSkillHerPageBottomAd(state, getters){
      let vlearnSkillHerPageBottomAd = []


      for (const item of state.adds) {
        if(item.title == "Vlearn/SkillHerPage/BottomAd"){
          vlearnSkillHerPageBottomAd.push({
            id : item.addsId,
            title : item.title,
            image : item.addImage,
            url: item.bannerUrl
          });
        }
      }



      return vlearnSkillHerPageBottomAd.filter(item => item.id > 0)
    },
    availableCourseDetailBeforeFooterAdd(state, getters){
      let vlearnCourseDetailPageBottomAd = []


      for (const item of state.adds) {
        if(item.title == "Vlearn/CourseDetail/BottomAd"){
          vlearnCourseDetailPageBottomAd.push({
            id : item.addsId,
            title : item.title,
            image : item.addImage,
            url: item.bannerUrl
          });
        }
      }



      return vlearnCourseDetailPageBottomAd.filter(item => item.id > 0)
    },

  },
  modules: {
  }
})
