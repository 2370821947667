<template>
  <div
    class="custom-sidebar w3-sidebar w3-bar-block w3-animate-left"
    id="mySidebar"
  >
    <div
      id="sidebar-tabs"
      class="nav nav-tabs custom-sidebar-nav-tabs"
      role="tablist"
    >
      <router-link
        :to="{ name: 'Home'}"
        class="w3-bar-item w3-button custom-bar-item custom-active"
      >
        <div class="custom-sidebar-heading " @click="w3_close">
          <div class="custom-row">
            <div class="custom-sidebar-icons">
              <b-icon icon="house-door"></b-icon>
            </div>
            <div class="custom-sidebar-text">
              <span>Home</span>
            </div>
          </div>
        </div>
      </router-link>
      <a
        href="#"
        class="w3-bar-item w3-button custom-bar-item custom-active"
        data-toggle="collapse"
        data-target="#id-aboutUs"
        aria-expanded="false"
        ariacontrols="id-aboutUs"
      >
        <div class="custom-sidebar-heading">
          <div class="custom-row">
            <div class="custom-sidebar-icons">
              <b-icon icon="shop"></b-icon>
            </div>
            <div class="custom-sidebar-text">
              <span>About Us</span>
            </div>
          </div>
          <div class="custom-sidebar-icons custom-add-btn">
            <a href="#" class="custom-sidebar-link">
              <b-icon class="custom-arrow-icon" icon="chevron-down"></b-icon>
            </a>
          </div>
        </div>
      </a>
      <div
        id="id-aboutUs"
        class="collapse custom-collapse"
        aria-labelledby="sidebar-tabs"
        data-parent="#sidebar-tabs"
      >
        <router-link
          v-if="ourViiLearnStory.length != 0"
          class="w3-bar-item w3-button custom-bar-item custom-active"
          :to="{ name: 'Detail',
                params: { id: ourViiLearnStory[0].id }
                }"
        >
          <div class="custom-sidebar-heading" @click="w3_close">
            <div class="custom-row">
              <div class="custom-sidebar-icons">
              </div>
              <div class="custom-sidebar-text">
                <span>Our Vii Learn Story</span>
              </div>
            </div>
          </div>
        </router-link>
        <router-link
          v-else
          class="w3-bar-item w3-button custom-bar-item custom-active"
          :to="{ name: 'Detail',
                params: { id: 0 }
                }"
        >
          <div class="custom-sidebar-heading" @click="w3_close">
            <div class="custom-row">
              <div class="custom-sidebar-icons">
              </div>
              <div class="custom-sidebar-text">
                <span>Our Vii Learn Story</span>
              </div>
            </div>
          </div>
        </router-link>
        <router-link
          v-if="buildYouOwnCourses.length != 0"
          :to="{ name: 'Detail',
                params: { id: buildYouOwnCourses[0].id }
                }"
          class="w3-bar-item w3-button custom-bar-item custom-active"
        >
          <div class="custom-sidebar-heading" @click="w3_close">
            <div class="custom-row">
              <div class="custom-sidebar-icons">
              </div>
              <div class="custom-sidebar-text">
                <span>Build Your Own Course</span>
              </div>
            </div>
          </div>
        </router-link>
        <router-link
          v-else
          :to="{ name: 'Detail',
                params: { id: 0 }
                }"
          class="w3-bar-item w3-button custom-bar-item custom-active"
        >
          <div class="custom-sidebar-heading" @click="w3_close">
            <div class="custom-row">
              <div class="custom-sidebar-icons">
              </div>
              <div class="custom-sidebar-text">
                <span>Build Your Own Course</span>
              </div>
            </div>
          </div>
        </router-link>
        <router-link
          to="/skill-her"
          class="w3-bar-item w3-button custom-bar-item custom-active"
        >
          <div class="custom-sidebar-heading" @click="w3_close">
            <div class="custom-row">
              <div class="custom-sidebar-icons">
              </div>
              <div class="custom-sidebar-text">
                <span>Skill Her</span>
              </div>
            </div>
          </div>
        </router-link>
        <router-link
          to="/upcoming-sessions"
          class="w3-bar-item w3-button custom-bar-item custom-active"
        >
          <div class="custom-sidebar-heading" @click="w3_close">
            <div class="custom-row">
              <div class="custom-sidebar-icons">
              </div>
              <div class="custom-sidebar-text">
                <span>Upcoming Sessions</span>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <a
        href="#"
        class="w3-bar-item w3-button custom-bar-item custom-active"
        data-toggle="collapse"
        data-target="#id-courses"
        aria-expanded="false"
        ariacontrols="id-courses"
      >
        <div class="custom-sidebar-heading">
          <div class="custom-row">
            <div class="custom-sidebar-icons">
              <b-icon icon="book"></b-icon>
            </div>
            <div class="custom-sidebar-text">
              <span>Courses</span>
            </div>
          </div>
          <div class="custom-sidebar-icons custom-add-btn">
            <a href="#" class="custom-sidebar-link">
              <b-icon class="custom-arrow-icon" icon="chevron-down"></b-icon>
            </a>
          </div>
        </div>
      </a>
      <div
        id="id-courses"
        class="collapse custom-collapse"
        aria-labelledby="sidebar-tabs"
        data-parent="#sidebar-tabs"
      >
        <router-link
          to="/courses/0,0"
          class="w3-bar-item w3-button custom-bar-item custom-active"
        >
          <div class="custom-sidebar-heading" @click="w3_close">
            <div class="custom-row">
              <div class="custom-sidebar-icons">
              </div>
              <div class="custom-sidebar-text">
                <span>All Courses</span>
              </div>
            </div>
          </div>
        </router-link>
        <router-link
          to="/courses/0,2"
          class="w3-bar-item w3-button custom-bar-item custom-active"
        >
          <div class="custom-sidebar-heading" @click="w3_close">
            <div class="custom-row">
              <div class="custom-sidebar-icons">
              </div>
              <div class="custom-sidebar-text">
                <span>Courses With Partners</span>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <a
        href="#"
        class="w3-bar-item w3-button custom-bar-item custom-active"
        data-toggle="collapse"
        data-target="#id-resources"
        aria-expanded="false"
        ariacontrols="id-resources"
      >
        <div class="custom-sidebar-heading">
          <div class="custom-row">
            <div class="custom-sidebar-icons">
              <b-icon icon="shop"></b-icon>
            </div>
            <div class="custom-sidebar-text">
              <span>Resources</span>
            </div>
          </div>
          <div class="custom-sidebar-icons custom-add-btn">
            <a href="#" class="custom-sidebar-link">
              <b-icon class="custom-arrow-icon" icon="chevron-down"></b-icon>
            </a>
          </div>
        </div>
      </a>
      <div
        id="id-resources"
        class="collapse custom-collapse"
        aria-labelledby="sidebar-tabs"
        data-parent="#sidebar-tabs"
      >
        <router-link
          to="/videos"
          class="w3-bar-item w3-button custom-bar-item custom-active"
        >
          <div class="custom-sidebar-heading" @click="w3_close">
            <div class="custom-row">
              <div class="custom-sidebar-icons">
              </div>
              <div class="custom-sidebar-text">
                <span>Videos & Webinars</span>
              </div>
            </div>
          </div>
        </router-link>

      </div>
      <a
        href="#"
        class="w3-bar-item w3-button custom-bar-item custom-active"
        data-toggle="collapse"
        data-target="#id-smw"
        aria-expanded="false"
        ariacontrols="id-smw"
      >
        <div class="custom-sidebar-heading">
          <div class="custom-row">
            <div class="custom-sidebar-icons">
              <b-icon icon="shop"></b-icon>
            </div>
            <div class="custom-sidebar-text">
              <span>SMW</span>
            </div>
          </div>
          <div class="custom-sidebar-icons custom-add-btn">
            <a href="#" class="custom-sidebar-link">
              <b-icon class="custom-arrow-icon" icon="chevron-down"></b-icon>
            </a>
          </div>
        </div>
      </a>
      <div
        id="id-smw"
        class="collapse custom-collapse"
        aria-labelledby="sidebar-tabs"
        data-parent="#sidebar-tabs"
      >
        <a
          href="https://shemeanswork.com/" target="_blank"
          class="w3-bar-item w3-button custom-bar-item custom-active"
        >
          <div class="custom-sidebar-heading" @click="w3_close">
            <div class="custom-row">
              <div class="custom-sidebar-icons">
              </div>
              <div class="custom-sidebar-text">
                <span>She Means Work</span>
              </div>
            </div>
          </div>
        </a>
        <a
          href="https://harshehar.com/" target="_blank"
          class="w3-bar-item w3-button custom-bar-item custom-active"
        >
          <div class="custom-sidebar-heading" @click="w3_close">
            <div class="custom-row">
              <div class="custom-sidebar-icons">
              </div>
              <div class="custom-sidebar-text">
                <span>Harshehar</span>
              </div>
            </div>
          </div>
        </a>
        <a
          href="https://ventureher.com/" target="_blank"
          class="w3-bar-item w3-button custom-bar-item custom-active"
        >
          <div class="custom-sidebar-heading" @click="w3_close">
            <div class="custom-row">
              <div class="custom-sidebar-icons">
              </div>
              <div class="custom-sidebar-text">
                <span>Ventureher</span>
              </div>
            </div>
          </div>
        </a>
        <a
          href="javascript:void(0)"
          class="w3-bar-item w3-button custom-bar-item custom-active"
        >
          <div class="custom-sidebar-heading" @click="w3_close">
            <div class="custom-row">
              <div class="custom-sidebar-icons">
              </div>
              <div class="custom-sidebar-text">
                <span>Hecancy</span>
              </div>
            </div>
          </div>
        </a>
      </div>
      <a
        href="#"
        class="w3-bar-item w3-button custom-bar-item custom-active"
        data-toggle="collapse"
        data-target="#id-registerMe"
        aria-expanded="false"
        ariacontrols="id-registerMe"
      >
        <div class="custom-sidebar-heading">
          <div class="custom-row">
            <div class="custom-sidebar-icons">
              <b-icon icon="shop"></b-icon>
            </div>
            <div class="custom-sidebar-text">
              <span>Register Me</span>
            </div>
          </div>
          <div class="custom-sidebar-icons custom-add-btn">
            <a href="#" class="custom-sidebar-link">
              <b-icon class="custom-arrow-icon" icon="chevron-down"></b-icon>
            </a>
          </div>
        </div>
      </a>
      <div
        id="id-registerMe"
        class="collapse custom-collapse"
        aria-labelledby="sidebar-tabs"
        data-parent="#sidebar-tabs"
      >
        <router-link
          to="/mentee-form"
          class="w3-bar-item w3-button custom-bar-item custom-active"
        >
          <div class="custom-sidebar-heading" @click="w3_close">
            <div class="custom-row">
              <div class="custom-sidebar-icons">
              </div>
              <div class="custom-sidebar-text">
                <span>Be A Mentee</span>
              </div>
            </div>
          </div>
        </router-link>
        <router-link
          to="/mentor-form"
          class="w3-bar-item w3-button custom-bar-item custom-active"
        >
          <div class="custom-sidebar-heading" @click="w3_close">
            <div class="custom-row">
              <div class="custom-sidebar-icons">
              </div>
              <div class="custom-sidebar-text">
                <span>Be A Mentor</span>
              </div>
            </div>
          </div>
        </router-link>
        <router-link
          to="/partner-form"
          class="w3-bar-item w3-button custom-bar-item custom-active"
        >
          <div class="custom-sidebar-heading" @click="w3_close">
            <div class="custom-row">
              <div class="custom-sidebar-icons">
              </div>
              <div class="custom-sidebar-text">
                <span>Register As Partner</span>
              </div>
            </div>
          </div>
        </router-link>
        <router-link
          to="/alumni-form"
          class="w3-bar-item w3-button custom-bar-item custom-active"
        >
          <div class="custom-sidebar-heading" @click="w3_close">
            <div class="custom-row">
              <div class="custom-sidebar-icons">
              </div>
              <div class="custom-sidebar-text">
                <span>Register As Alumni</span>
              </div>
            </div>
          </div>
        </router-link>
      </div>




    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  mounted(){

    this.getAll();


    // this.$nextTick(() => {
    //   // Change top navbar on scroll
    //   $(window).on("scroll", function() {
    //     if($(window).scrollTop() > 100) {
    //       $("#ad-banner-id").addClass("active");
    //     } else {
    //       $("#ad-banner-id").removeClass("active");
    //     }
    //   });
    // });

  },
  beforeCreate () {

  },
  computed:{
    /*cartTotalItems(){
      return this.$store.getters.cartTotalCourses
    },*/
    ourViiLearnStory(){
      console.log("this.$store.state.ourViiLearnStory", this.$store.state.ourViiLearnStory)
      return this.$store.state.ourViiLearnStory
    },
    buildYouOwnCourses(){
      console.log("this.$store.state.buildYouOwnCourses", this.$store.state.buildYouOwnCourses)
      return this.$store.state.buildYouOwnCourses
    },
  },


  methods: {
    getAll(){


      this.$store.dispatch("fetchOurViiLearnStory");
      this.$store.dispatch("fetchBuildYouOwnCourses");



    },
    w3_close() {
      document.getElementById("mySidebar").style.display = "none";

      document.getElementById("btn-open-sidebar").style.display = "block";
      document.getElementById("btn-close-sidebar").style.display = "none";
      console.log("clicked")
    },


  },
};
</script>

<style></style>
