<template>
  <div id="main-container" :key="componentKey">


    <!-- Banner Section For Web Start -->
    <section class="webSection  custom-landing-banner-section1">
      <div class="custom-banner-background-overlay"></div>
      <div class="custom-section">
        <div class="custom-row-wrap">
          <div class="custom-row-element ">
            <div class="custom-row-element-wrap ">
              <div class="row custom-row-section ">
                <div class="col-lg-12 ">
                  <div class="text-center text-uppercase custom-banner-heading">
                    <!--                    <span>Creativity</span>-->
                    <span class="ml-4 mr-4" style="color: #ea216c">
                                      <b-icon width="1.5rem" icon="circle-fill"></b-icon>
                                    </span>
                    <span>Articles</span>
                    <span class="ml-4 mr-4" style="color: #ea216c">
                                      <b-icon width="1.5rem" icon="circle-fill"></b-icon>
                                    </span>
                    <!--                    <span>Desire</span>-->
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </section>





    <!--  Posts Start  -->
    <div class="custom-row-element-wrap mt-5 mb-5">
      <div class="custom-widget mb-4 border-bottom">
        <h2 class="text-center">Articles</h2>
      </div>
      <div class="row align-items-center">
        <div class="col-lg-4" v-for="post in list" :key="post.id">
          <article class="mb-5">
            <div class="post-container">

              <div class="post-thumbnail mt-4">
                <div class="sh-ratio">
                  <!--                    sh-ratio-container-->
                  <div class="">
                    <!--                      class="sh-ratio-content"
                                          style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"-->
                    <div >
                      <img :src="url+post.videoOrImage" />
                    </div>
                  </div>
                </div>
                <router-link
                  to="/"
                  class="post-overlay">
                  <div class=""><span></span> <span></span> <span></span></div>
                </router-link>
              </div>
              <div class="container">
                <div class="post-content-container mt-3">
<!--                  <div class="post-categories-container ">
                    <div class="post-categories">
                      <h5 v-if="post.category == 'HealthAndBeauty'" class="post-title font-italic">
                        Health & Beauty
                      </h5>
                      <h5 v-if="post.category == 'BeveragesAndDining'" class="post-title font-italic">
                        Beverages & Dining
                      </h5>
                      <h5 v-if="post.category == 'HotelsAndStaycations'" class="post-title font-italic">
                        Hotels & Staycations
                      </h5>
                      <h5 v-if="post.category == 'SportsAndLeisure'" class="post-title font-italic">
                        Sports & Leisure
                      </h5>
                      <h5 v-if="post.category == 'KidsAndFamily'" class="post-title font-italic">
                        Kids & Family
                      </h5>
                      <h5 v-if="post.category == 'FlowersAndGardening'" class="post-title font-italic">
                        Flowers & Gardening
                      </h5>
                      <span v-else class="post-title font-italic"></span>
                    </div>
                  </div>-->
                  <router-link
                    to="/"
                    class="post-title">
                    <h2> {{ post.categoryTitle }} </h2>
                  </router-link>
                  <div class="post-content">
                    <p>
                      {{ post.categoryDescription }}
                    </p>
                  </div>
                  <!--  Learn more    -->
                  <div>
                    <div class="text-center clear-bottom">
                      <router-link to="/" class="btn link-theme text-decoration-none">
                        Learn More
                        <i aria-hidden="true" class="fa fa-arrow-alt-circle-right"> </i>
                      </router-link>
                    </div>
                  </div>
<!--                  <div class="post-meta">
                    <div class="post-meta-content">
                    <span class="post-auhor-date row">
                      <div class="col-sm-4">
                        <b-avatar variant="info" src="https://placekitten.com/300/300" size="2rem" class="mr-3"></b-avatar>
                        <span class="mr-auto">J. Circlehead</span>
                      </div>
                      <div class="col-sm-4">
                        <span v-if="post.numberofDays > 1">
                        <i class="post-date">
                          {{ post.numberofDays }} days ago
                        </i>
                      </span>
                      <span v-else>
                        <i class="post-date">
                          {{ post.numberofDays }} day ago
                        </i>
                      </span>
                      </div>

                        <div class="col-sm-4" >
                          <b-icon class="icon" icon="calendar3"></b-icon>
                          <span class="post-author ml-2">2021-9-12</span>
                      </div>
                    </span>
                      &lt;!&ndash;                        <a
                                                href="https://gillion.shufflehound.com/home/2017/01/31/health-tips-and-benefits-of-healthy-lifestyle-you-should-consider/#comments"
                                                class="post-comments">
                                                <i class="icon icon-bubble"></i> 0
                                              </a>
                                              <span class="post-readtime">
                                                <i class="icon icon-clock"></i> 1 min <span>read</span>
                                              </span>&ndash;&gt;

                    </div>
                  </div>-->
                </div>
              </div>

            </div>
          </article>
        </div>
<!--        <div class="col-lg-4">
          <article class="mb-5">
            <div class="post-container">

              <div class="post-thumbnail mt-4">
                <div class="sh-ratio">
                  &lt;!&ndash;                    sh-ratio-container&ndash;&gt;
                  <div class="">
                    &lt;!&ndash;                      class="sh-ratio-content"
                                          style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"&ndash;&gt;
                    <div >
                      <img src="../assets/images/about-img5.jpg" />
                    </div>
                  </div>
                </div>
                <router-link
                  to="/"
                  class="post-overlay">
                  <div class=""><span></span> <span></span> <span></span></div>
                </router-link>
              </div>
              <div class="container">
                <div class="post-content-container mt-3">
                  <router-link
                    to="/"
                    class="post-title">
                    <h2> Title </h2>
                  </router-link>
                  <div class="post-content">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                  </div>
                  &lt;!&ndash;  Learn more    &ndash;&gt;
                  <div>
                    <div class="text-center clear-bottom">
                      <router-link to="/contact-us" class="btn link-theme text-decoration-none">
                        Learn More
                        <i aria-hidden="true" class="fa fa-arrow-alt-circle-right"> </i>
                      </router-link>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </article>
        </div>
        <div class="col-lg-4">
          <article class="mb-5">
            <div class="post-container">

              <div class="post-thumbnail mt-4">
                <div class="sh-ratio">
                  &lt;!&ndash;                    sh-ratio-container&ndash;&gt;
                  <div class="">
                    &lt;!&ndash;                      class="sh-ratio-content"
                                          style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"&ndash;&gt;
                    <div >
                      <img src="../assets/images/about-img5.jpg" />
                    </div>
                  </div>
                </div>
                <router-link
                  to="/"
                  class="post-overlay">
                  <div class=""><span></span> <span></span> <span></span></div>
                </router-link>
              </div>
              <div class="container">
                <div class="post-content-container mt-3">

                  <router-link
                    to="/"
                    class="post-title">
                    <h2> Title </h2>
                  </router-link>
                  <div class="post-content">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                  </div>
                  &lt;!&ndash;  Learn more    &ndash;&gt;
                  <div>
                    <div class="text-center clear-bottom">
                      <router-link to="/contact-us" class="btn link-theme text-decoration-none">
                        Learn More
                        <i aria-hidden="true" class="fa fa-arrow-alt-circle-right"> </i>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </article>
        </div>
        <div class="col-lg-4">
          <article class="mb-5">
            <div class="post-container">

              <div class="post-thumbnail mt-4">
                <div class="sh-ratio">
                  &lt;!&ndash;                    sh-ratio-container&ndash;&gt;
                  <div class="">
                    &lt;!&ndash;                      class="sh-ratio-content"
                                          style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"&ndash;&gt;
                    <div >
                      <img src="../assets/images/about-img5.jpg" />
                    </div>
                  </div>
                </div>
                <router-link
                  to="/"
                  class="post-overlay">
                  <div class=""><span></span> <span></span> <span></span></div>
                </router-link>
              </div>
              <div class="container">
                <div class="post-content-container mt-3">

                  <router-link
                    to="/"
                    class="post-title">
                    <h2> Title </h2>
                  </router-link>
                  <div class="post-content">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                  </div>
                  &lt;!&ndash;  Learn more    &ndash;&gt;
                  <div>
                    <div class="text-center clear-bottom">
                      <router-link to="/contact-us" class="btn link-theme text-decoration-none">
                        Learn More
                        <i aria-hidden="true" class="fa fa-arrow-alt-circle-right"> </i>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </article>
        </div>-->
      </div>


    </div>



  </div>
</template>

<script>

// optional style for arrows & dots

import axios from 'axios'

// import { RlCarouselSlide } from 'vue-renderless-carousel'
export default {
  name: 'Articles',
  components: {
    // SlidesComponent,
    // RlCarouselSlide

  },
  data () {
    return {




      slides: [
        {
          title: 'John Doe',
          /* content: 'Photo by Max Rive',*/
          // You can also provide a URL for the image.
          image: require('@/assets/images/avatar-2.jpg'),
        },
        {
          title: 'John Doe',
          /* content: 'Photo by Max Rive',*/
          // You can also provide a URL for the image.
          image: require('@/assets/images/avatar-2.jpg'),
        },
        {
          title: 'John Doe',
          /* content: 'Photo by Max Rive',*/
          // You can also provide a URL for the image.
          image: require('@/assets/images/avatar-2.jpg'),
        },
        {
          title: 'John Doe',
          /* content: 'Photo by Max Rive',*/
          // You can also provide a URL for the image.
          image: require('@/assets/images/avatar-2.jpg'),
        },
        {
          title: 'John Doe',
          /* content: 'Photo by Max Rive',*/
          // You can also provide a URL for the image.
          image: require('@/assets/images/avatar-2.jpg'),
        },
        {
          title: 'John Doe',
          /* content: 'Photo by Max Rive',*/
          // You can also provide a URL for the image.
          image: require('@/assets/images/avatar-2.jpg'),
        },
      ],
      settingsForLatestOrFeaturedJobs: {

        infinite: true,
        slidesToShow: 2,
        'autoplay': true,
        'autoplaySpeed': 2000,

        'pauseOnFocus': true,
        'pauseOnHover': true,
        rows: 2,
        slidesPerRow: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,

              infinite: true

            }
          }
        ]
      },
      settingsForTopEmployers: {
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        rows: 1,
        slidesPerRow: 1,
        arrows: true,
        'autoplay': true,
        'autoplaySpeed': 2000,

        'pauseOnFocus': true,
        'pauseOnHover': true,
        'responsive': [
          {
            'breakpoint': 768,
            'settings': {
              'slidesToShow': 2,

              'infinite': true,

            }
          },
          {
            'breakpoint': 500,
            'settings': {
              'slidesToShow': 1,

              'infinite': true,

            }
          }
        ]
      },

      settingsForThreeRows: {
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        rows: 1,
        slidesPerRow: 1,
        arrows: false,
        'autoplay': true,
        'autoplaySpeed': 2000,

        'pauseOnFocus': true,
        'pauseOnHover': true,
        'responsive': [
          {
            'breakpoint': 768,
            'settings': {
              'slidesToShow': 2,

              'infinite': true,

            }
          },
          {
            'breakpoint': 500,
            'settings': {
              'slidesToShow': 1,

              'infinite': true,

            }
          }
        ]
      },
      settingsForBrands: {
        infinite: true,
        slidesToShow: 4,
        speed: 500,
        rows: 1,
        slidesPerRow: 1,
        arrows: true,
        'autoplay': true,
        'autoplaySpeed': 2000,

        'pauseOnFocus': true,
        'pauseOnHover': true,
        'responsive': [
          {
            'breakpoint': 768,
            'settings': {
              'slidesToShow': 3,

              'infinite': true,

            }
          },
          {
            'breakpoint': 500,
            'settings': {
              'slidesToShow': 2,

              'infinite': true,

            }
          }
        ]
      },
      settingsForTwoSlides: {
        infinite: true,
        slidesToShow: 2,
        speed: 500,
        rows: 1,
        slidesPerRow: 1,
        'autoplay': true,
        'autoplaySpeed': 2000,

        'pauseOnFocus': true,
        'pauseOnHover': true,
        'responsive': [

          {
            'breakpoint': 500,
            'settings': {
              'slidesToShow': 1,

              'infinite': true,

            }
          }
        ]
        /*focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        touchThreshold: 5,*/
      },

      fullPageslides: [
        {
          title: 'El Teide Volcano, Spain',
          content: 'Photo by Max Rive',
          // You can also provide a URL for the image.
          image: require('@/assets/images/slider11.jpg'),
          link:
            'https://www.maxrivephotography.com/index/C0000rU1RKCHdqwI/G0000X57AtIzuRX0/I0000Gvr9HqdtyXk',
        },
        {
          title: 'El Teide Volcano, Spain',
          content: 'Photo by Max Rive',
          // You can also provide a URL for the image.
          image: require('@/assets/images/slider12.jpg'),
          link:
            'https://www.maxrivephotography.com/index/C0000rU1RKCHdqwI/G0000X57AtIzuRX0/I0000Gvr9HqdtyXk',
        },
        {
          title: 'El Teide Volcano, Spain',
          content: 'Photo by Max Rive',
          // You can also provide a URL for the image.
          image: require('@/assets/images/slider13.jpg'),
          link:
            'https://www.maxrivephotography.com/index/C0000rU1RKCHdqwI/G0000X57AtIzuRX0/I0000Gvr9HqdtyXk',
        },
      ],


      url: axios.defaults.baseURL,
      currentPage: 1,
      total: 1,

      list: null,
      PageOfItems: [],
      info: [],
      option: 0,

      //posts
      featuredPosts: [],
      latestPosts: [],
      postType: null,


      //Posts by categories
      postList:[],
      healthAndBeauty: {},
      beveragesAndDining: {},
      hotelsAndStaycations: {},
      sportsAndLeisure: {},
      kidsAndFamily: {},
      flowersAndGardening: {},



      loading: true,
      errored: false,


      componentKey: 0,
    }
  },
  //carousel_latest_jobs
  methods: {

    getAll() {
      this.rerender()

      this.list = null;
      // this.featuredPosts = [];
      // this.latestPosts = [];
      axios.get('/api/Shehri/GetAllPost')
        .then(res => {
          if (res.status != '200') {
            console.log('error.....')
            const error = new Error('Failed to fetch!')
            return error
          }

          this.list = res.data.data
          console.log('categorylist : ', this.list)
          //let currentDate = moment(String(new Date())).format('YYYY-MM-DD')

         /* for (let post in this.list){
            console.log("this.list[post]: ",this.list[post])
            if(this.list[post].postType === 1){
              this.featuredPosts.push(this.list[post]);
            }

          }

          console.log("Featured Jobs : ", this.featuredPosts);
          for (let lpost in this.list){
            if(this.list[lpost].postType === 2){
              this.latestPosts.push(this.list[lpost]);
            }
          }
          console.log("latestJobs Jobs : ", this.latestPosts);*/


        }).catch(error => {
        console.log(error)
        this.errored = true
      })
        .finally(() => {
          if (this.errored) {

            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        })
    },




    showPrevious () {
      this.$refs.carousel.prev()
    },
    showNext () {
      this.$refs.carousel.next()
    },
    showPreviousLatestJob () {
      this.$refs.carousel_latest_jobs.prev()
    },
    showNextLatestJob () {
      this.$refs.carousel_latest_jobs.next()
    },
    showPreviousExecutiveJobs () {
      this.$refs.carousel_Executive_Jobs.prev()
    },
    showNextExecutiveJobs () {
      this.$refs.carousel_Executive_Jobs.next()
    },
    showPreviousTopEmployee () {
      this.$refs.carousel_Top_Employee.prev()
    },
    showNextTopEmployee () {
      this.$refs.carousel_Top_Employee.next()
    },
    showPreviousBrandsCar () {
      this.$refs.brands_carousel.prev()
    },
    showNextBrandsCar () {
      this.$refs.brands_carousel.next()
    },
    showPreviousTopEmployers () {
      this.$refs.top_employers_carousel.prev()
    },
    showNextTopEmployers () {
      this.$refs.top_employers_carousel.next()
    },


    //Rerender Component
    rerender () {
      this.componentKey += 1
    },
  },

  mounted () {
    this.getAll()

  }
}
</script>
