<template>
  <div id="main-container" :key="componentKey">



    <!--  Posts Start  -->
    <div class="custom-row-element-wrap mt-5 mb-5">
      <div class="custom-widget mt-2 mb-2 border-bottom ">
        <h2 class="widget-title text-uppercase">Categories</h2>
      </div>
      <div class="row">

        <div class="col-lg-4" v-for="data in list" :key="data.courseCategoryId">
          <div class="post-thumbnail mt-4">
            <div class="sh-ratio">
              <!--                    sh-ratio-container-->
              <div class="">
                <!--                      class="sh-ratio-content"
                                      style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"-->
                <div >
                  <img src="../../assets/images/slider11.jpg" />
                </div>
              </div>
            </div>
            <router-link
              :to="{ name: 'Courses',
                              params: { id: data.courseCategoryId}
                            }"
              class="post-overlay">

              <div class="post-overlay-content"><span></span> <span></span> <span></span></div>
            </router-link>
            <div class="post-categories-container">
              <div class="post-categories">
                <a href="javascript:void(0)">{{ data.courseCategoryName }}</a>
              </div>
            </div>
          </div>
        </div>
<!--        <div class="col-lg-4">
          <div class="post-thumbnail mt-4">
            <div class="sh-ratio">
              &lt;!&ndash;                    sh-ratio-container&ndash;&gt;
              <div class="">
                &lt;!&ndash;                      class="sh-ratio-content"
                                      style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"&ndash;&gt;
                <div >
                  <img src="../assets/images/slider11.jpg" />
                </div>
              </div>
            </div>
            <router-link
              :to="{ name: 'FreeCourses',
                              params: {beveragesAndDining}
                            }"
              class="post-overlay">

              <div class="post-overlay-content"><span></span> <span></span> <span></span></div>
            </router-link>
            <div class="post-categories-container">
              <div class="post-categories">
                <a href="javascript:void(0)">Business</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="post-thumbnail mt-4">
            <div class="sh-ratio">
              &lt;!&ndash;                    sh-ratio-container&ndash;&gt;
              <div class="">
                &lt;!&ndash;                      class="sh-ratio-content"
                                      style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"&ndash;&gt;
                <div >
                  <img src="../assets/images/slider11.jpg" />
                </div>
              </div>
            </div>
            <router-link
              :to="{ name: 'FreeCourses',
                              params: {beveragesAndDining}
                            }"
              class="post-overlay">

              <div class="post-overlay-content"><span></span> <span></span> <span></span></div>
            </router-link>
            <div class="post-categories-container">
              <div class="post-categories">
                <a href="javascript:void(0)">Computer Science</a>
              </div>
            </div>
          </div>
        </div>-->


      </div>
<!--      <div class="row ">

        <div class="col-lg-4">
          <div class="post-thumbnail mt-4">
            <div class="sh-ratio">
              &lt;!&ndash;                    sh-ratio-container&ndash;&gt;
              <div class="">
                &lt;!&ndash;                      class="sh-ratio-content"
                                      style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"&ndash;&gt;
                <div >
                  <img src="../assets/images/slider11.jpg" />
                </div>
              </div>
            </div>
            <router-link
              :to="{ name: 'FreeCourses',
                              params: {beveragesAndDining}
                            }"
              class="post-overlay">

              <div class="post-overlay-content"><span></span> <span></span> <span></span></div>
            </router-link>
            <div class="post-categories-container">
              <div class="post-categories">
                <a href="javascript:void(0)">Information Technology</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="post-thumbnail mt-4">
            <div class="sh-ratio">
              &lt;!&ndash;                    sh-ratio-container&ndash;&gt;
              <div class="">
                &lt;!&ndash;                      class="sh-ratio-content"
                                      style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"&ndash;&gt;
                <div >
                  <img src="../assets/images/slider11.jpg" />
                </div>
              </div>
            </div>
            <router-link
              :to="{ name: 'FreeCourses',
                              params: {beveragesAndDining}
                            }"
              class="post-overlay">

              <div class="post-overlay-content"><span></span> <span></span> <span></span></div>
            </router-link>
            <div class="post-categories-container">
              <div class="post-categories">
                <a href="javascript:void(0)">Language Learning</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="post-thumbnail mt-4">
            <div class="sh-ratio">
              &lt;!&ndash;                    sh-ratio-container&ndash;&gt;
              <div class="">
                &lt;!&ndash;                      class="sh-ratio-content"
                                      style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"&ndash;&gt;
                <div >
                  <img src="../assets/images/slider11.jpg" />
                </div>
              </div>
            </div>
            <router-link
              :to="{ name: 'FreeCourses',
                              params: {beveragesAndDining}
                            }"
              class="post-overlay">

              <div class="post-overlay-content"><span></span> <span></span> <span></span></div>
            </router-link>
            <div class="post-categories-container">
              <div class="post-categories">
                <a href="javascript:void(0)">Arts & Humanities</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row ">

        <div class="col-lg-4">
          <div class="post-thumbnail mt-4">
            <div class="sh-ratio">
              &lt;!&ndash;                    sh-ratio-container&ndash;&gt;
              <div class="">
                &lt;!&ndash;                      class="sh-ratio-content"
                                      style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"&ndash;&gt;
                <div >
                  <img src="../assets/images/slider11.jpg" />
                </div>
              </div>
            </div>
            <router-link
              :to="{ name: 'FreeCourses',
                              params: {beveragesAndDining}
                            }"
              class="post-overlay">

              <div class="post-overlay-content"><span></span> <span></span> <span></span></div>
            </router-link>
            <div class="post-categories-container">
              <div class="post-categories">
                <a href="javascript:void(0)">Social Sciences</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="post-thumbnail mt-4">
            <div class="sh-ratio">
              &lt;!&ndash;                    sh-ratio-container&ndash;&gt;
              <div class="">
                &lt;!&ndash;                      class="sh-ratio-content"
                                      style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"&ndash;&gt;
                <div >
                  <img src="../assets/images/slider11.jpg" />
                </div>
              </div>
            </div>
            <router-link
              :to="{ name: 'FreeCourses',
                              params: {beveragesAndDining}
                            }"
              class="post-overlay">

              <div class="post-overlay-content"><span></span> <span></span> <span></span></div>
            </router-link>
            <div class="post-categories-container">
              <div class="post-categories">
                <a href="javascript:void(0)">Health</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="post-thumbnail mt-4">
            <div class="sh-ratio">
              &lt;!&ndash;                    sh-ratio-container&ndash;&gt;
              <div class="">
                &lt;!&ndash;                      class="sh-ratio-content"
                                      style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"&ndash;&gt;
                <div >
                  <img src="../assets/images/slider11.jpg" />
                </div>
              </div>
            </div>
            <router-link
              :to="{ name: 'FreeCourses',
                              params: {beveragesAndDining}
                            }"
              class="post-overlay">

              <div class="post-overlay-content"><span></span> <span></span> <span></span></div>
            </router-link>
            <div class="post-categories-container">
              <div class="post-categories">
                <a href="javascript:void(0)">Personnel Development</a>
              </div>
            </div>
          </div>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>

// optional style for arrows & dots

import axios from 'axios'

// import { RlCarouselSlide } from 'vue-renderless-carousel'
export default {
  name: 'CourseCategories',
  components: {
    // SlidesComponent,
    // RlCarouselSlide

  },
  data () {
    return {
      url: axios.defaults.baseURL,
      currentPage: 1,
      total: 1,

      list: null,
      PageOfItems: [],
      info: [],
      option: 0,



      loading: true,
      errored: false,


      componentKey: 0,
    }
  },
  //carousel_latest_jobs
  methods: {
    getAll() {
      this.rerender()

      this.list = null;

      axios.get('/api/V_Learn/GetCourseCategory')
        .then(res => {

          let list = [];
          list = res.data.data

          console.log('Add Response portion')

          console.log('list : ', list)
          this.list = list.slice().reverse();


          console.log('categorylist : ', this.list)
          //let currentDate = moment(String(new Date())).format('YYYY-MM-DD')




        }).catch(error => {
        console.log(error)
        this.errored = true
      })
        .finally(() => {
          if (this.errored) {

            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        })
    },



    showPrevious () {
      this.$refs.carousel.prev()
    },
    showNext () {
      this.$refs.carousel.next()
    },
    showPreviousLatestJob () {
      this.$refs.carousel_latest_jobs.prev()
    },
    showNextLatestJob () {
      this.$refs.carousel_latest_jobs.next()
    },
    showPreviousExecutiveJobs () {
      this.$refs.carousel_Executive_Jobs.prev()
    },
    showNextExecutiveJobs () {
      this.$refs.carousel_Executive_Jobs.next()
    },
    showPreviousTopEmployee () {
      this.$refs.carousel_Top_Employee.prev()
    },
    showNextTopEmployee () {
      this.$refs.carousel_Top_Employee.next()
    },
    showPreviousBrandsCar () {
      this.$refs.brands_carousel.prev()
    },
    showNextBrandsCar () {
      this.$refs.brands_carousel.next()
    },
    showPreviousTopEmployers () {
      this.$refs.top_employers_carousel.prev()
    },
    showNextTopEmployers () {
      this.$refs.top_employers_carousel.next()
    },


    //Rerender Component
    rerender () {
      this.componentKey += 1
    },
  },

  mounted () {
    this.getAll();
  }
}
</script>
