import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/Home'


import ContactUs from '../pages/ContactUs'

import BuildYourOwnCourse from '@/pages/BuildYourOwnCourse'
import OurViiLearnStory from '@/pages/OurViiLearnStory'
import Courses from '@/pages/courses/Courses'
import CourseCategories from '@/pages/courses/CourseCategories'
import CourseDetail from '@/pages/courses/CourseDetail'
import FAQs from '@/pages/FAQs'
import spareasmile from '@/pages/SpareAsmileSAS'

import Articles from '@/pages/Articles'
import Videos from '@/pages/Videos'
import OurPartners from '@/pages/OurPartners'
import SkillHer from '@/pages/SkillHer'
import MentorshipProgram from '@/pages/MentorshipProgram'
import SignIn from '@/pages/SignIn'
import SignUp from '@/pages/SignUp'
import Detail from '@/pages/Detail'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  {
    path: '/sign-in',
    name: 'SignIn',
    component: SignIn,
    meta: {
      auth: false
    }
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp
  },


  {
    path: '/detail/:id',
    name: 'Detail',
    component: Detail
  },
  {
    path: '/spare-a-smile',
    name: 'spareasmile',
    component: spareasmile
  },

  {
    path: '/our-vii-learn-story',
    name: 'OurViiLearnStory',
    component: OurViiLearnStory
  },
  {
    path: '/build-your-own-course',
    name: 'BuildYourOwnCourse',
    component: BuildYourOwnCourse
  },
  {
    path: '/courses/:id,:tid',
    name: 'Courses',
    component: Courses
  },
  {
    path: '/mentorship-sessions',
    name: 'OurPartners',
    component: OurPartners
  },
  {
    path: '/spare-a-smile',
    name: 'SkillHer',
    component: SkillHer
  },
  {
    path: '/faqs',
    name: 'FAQs',
    component: FAQs
  },
  {
    path: '/course-categories',
    name: 'CourseCategories',
    component: CourseCategories
  },
  {
    path: '/course-detail/:id',
    name: 'CourseDetail',
    component: CourseDetail
  }, 
  {
    path: '/digital-courses',
    name: 'DigitalCourses',
    component: () => import(/* webpackChunkName: "checkout" */ '../pages/DigitalCourses.vue')
  },
  
  {
    path: '/checkout',
    name: 'Checkout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "checkout" */ '../pages/courses/Checkout.vue')
  },
  {
    path: '/order-details',
    name: 'Orderandcustomerdetails',
    component: () => import(/* webpackChunkName: "checkout" */ '../pages/courses/Orderandcustomerdetails.vue')
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('../pages/courses/success.vue')
  },
  {
    path: '/success1',
    name: 'success1',
    component: () => import('../pages/courses/success1.vue')
  },
  {
    path: '/articles',
    name: 'Articles',
    component: Articles
  },
  {
    path: '/videos',
    name: 'Videos',
    component: Videos
  },
  {
    path: '/mentorship-program',
    name: 'MentorshipProgram',
    component: MentorshipProgram
  },

  {
    path: '/mentee-form',
    name: 'BeAMenteeForm',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "BeAMenteeForm" */ '../pages/forms/BeAMenteeForm.vue')
  },
  {
    path: '/mentor-form',
    name: 'BeAMentorForm',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "BeAMentorForm" */ '../pages/forms/BeAMentorForm.vue')
  },
  {
    path: '/BeAVolunteer',
    name: 'BeAVolunteer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "BeAMentorForm" */ '../pages/forms/BeAVolunteer.vue')
  },
  {
    path: '/alumni-form',
    name: 'AlumniForm',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "AlumniForm" */ '../pages/forms/AlumniForm.vue')
  },
  {
    path: '/partner-form',
    name: 'PartnerForm',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "PartnerForm" */ '../pages/forms/PartnerForm.vue')
  },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: "/404",
    name: "PageNotFound",
    component: () => import("../pages/PageNotFound"),
  },
  { path: "*", name: "redirectPageNotFound", redirect: "/404" }
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
