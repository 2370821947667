import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import "./plugins/custom";
import App from './App.vue'
import VueRouter from "vue-router"
import router from './router'
import store from './store'


import axios from "axios";
import VueAxios from "vue-axios";
import VueAuth from "@websanova/vue-auth";
import auth from "./js/auth";

import "es6-promise/auto";
import "../src/js/easypaisa";
import moment from "moment";
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);
Vue.config.productionTip = false

Vue.filter('formatDateAndTime', function(value) {
  if (value) {
    return moment(String(value)).format('MMM DD, YYYY, hh:mm:ss a')
  }
});




Vue.router = router;
Vue.use(VueRouter);
Vue.use(VueAxios, axios);
Vue.use(VueAuth, auth);


// axios.defaults.baseURL = "https://smw.demo.itrooscloud.net";
axios.defaults.baseURL = "https://smw-live.azurewebsites.net";
// axios.defaults.baseURL = "http://localhost:57589/";




new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
