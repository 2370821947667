<template>
  <div id="main-container" :key="componentKey">


    <!-- Banner Section For Web Start -->
<!--    <section class="webSection  custom-landing-banner-section1">
      <div class="custom-banner-background-overlay"></div>
      <div class="custom-section">
        <div class="custom-row-wrap">
          <div class="custom-row-element ">
            <div class="custom-row-element-wrap ">
              <div class="row custom-row-section ">
                <div class="col-lg-12 ">
                  <div class="text-center text-uppercase custom-banner-heading">

                    <span>Find Out More</span><br/>
                    <span>Check The Videos</span>

                    &lt;!&ndash;  Learn more    &ndash;&gt;
                    <div class="mt-4">
                      <div class="text-center clear-bottom">
                        <a href="javascript:void(0);" @click="scrollTo($event.target.dataset.id)" data-id="content_id" class="btn link-theme text-decoration-none">
                          View More
                          <i aria-hidden="true" class="fa fa-arrow-alt-circle-down"> </i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </section>-->





    <!--  Posts Start  -->
    <div  class="custom-row-element-wrap mt-5 mb-5">
      <div class="pt-4"></div>
      <div class="text-center mb-5 ml-auto mr-auto w-75">

        <div class="custom-row justify-content-center mt-2">
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
          <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
<!--            <i class="far fa-user"></i>-->
            <b-icon icon="question-square"></b-icon>
          </span>
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
        </div>
        <div  class="custom-widget  mb-2">
          <!--          <span style="color: #ea216c;font-size: 18px">{{ ourViiLearnStory[0].title }}</span>-->
          <h2 class="custom-text-color">Videos & Webinars</h2>
        </div>
      </div>
      <div class="pt-4"></div>
      <div class="row align-items-center">
        <div class="col-lg-12">
          <div class="post-container">

<!--            <div class="custom-widget mb-4 border-bottom">
              <h2 class=" ">So, how does it happen?</h2>

            </div>-->

            <div>
              <p class="custom-service-text" v-if="visibleRecords.length != 0">
                <span>{{ visibleRecords[0].description }}</span>
              </p>
            </div>

            <div class="pt-4"></div>
            <div v-if="visibleRecords != null" class="row no-gutters">
              <div class="col-lg-4" v-for="data in visibleRecords" :key="data.id">
                <article class="mb-5">
                  <div class="post-container">

                    <div v-if="data.yutubeLink != '' && data.yutubeLink != null">
                      <div class="post-thumbnail mt-4">
                        <div class="sh-ratio">

                          <div class="">
                            <!--                          <iframe width="1280" height="720" src="https://www.youtube.com/embed/x7Krla_UxRg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
                            <div class="custom-post-large-image" >
                              <img :src="'https://img.youtube.com/vi/'+data.yutubeLink.substring(30, data.yutubeLink.length)+'/0.jpg'" >
                            </div>
                          </div>
                        </div>
                        <a
                          @click="getVideoSrc(data.yutubeLink)"
                          data-toggle="modal" data-target="#videoModal" :title="data.yutubeLink"
                          class="post-overlay">
                          <!--                      <div class="post-overlay-content custom-post-overlay-content"></div>-->
                          <div class="post-categories-container custom-play-icon">
                            <div class="post-overlay-content custom-post-overlay-content">
                              <b-icon icon="play-circle"></b-icon>
                            </div>

                          </div>
                        </a>


                      </div>
                    </div>
                    <div v-else>

                      <div  class="post-thumbnail mt-4">
                        <div class="sh-ratio">
                          <!--                    sh-ratio-container-->
                          <div class="">
                            <!--                      class="sh-ratio-content"

                                                 style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"-->
                            <div class="custom-post-large-image">

                              <img src="../assets/images/no-image.png" >
                            </div>
                          </div>
                        </div>
<!--                        <router-link
                          :to="{ name: 'PostDetail',
                              params: { id: healthAndBeauty.id }
                            }"
                          class="post-overlay">
                          <div class="post-overlay-content"><span></span> <span></span> <span></span></div>
                        </router-link>-->
                      </div>

                    </div>
                    <div class="container">
                      <div class="post-content-container mt-3">

                        <div
                          class="post-title">
                          <h2> {{ data.vedioTitle }} </h2>
                        </div>

                      </div>
                    </div>

                  </div>
                </article>
              </div>

            </div>

          </div>
        </div>

      </div>
      <!--   Spacer   -->
      <div class="pt-5"></div>

      <div class="text-center clear-bottom">
        <div class="row no-gutters ">
          <div class="col-lg-3"></div>
          <div class="col-lg-2">
            <!--            <router-link :to="{ name: 'Home',hash: '#ourServices_id'
                                        }"

                                     class="btn link-theme text-decoration-none">Explore Services</router-link>-->
          </div>
          <div class="col-lg-2" v-if="visibleRecords != null">
            <!--  View More Button    -->
            <div >
              <div v-if="recordsVisible < record.length"  >
                <div class="text-center clear-bottom">
                  <a href="javascript:void(0)"
                     @click="recordsVisible += step"
                     class="btn link-theme text-decoration-none">
                    View More
                  </a>
                </div>
              </div>
            </div>

          </div>
          <div class="col-lg-2">
            <!--            <router-link :to="{ name: 'ContactUs' }"

                                     class="btn link-theme text-decoration-none">Contact Us</router-link>-->
          </div>

          <div class="col-lg-3"></div>
        </div>

      </div>

    </div>

    <!-- Video Modal-->

    <div class="modal fade" id="videoModal" tabindex="-1" role="dialog" aria-labelledby="videoModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <button type="button" @click="closeModal" class="close custom-close-btn" data-dismiss="modal"  aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>

          <div class="embed-responsive embed-responsive-16by9">

            <iframe v-if="isVideoAvailable" class="embed-responsive-item" width="1280" height="720" :src="videoUrl"  frameborder="0" allowfullscreen></iframe>
          </div>


        </div>
      </div>
    </div>

  </div>
</template>

<script>

// optional style for arrows & dots

import axios from 'axios'

// import { RlCarouselSlide } from 'vue-renderless-carousel'
export default {
  name: 'Videos',
  components: {
    // SlidesComponent,
    // RlCarouselSlide

  },
  data () {
    return {



      url: axios.defaults.baseURL,
      currentPage: 1,
      total: 1,

      list: null,
      PageOfItems: [],
      info: [],
      option: 0,

      isShowMoreBtnVisible:false,
      recordsVisible: 5,
      step: 5,

      isVideoAvailable:false,
      //Video Source
      videoUrl:"",
      loading: true,
      errored: false,


      componentKey: 0,
    }
  },
  //carousel_latest_jobs
  methods: {
    getAll() {
      this.rerender()
      this.$store.dispatch("fetchVideosAndWebinars")
    },

    showMore(){
      this.model.filter.PageSize += 5;
      this.getAll();
    },

    getVideoSrc(vSrc){
      // var video = document.getElementById("videoId");
      this.videoUrl = vSrc;
      console.log("videoUrl : ", this.videoUrl)

      this.isVideoAvailable = true;
    },


    closeModal(id){
      /*var video = document.getElementById(id);
        video.pause();*/
      this.isVideoAvailable = false;
      /*$('#videoModal').on('hidden.bs.modal', function (e) {
        $('#videoModal video').pause()
      })*/
    },


    scrollTo(id){
      let yOffset = -245;
      /*if(id.match("accordion")){
        yOffset = -250;
      }*/

      const element = document.getElementById(id);
      console.log("element id : ",element)
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      console.log("element position : ",y)
      //$("#content_id").animate({ scrollBottom: y }, "fast");
      window.scrollTo({top: y, behavior: 'smooth'});


    },


    //Rerender Component
    rerender () {
      this.componentKey += 1
    },
  },
  beforeCreate () {
    return this.$options.computed.visibleRecords;
  },
  computed:{
    record(){
      //if(this.$route.params.type)
      console.log("Available record", this.$store.state.videosAndWebinars)
      return this.$store.state.videosAndWebinars
    },
    visibleRecords() {
      return this.record.slice(0, this.recordsVisible)
    }
    /*totalCount () {
      console.log("total Count: ", this.$store.getters.totalCount)
      return this.$store.getters.totalCount;
    }*/
  },
  mounted () {

    this.getAll();

  }
}
</script>
