<template>
  <div id="main-container" :key="componentKey">


    <!-- Banner Section For Web Start -->
<!--    <section class="webSection  custom-landing-banner-section1">
      <div class="custom-banner-background-overlay"></div>
      <div class="custom-section">
        <div class="custom-row-wrap">
          <div class="custom-row-element ">
            <div class="custom-row-element-wrap ">
              <div class="row custom-row-section ">
                <div class="col-lg-12 custom-column-banner-heading  ">
                  <div class="custom-column-wrap">
                    <div class="">

                      <div class="row">
                        <div class="col-lg-5">
                          <select id="trainMe_id"
                                  class="form-control custom-input-gotopage custom-widget-form-group"
                                  v-model="selectedTrainMe"
                          >
                            <option value=0 >Choose ...</option>
                            <option
                              v-for="option in trainMe"
                              :key="option.key"
                              :value="option.key"
                            >
                              {{ option.value }}
                            </option>
                          </select>
                        </div>
                        <div class="col-lg-5">
                          <select id="category_id"
                                  class="form-control custom-input-gotopage custom-widget-form-group"
                                  v-model="selectedCategory"
                          >
                            <option value=0 >Select Category</option>
                            <option
                              v-for="option in categories"
                              :key="option.courseCategoryId"
                              :value="option.courseCategoryId"
                            >
                              {{ option.courseCategoryName }}
                            </option>
                          </select>
                        </div>
                        <div class="col-lg-2">
                          <router-link
                            :to="{ name: 'Courses',
                                    params: { id : selectedCategory, tid : selectedTrainMe}
                                  }"
                            class="btn custom-btn-search">
                            <span class="custom-btn-more-text font-weight-bold">
                              SUBMIT
                            </span>
                          </router-link>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </section>-->






    <!--  Content Start  -->
    <div class="custom-row-element-wrap mt-5 mb-5">
<!--      <div class="text-center mb-5 ml-auto mr-auto w-75">

        <div class="custom-row justify-content-center mt-2">
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
          <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
&lt;!&ndash;            <i class="far fa-user"></i>&ndash;&gt;
            <b-icon icon="person"></b-icon>
          </span>
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
        </div>
        <div v-if="educationForEveryOne.length != 0" class="custom-widget  mb-2">
          <h2 class="custom-text-color text-capitalize">{{ educationForEveryOne[0].title }}</h2>
&lt;!&ndash;          <span class="text-capitalize" style="color: #ea216c;font-size: 18px">{{ educationForEveryOne[0].title }}</span>&ndash;&gt;
          <h3 class=" ">{{ educationForEveryOne[0].shortDescription }}</h3>
        </div>
        <div v-else class="custom-widget  mb-2">
          <span style="color: #ea216c;font-size: 18px">EDUCATION FOR EVERYONE</span>
          <h3 class=" ">Affordable Online Courses and Learning Opportunities</h3>
        </div>

      </div>
      <div class="pt-4"></div>-->
      <div class="text-center mb-5 ml-auto mr-auto w-75">

        <div class="custom-row justify-content-center mt-2">
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
          <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
<!--            <i class="far fa-user"></i>-->
            <b-icon icon="person"></b-icon>
          </span>
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
        </div>
        <div  class="custom-widget  mb-2">
<!--          <span style="color: #ea216c;font-size: 18px">{{ ourViiLearnStory[0].title }}</span>-->
          <h2 class="custom-text-color">Knowledge & Training For Everyone</h2>
          <h3 v-if="ourViiLearnStory.length != 0" class=" ">{{ ourViiLearnStory[0].shortDescription }}</h3>
        </div>
<!--        <div class="text-muted">
          <p v-if="ourViiLearnStory != [] && ourViiLearnStory != null">
            {{ ourViiLearnStory[0].shortDescription }}
          </p>
        </div>-->
      </div>



      <div class="pt-4"></div>
      <!--  Read More    -->
      <div>
        <div v-if="ourViiLearnStory.length != 0" class="text-center clear-bottom">
          <router-link :to="{ name: 'Detail',
          params: { id: ourViiLearnStory[0].id }
          }"
                       class="btn link-theme text-decoration-none">
            Read More
<!--            <b-icon class="ml-2" icon="arrow-right-circle-fill"></b-icon>-->
          </router-link>
        </div>
      </div>
      <!--   Spacer   -->
      <div class="pt-5"></div>
      <!-- Course Start-->
      <div class="text-center mb-5 ml-auto mr-auto w-75">

        <div class="custom-row justify-content-center mt-2">
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
          <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
<!--            <i class="far fa-user"></i>-->
            <b-icon icon="person"></b-icon>
          </span>
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
        </div>
        <div class="custom-widget  mb-2">
<!--          <span style="color: #ea216c;font-size: 18px"></span>-->
          <h2 class= "custom-text-color ">Vii Learn Popular Courses</h2>
          <h3 class=" ">Learn at your own pace</h3>
        </div>
<!--        <div class="text-muted">
          <p>
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          </p>
        </div>-->
      </div>

      <div class="container">
        <div class="row">
          <div class="col">
            <VueSlickCarousel class="container" v-bind="settingsForThreeRows" ref="carousel" v-if="list.length != 0">
              <div v-for="(course, index) in list" :key="course.courseId">
                <article class="mb-5">
                  <div class="post-container">

                    <div class="post-thumbnail mt-4">
                      <div class="sh-ratio">
                        <!--                    sh-ratio-container-->
                        <div class="">
                          <!--                      class="sh-ratio-content"
                                                style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"-->
                          <div class="custom-post-large-image-course">
                            <img v-if="course.courseImage != null" :src="url+course.courseImage" />
                            <img v-else src="../assets/images/no-image.png" />
                          </div>
                        </div>
                      </div>
                      <router-link
                        :to="{ name: 'CourseDetail',
                         params: { id: course.courseId }
                        }"
                        :title="course.courseTitle"
                        class="post-overlay">
                        <div class=""><span></span> <span></span> <span></span></div>
                      </router-link>
                    </div>
                    <div class="container">
                      <div class="post-content-container mt-3">
                        <div class="post-categories-container ">
                          <div class="post-categories">
                            <b-avatar variant="info" src="https://placekitten.com/300/300" size="2rem" class="mr-3"></b-avatar>
                            <span class="mr-auto">{{ course.instructorName }}</span>
                          </div>
                        </div>
                        <router-link
                          :to="{ name: 'CourseDetail',
                         params: { id: course.courseId }

                        }"
                          :title="course.courseTitle"
                          class="post-title">
                          <h2> {{ course.courseTitle }} </h2>
                        </router-link>
                        <div class="post-content">
                          <p>
                            {{ course.courseShortDescription }}
                          </p>
                        </div>
                        <div class="row no-gutters mb-2">
                          <div class="col-md-9"></div>
                          <div class="col-md-3">
                            <span>
                              <router-link
                                :to="{ name: 'CourseDetail',
                                  params: { id: course.courseId }
                                }"
                                :title="course.courseTitle"
                                class="text-decoration-none post-categories-link">
                            <span>
                              Read More
                              <!--                          Category-->
                            </span>
                          </router-link>
                            </span>
                          </div>
                        </div>
                        <div class="post-meta">
                          <div class="post-meta-content">
                        <span class="post-auhor-date custom-row">
                          <span class="flex-grow-1">
                            <b-icon class="icon" icon="book"></b-icon>
                            <span class="post-author ml-2">{{ course.totalLectures }} Lessons</span>
                          </span>,
                            <span >
                              <users-icon size="1.5x" class="custom-text-color custom-class"></users-icon>
                              <span class="post-author ml-2">{{ course.courseType }}</span>
                          </span>
                        </span>

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </article>
              </div>

              <!--            <div class="style-events" v-for="ev in events" :key="ev.id">
                            <router-link to="/" :title="ev.title">
                              <img
                                  :src="url+ev.image"
                                  style="height: 250px; width: 370px; display: block"
                              />
                            </router-link>

                          </div>
                          <div class="style-events">
                            <img src="../assets/images/blog3.jpg" />
                          </div>
                          <div class="style-events">
                            <img src="../assets/images/blog4.jpg" />
                          </div>
                          <div class="style-events">
                            <img src="../assets/images/blog5.jpg" />
                          </div>
                          <div class="style-events">
                            <img src="../assets/images/blog6.jpg" />
                          </div>-->
            </VueSlickCarousel>
          </div>
        </div>
      </div>
      <!-- Course End-->
      <!--   Spacer   -->
      <div class="pt-5"></div>

      <div class="text-center mb-5 ml-auto mr-auto w-75">

        <div class="custom-row justify-content-center mt-2">
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
          <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
<!--            <i class="far fa-user"></i>-->
            <b-icon icon="person"></b-icon>
          </span>
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
        </div>
        <div  class="custom-widget  mb-2">
          <!--          <span style="color: #ea216c;font-size: 18px">{{ ourViiLearnStory[0].title }}</span>-->
          <h2 class="custom-text-color">Our Partners & Services for Corporates</h2>
          <h3 v-if="buildYouOwnCourses.length != 0" class=" ">{{ buildYouOwnCourses[0].shortDescription }}</h3>
        </div>
<!--        <div class="text-muted">
          <p v-if="buildYouOwnCourses != [] && buildYouOwnCourses != null">
            {{ buildYouOwnCourses[0].shortDescription }}
          </p>
        </div>-->
      </div>

<!--      <div class="row no-gutters custom-quotes-section mt-4">
        <div class="col-lg-6">
          <div class="ml-3 mr-3">
            <div class="custom-widget  mb-2">
              <span style="color: #ea216c;font-size: 18px">ONLINE LEARNING</span>
            </div>
            <div class="">
              <h2 class=" ">Flexible Study at Your Own Pace, According to Your Own Needs</h2>
            </div>
            <div>
              <p class="custom-service-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div class="custom-row justify-content-center ">
              &lt;!&ndash;            <span style="width: 100%;border-bottom: 2px solid  #ea216c;height: 10px"></span>&ndash;&gt;
              <span class=" mr-3" style="color: #ea216c;font-size: 40px">
&lt;!&ndash;            <i class="far fa-user"></i>&ndash;&gt;
            <b-icon icon="chat-left-quote-fill"></b-icon>
          </span>
              <span style="width: 100%;border-bottom: 1px solid  #ea216c;height: 10px"></span>
            </div>
            <div id="carouselExampleIndicators" class="carousel slide  custom-quote-style" data-ride="carousel">

              <div class="custom-row">
                <div class="carousel-inner   mt-2 pr-3">
                  <div class="carousel-item w3-animate-opacity active">
                    <div>
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ncididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. consequat. ncididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerc
                    </div>
                    <div class="custom-row mt-4">
                    <span>
                      <b-avatar variant="info" src="https://placekitten.com/300/300" size="3rem" class="mr-3"></b-avatar>
                    </span>
                      <div>
                        <span style="color: #ea216c;font-size: 15px">John Doe</span><br/>
                        <span class="mr-auto">Java Developer</span>
                      </div>

                    </div>


                  </div>
                  <div class="carousel-item w3-animate-opacity">
                    <div>
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ncididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. consequat. ncididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerc
                    </div>
                    <div class="custom-row mt-4">
                    <span>
                      <b-avatar variant="info" src="https://placekitten.com/300/300" size="3rem" class="mr-3"></b-avatar>
                    </span>
                      <div>
                        <span style="color: #ea216c;font-size: 15px">John Doe</span><br/>
                        <span class="mr-auto">Java Developer</span>
                      </div>

                    </div>
                  </div>
                  <div class="carousel-item w3-animate-opacity">
                    <div>
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ncididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. consequat. ncididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exerc
                    </div>
                    <div class="custom-row mt-4">
                    <span>
                      <b-avatar variant="info" src="https://placekitten.com/300/300" size="3rem" class="mr-3"></b-avatar>
                    </span>
                      <div>
                        <span style="color: #ea216c;font-size: 15px">John Doe</span><br/>
                        <span class="mr-auto">Java Developer</span>
                      </div>

                    </div>
                  </div>
                </div>
                <span class="">
               <ol class="carousel-indicators custom-carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
              </ol>
            </span>
              </div>


            </div>
          </div>

        </div>
        <div class="col-lg-6">
          <div class="row no-gutters">
            <div class="col-lg-6">
              <div class="custom-counter custom-top-left-counter text-center">
                <h3 class="custom-counter-value m-0">1,200</h3>
                <p class="custom-counter-text">FINISHED LESSONS</p>
              </div>
              <div class="custom-counter custom-bottom-left-counter text-center">
                <h3 class="custom-counter-value m-0">23</h3>
                <p class="custom-counter-text">ONLINE INSTRUCTORS</p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="custom-counter custom-top-right-counter text-center">
                <h3 class="custom-counter-value m-0">4,340</h3>
                <p class="custom-counter-text">ENROLLED LEARNERS</p>
              </div>
              <div class="custom-counter custom-bottom-right-counter text-center">
                <h3 class="custom-counter-value m-0">100</h3>
                <p class="custom-counter-text">SATISFACTION RATE</p>
              </div>
            </div>
          </div>
        </div>


      </div>-->
      <div class="pt-4"></div>
      <!--  Read More    -->
      <div>
        <div v-if="buildYouOwnCourses.length != 0" class="text-center clear-bottom">
          <router-link :to="{ name: 'Detail',
           params: { id: buildYouOwnCourses[0].id }
          }" class="btn link-theme text-decoration-none">
            Read More
            <!--            <b-icon class="ml-2" icon="arrow-right-circle-fill"></b-icon>-->
          </router-link>
        </div>
      </div>
      <!--   Spacer   -->
      <div class="pt-5"></div>
      <div class="text-center mb-5 ml-auto mr-auto w-75">

        <div class="custom-row justify-content-center mt-2">
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
          <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
<!--            <i class="far fa-user"></i>-->
            <b-icon icon="person"></b-icon>
          </span>
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
        </div>
        <div  class="custom-widget  mb-2">
          <!--          <span style="color: #ea216c;font-size: 18px">{{ ourViiLearnStory[0].title }}</span>-->
          <h2 class="custom-text-color">Spare a smile children welfare foundation & skill women Pakistan Mentorship Programme</h2>
          <h3 v-if="skillHer.length != 0" class=" ">{{ skillHer[0].shortDescription }}</h3>
        </div>
<!--        <div class="text-muted">
          <p v-if="skillHer != [] && skillHer != null">
            {{ skillHer[0].shortDescription }}
          </p>
        </div>-->
      </div>
<!--      <div class="text-center mb-5 ml-auto mr-auto w-75">

        <div class="custom-row justify-content-center mt-2">
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
          <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
&lt;!&ndash;            <i class="far fa-user"></i>&ndash;&gt;
            <b-icon icon="person"></b-icon>
          </span>
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
        </div>
        <div class="custom-widget  mb-2">
          <span style="color: #ea216c;font-size: 18px">NEWS & BLOGS</span>
          <h2 class=" ">Our Latest Articles</h2>
        </div>
        <div class="text-muted">
          <p>
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          </p>
        </div>
      </div>

      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-4" v-for="post in latestPosts" :key="post.id">
            <article class="mb-5">
              <div class="post-container">

                <div class="post-thumbnail mt-4">
                  <div class="sh-ratio">
                    &lt;!&ndash;                    sh-ratio-container&ndash;&gt;
                    <div class="">
                      &lt;!&ndash;                      class="sh-ratio-content"
                                            style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"&ndash;&gt;
                      <div style="height: 265px" >
                        <img :src="url+post.videoOrImage" />
                      </div>
                    </div>
                  </div>
                  <router-link
                    to="/"
                    class="post-overlay">
                    <div class=""><span></span> <span></span> <span></span></div>
                  </router-link>
                </div>
                <div class="container">
                  <div class="post-content-container mt-3">
                    <div class="post-categories-container ">
                      <div class="post-categories">
                        <h5 v-if="post.category == 'HealthAndBeauty'" class="post-title font-italic">
                          Health & Beauty
                        </h5>
                        <h5 v-if="post.category == 'BeveragesAndDining'" class="post-title font-italic">
                          Beverages & Dining
                        </h5>
                        <h5 v-if="post.category == 'HotelsAndStaycations'" class="post-title font-italic">
                          Hotels & Staycations
                        </h5>
                        <h5 v-if="post.category == 'SportsAndLeisure'" class="post-title font-italic">
                          Sports & Leisure
                        </h5>
                        <h5 v-if="post.category == 'KidsAndFamily'" class="post-title font-italic">
                          Kids & Family
                        </h5>
                        <h5 v-if="post.category == 'FlowersAndGardening'" class="post-title font-italic">
                          Flowers & Gardening
                        </h5>
                        <span v-else class="post-title font-italic"></span>
                      </div>
                    </div>
                    <router-link
                      to="/"
                      class="post-title">
                      <h2> {{ post.categoryTitle }} </h2>
                    </router-link>
                    <div class="post-content">
                      <p>

                      </p>
                    </div>
                    <div class="post-meta">
                      <div class="post-meta-content">
                    <span class="post-auhor-date row">
                      <div class="col-sm-4">
                        <b-avatar variant="info" src="https://placekitten.com/300/300" size="2rem" class="mr-3"></b-avatar>
                        <span class="mr-auto">J. Circlehead</span>
                      </div>
                      <div class="col-sm-4">
                        <span v-if="post.numberofDays > 1">
                        <i class="post-date">
                          {{ post.numberofDays }} days ago
                        </i>
                      </span>
                      <span v-else>
                        <i class="post-date">
                          {{ post.numberofDays }} day ago
                        </i>
                      </span>
                      </div>

                        <div class="col-sm-4" >
                          <b-icon class="icon" icon="calendar3"></b-icon>
                          <span class="post-author ml-2">2021-9-12</span>
                      </div>
                    </span>
                        &lt;!&ndash;                        <a
                                                  href="https://gillion.shufflehound.com/home/2017/01/31/health-tips-and-benefits-of-healthy-lifestyle-you-should-consider/#comments"
                                                  class="post-comments">
                                                  <i class="icon icon-bubble"></i> 0
                                                </a>
                                                <span class="post-readtime">
                                                  <i class="icon icon-clock"></i> 1 min <span>read</span>
                                                </span>&ndash;&gt;

                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </article>
          </div>
&lt;!&ndash;          <div class="col-lg-4">
            <article class="mb-5">
              <div class="post-container">

                <div class="post-thumbnail mt-4">
                  <div class="sh-ratio">
                    &lt;!&ndash;                    sh-ratio-container&ndash;&gt;
                    <div class="">
                      &lt;!&ndash;                      class="sh-ratio-content"
                                            style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"&ndash;&gt;
                      <div >
                        <img src="../assets/images/about-img5.jpg" />
                      </div>
                    </div>
                  </div>
                  <router-link
                    to="/"
                    class="post-overlay">
                    <div class=""><span></span> <span></span> <span></span></div>
                  </router-link>
                </div>
                <div class="container">
                  <div class="post-content-container mt-3">
                    <div class="post-categories-container ">
                      <div class="post-categories">
                        Category
                      </div>
                    </div>
                    <router-link
                      to="/"
                      class="post-title">
                      <h2> Title </h2>
                    </router-link>
                    <div class="post-content">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                      </p>
                    </div>
                    <div class="post-meta">
                      <div class="post-meta-content">
                    <span class="post-auhor-date custom-row">
                      <span class="flex-grow-1">
                        <b-avatar variant="info" src="https://placekitten.com/300/300" size="2rem" class="mr-3"></b-avatar>
                        <span class="mr-auto">J. Circlehead</span>
                      </span>,
                      &lt;!&ndash;                      <span v-if="beveragesAndDining.numberofDays > 1">
                                              <i class="post-date">
                                                {{ beveragesAndDining.numberofDays }} days ago
                                              </i>
                                            </span>
                                            <span v-else>
                                              <i class="post-date">
                                                {{ beveragesAndDining.numberofDays }} day ago
                                              </i>
                                            </span>&ndash;&gt;
                        <span >
                          <b-icon class="icon" icon="calendar3"></b-icon>
                          <span class="post-author ml-2">2021-9-12</span>
                      </span>
                    </span>
                        &lt;!&ndash;                        <a
                                                  href="https://gillion.shufflehound.com/home/2017/01/31/health-tips-and-benefits-of-healthy-lifestyle-you-should-consider/#comments"
                                                  class="post-comments">
                                                  <i class="icon icon-bubble"></i> 0
                                                </a>
                                                <span class="post-readtime">
                                                  <i class="icon icon-clock"></i> 1 min <span>read</span>
                                                </span>&ndash;&gt;

                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </article>
          </div>
          <div class="col-lg-4">
            <article class="mb-5">
              <div class="post-container">

                <div class="post-thumbnail mt-4">
                  <div class="sh-ratio">
                    &lt;!&ndash;                    sh-ratio-container&ndash;&gt;
                    <div class="">
                      &lt;!&ndash;                      class="sh-ratio-content"
                                            style="background-image: url( https://cdn.gillion.shufflehound.com/wp-content/uploads/sites/14/2017/01/9-420x265.jpg);"&ndash;&gt;
                      <div >
                        <img src="../assets/images/about-img5.jpg" />
                      </div>
                    </div>
                  </div>
                  <router-link
                    to="/"
                    class="post-overlay">
                    <div class=""><span></span> <span></span> <span></span></div>
                  </router-link>
                </div>
                <div class="container">
                  <div class="post-content-container mt-3">
                    <div class="post-categories-container ">
                      <div class="post-categories">
                        Category
                      </div>
                    </div>
                    <router-link
                      to="/"
                      class="post-title">
                      <h2> Title </h2>
                    </router-link>
                    <div class="post-content">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                      </p>
                    </div>
                    <div class="post-meta">
                      <div class="post-meta-content">
                    <span class="post-auhor-date custom-row">
                      <span class="flex-grow-1">
                        <b-avatar variant="info" src="https://placekitten.com/300/300" size="2rem" class="mr-3"></b-avatar>
                        <span class="mr-auto">J. Circlehead</span>
                      </span>,
                      &lt;!&ndash;                      <span v-if="beveragesAndDining.numberofDays > 1">
                                              <i class="post-date">
                                                {{ beveragesAndDining.numberofDays }} days ago
                                              </i>
                                            </span>
                                            <span v-else>
                                              <i class="post-date">
                                                {{ beveragesAndDining.numberofDays }} day ago
                                              </i>
                                            </span>&ndash;&gt;
                        <span >
                          <b-icon class="icon" icon="calendar3"></b-icon>
                          <span class="post-author ml-2">2021-9-12</span>
                      </span>
                    </span>
                        &lt;!&ndash;                        <a
                                                  href="https://gillion.shufflehound.com/home/2017/01/31/health-tips-and-benefits-of-healthy-lifestyle-you-should-consider/#comments"
                                                  class="post-comments">
                                                  <i class="icon icon-bubble"></i> 0
                                                </a>
                                                <span class="post-readtime">
                                                  <i class="icon icon-clock"></i> 1 min <span>read</span>
                                                </span>&ndash;&gt;

                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </article>
          </div>&ndash;&gt;
        </div>
      </div>-->
      <div class="pt-4"></div>
      <!--  Read More    -->
      <div>
        <div class="text-center clear-bottom">
          <router-link to="/spare-a-smile" class="btn link-theme text-decoration-none">
            Read More
            <!--            <b-icon class="ml-2" icon="arrow-right-circle-fill"></b-icon>-->
          </router-link>
        </div>
      </div>
      <div class="pt-5"></div>
      <!-- online courses banner-->

      <div class="text-center mb-5 ml-auto mr-auto w-75">

        <div class="custom-row justify-content-center mt-2">
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
          <span class="ml-1 mr-1" style="color: #ea216c;font-size: 24px">
<!--            <i class="far fa-user"></i>-->
            <b-icon icon="file-earmark-text"></b-icon>
          </span>
          <span style="width: 25px;border-bottom: 2px solid  #ea216c;height: 10px"></span>
        </div>
        <div class="custom-widget  mb-2">
          <!--        <span style="color: #ea216c;font-size: 18px">AFFORDABLE CERTIFICATION</span>-->
          <h2 class="custom-text-color">Our Mentorship Sessions</h2>
          <h3 class="">If you are a female student looking for guidance on  navigating your career or  need to seek advice on career development and ongoing support , join our community of mentors and mentees and avail free of charge mentorship sessions. fill out the form "be a mentee"</h3>
        </div>

      </div>

      <!--  Read More    -->
      <div>
        <div class="text-center clear-bottom">
          <router-link to="/mentee-form" class="btn link-theme text-decoration-none">
            Be A Mentee
            <!--            <b-icon class="ml-2" icon="arrow-right-circle-fill"></b-icon>-->
          </router-link>
        </div>
      </div>
      <div class="pt-4"></div>
      <div class="container-fluid style-grey-bg style-brand-spaces">
        <div class="container">
          <div class="row no-gutters">

            <div class="col-12">
              <VueSlickCarousel class="container" v-bind="settingsForBrands" ref="brands_carousel" v-if="ourPartner.length != 0">
                <div class="style-brands" v-for="br in ourPartner" :key="br.id">
                  <router-link :to="{ name: 'Detail',
                      params: { id: br.id }
                          }" :title="br.title">
                    <img :src="url+br.image" />
                  </router-link>
                  <div class="pt-4"></div>
                  <!--  Read More    -->
                  <div>
                    <div class="text-center clear-bottom">
                      <router-link
                        :to="{ name: 'Detail',
                      params: { id: br.id }
                          }"
                        :title="br.title"
                        class="text-decoration-none post-categories-link">
                        <span>
                          Read More
                          <!--                          Category-->
                        </span>
                      </router-link>
                    </div>
                  </div>
                </div>
              </VueSlickCarousel>
            </div>

          </div>
        </div>
      </div>
      <div class="pt-4"></div>
    </div>
    <!--   Spacer   -->
    <div class="pt-5"></div>
    <!-- online courses banner-->
<!--    <div class="custom-onlinecourses-banner-section">
      <div class="custom-banner-background-overlay"></div>
      <div class="custom-section">
        <div class="custom-row-wrap">
          <div class="custom-row-element ">
            <div class="custom-row-element-wrap ">
                            <div class="row custom-row-section align-items-center">
                              <div class="col-lg-6">
                                <div class="post-container">
                                  <div class="custom-widget mt-2  ">
                                    <h2 class="widget-title text-uppercase custom-text-color">Distance Learning</h2>
                                  </div>
                                  <div class="custom-widget mb-4 border-bottom">
                                    <h2 class=" ">Our Vii Learn Story</h2>
                                  </div>
                                  <div>
                                    <p class="custom-service-text">
                                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    </p>
                                  </div>
                                  &lt;!&ndash;  Load more    &ndash;&gt;
                                  <div>
                                    <div class="clear-bottom mt-2">
                                      <router-link to="/" class="btn link-theme text-decoration-none" href="#">
                                        Explore Learning
                                        <i aria-hidden="true" class="fa fa-arrow-alt-circle-right"> </i></router-link
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-6">
&lt;!&ndash;                                <div class="custom-onlinecourses-background">
                                  <img src="../assets/images/man-with-laptop.png" width="100%">
                                </div>&ndash;&gt;
                              </div>
                            </div>
            </div>
          </div>
        </div>
      </div>
    </div>-->

<!--    <div>
      <div class="text-center clear-bottom">
        <router-link to="/" class="btn link-theme text-decoration-none">
          <b-icon icon="journals"></b-icon>
          Get Started Now
        </router-link>
      </div>
    </div>-->
    <!--   Spacer   -->
    <div class="pt-5"></div>
    <!-- Video Modal-->

    <div class="modal fade" id="videoModal" tabindex="-1" role="dialog" aria-labelledby="videoModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <button type="button" @click="closeModal" class="close custom-close-btn" data-dismiss="modal"  aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>

            <div class="embed-responsive embed-responsive-16by9">
              <iframe class="embed-responsive-item"
                      src="https://www.youtube.com/embed/Zv11L-ZfrSg"
                      allowfullscreen></iframe>
            </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>

// optional style for arrows & dots

import axios from 'axios'
import { UsersIcon } from 'vue-feather-icons'
import VueSlickCarousel from "vue-slick-carousel";
export default {
  name: 'Home',
  components: {
    UsersIcon,
    VueSlickCarousel
  },
  data () {
    return {

      settingsForThreeRows: {
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        rows: 1,
        slidesPerRow: 1,
        arrows: true,
        "autoplay": true,
        "autoplaySpeed": 2000,

        "pauseOnFocus": true,
        "pauseOnHover": true,
        "responsive": [
          {
            "breakpoint": 768,
            "settings": {
              "slidesToShow": 2,

              "infinite": true,

            }
          },
          {
            "breakpoint": 500,
            "settings": {
              "slidesToShow": 1,

              "infinite": true,

            }
          }
        ]
      },
      settingsForBrands: {
        infinite: true,
        slidesToShow: 3,
        speed: 500,
        rows: 1,
        slidesPerRow: 1,
        arrows: true,
        "autoplay": true,
        "autoplaySpeed": 2000,

        "pauseOnFocus": true,
        "pauseOnHover": true,
        "responsive": [
          {
            "breakpoint": 768,
            "settings": {
              "slidesToShow": 2,

              "infinite": true,

            }
          },
          {
            "breakpoint": 500,
            "settings": {
              "slidesToShow": 1,

              "infinite": true,

            }
          }
        ]
      },



      url: axios.defaults.baseURL,
      currentPage: 1,
      total: 1,

      list: [],
      PageOfItems: [],
      info: [],
      option: 0,
      model:{
        PageSize: 10,
        PageNumber: 1,
        Text: "",
      },
      selectedTrainMe: 0,
      trainMe:[
        { key: 1, value: "Train Me"},
        { key: 2, value: "Train The Team"},
        { key: 3, value: "Skill Her"},
      ],
      selectedCategory:0,
      categories:[],
      courseFilter:{
        CourseCategoryId: null,
        CourseTitle :null
      },

      postsList:[],
      latestPosts:[],




      loading: true,
      errored: false,


      componentKey: 0,
    }
  },
  //carousel_latest_jobs
  methods: {

    getAll(){

      this.$store.dispatch("fetchEducationForEveryOne");
      this.$store.dispatch("fetchOurViiLearnStory");
      this.$store.dispatch("fetchBuildYouOwnCourses");
      this.$store.dispatch("fetchSkillHer");
      this.$store.dispatch("fetchOurPartner");


    },


    getAllCourses() {
      this.rerender()




      console.log("courseFilter : ",this.courseFilter);
      this.list = [];



      axios
        .post('/api/V_Learn/GetAllCourse', this.courseFilter)
        .then(res => {
          // code that we will 'try' to run
          let list = [];
          list = res.data.data

          console.log('Add Response portion')

          console.log('list : ', list)
          this.list = list.slice().reverse();
          /*let data = list.slice().reverse();
          console.log('data : ', data)
          if(data.length >= 3){
            for (let i = 0; i < 3 ; i++){
              console.log("data[i]: ",data[i])
              this.list.push(data[i]);
            }
          }
          else {
            for (let i in data){
              console.log("data[i]: ",data[i])
              this.list.push(data[i]);
            }
          }*/


          console.log("this.list : ", this.list);
        })
        .catch(error => {
          console.log('Errorrrrrr : ', error)
          this.errored = true
        })
        .finally(() => {
          if (this.errored) {

            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {

          }
        })
    },

    getAllArticles(){
      this.rerender()

      this.postsList = [];

      this.latestPosts = [];
      axios.post('/api/Shehri/GetAllPost', this.model)
        .then(res => {

          let list = [];
          list = res.data.data

          console.log('Add Response portion')

          console.log('Articles list : ', list)
          this.postsList = list.slice().reverse();
          console.log('postsList : ', this.postsList)
          //let currentDate = moment(String(new Date())).format('YYYY-MM-DD')


          if(this.postsList.length >= 3){
            for (let lpost=0; lpost < 3 ; lpost++){
              console.log("posts : ",this.postsList[lpost])
              if(this.postsList[lpost].postType == "LatestArticles"){
                this.latestPosts.push(this.postsList[lpost]);
              }
            }
            console.log("latestJobs Jobs : ", this.latestPosts);
          }
          else {
            for (let lpost in postsList){
              console.log("posts : ",this.postsList[lpost])
              if(this.postsList[lpost].postType == "LatestArticles"){
                this.latestPosts.push(this.postsList[lpost]);
              }
            }
            console.log("latestJobs Jobs : ", this.latestPosts);
          }




        }).catch(error => {
        console.log(error)
        this.errored = true
      })
        .finally(() => {
          if (this.errored) {

            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        })
    },

    getAllCategories(){
      this.rerender()

      this.categories = [];

      axios.get('/api/V_Learn/GetCourseCategory')
        .then(res => {


          this.categories = res.data.data;

          console.log('Add Response portion')

          console.log('categories list : ', this.categories)








        }).catch(error => {
        console.log(error)
        this.errored = true
      })
        .finally(() => {
          if (this.errored) {

            console.log('Error : Sorry there is no response')
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        })
    },
    closeModal(){
      $("#videoModal").on('hidden.bs.modal', function (e) {
        $("#videoModal iframe").attr("src", $("#videoModal iframe").attr("src"));
      });
    },

    showPrevious () {
      this.$refs.carousel.prev()
    },
    showNext () {
      this.$refs.carousel.next()
    },
    showPreviousLatestJob () {
      this.$refs.carousel_latest_jobs.prev()
    },
    showNextLatestJob () {
      this.$refs.carousel_latest_jobs.next()
    },
    showPreviousExecutiveJobs () {
      this.$refs.carousel_Executive_Jobs.prev()
    },
    showNextExecutiveJobs () {
      this.$refs.carousel_Executive_Jobs.next()
    },
    showPreviousTopEmployee () {
      this.$refs.carousel_Top_Employee.prev()
    },
    showNextTopEmployee () {
      this.$refs.carousel_Top_Employee.next()
    },
    showPreviousBrandsCar () {
      this.$refs.brands_carousel.prev()
    },
    showNextBrandsCar () {
      this.$refs.brands_carousel.next()
    },
    showPreviousTopEmployers () {
      this.$refs.top_employers_carousel.prev()
    },
    showNextTopEmployers () {
      this.$refs.top_employers_carousel.next()
    },


    //Rerender Component
    rerender () {
      this.componentKey += 1
    },
  },

  computed:{
    educationForEveryOne(){
      console.log("this.$store.state.educationForEveryOne", this.$store.state.educationForEveryOne)
      return this.$store.state.educationForEveryOne
    },
    ourViiLearnStory(){
      console.log("this.$store.state.ourViiLearnStory", this.$store.state.ourViiLearnStory)
      return this.$store.state.ourViiLearnStory
    },
    buildYouOwnCourses(){
      console.log("this.$store.state.buildYouOwnCourses", this.$store.state.buildYouOwnCourses)
      return this.$store.state.buildYouOwnCourses
    },
    skillHer(){
      console.log("this.$store.state.skillHer", this.$store.state.skillHer)
      return this.$store.state.skillHer
    },
    ourPartner(){
      console.log("this.$store.state.ourPartner", this.$store.state.ourPartner)
      return this.$store.state.ourPartner
    },
  },
  mounted () {

    this.getAll();
    this.getAllCourses();
    //this.getAllArticles();
    //this.getAllCategories();
  }
}
</script>
