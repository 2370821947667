<template>
  <div class="container-fluid main-content">
    <div class="main-panel">
      <div class="content-wrapper">
        <!--content area -->
        <router-view class="w3-animate-opacity" :key="$route.path" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Content",
};
</script>

<style></style>
